<template>
	<div>
		<div v-if="match">
			<div ref="page_2">
				<b-row class="teamsSchemes mt-2">
					<b-col class="text-center" v-if="match.home_modulo">
						<span class="scheme">{{ match.home_modulo.name }}</span>
					</b-col>
					<b-col class="text-center">
						<slot></slot>
					</b-col>
					<b-col class="text-center" v-if="match.away_modulo">
						<span class="scheme">{{ match.away_modulo.name }}</span>
					</b-col>
				</b-row>
				<div class="teamSubstitutions clearfix" v-if="formation_home_no_position || formation_away_no_position">

					<ul class="dotsTeam teamA">
						<li v-for="item in formation_home_no_position" :key="'no_pos_A_' + item.id">
							{{ player(item.persona_id).maglia }}
							<span v-if="yellowCards(item.persona_id).length" class="player-amm"></span>
							<span v-if="redCards(item.persona_id).length" class="player-esp"></span>
							<span v-if="goals(item.persona_id).length" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="item.persona.avatar_url" />
									</div>
									<div class="player_info">
										<h1>{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</h1>
										<h2>
											<span v-if="playerOut(item.persona_id).length"><img src="/assets/wyscout/out.png" />{{ playerOut(item.persona_id)[0].minuto }}'</span>
											<span v-if="yellowCards(item.persona_id).length"><span v-for="yellow in yellowCards(item.persona_id)"><img src="/assets/wyscout/yellow.png" :key="yellow.id"/>{{ yellow.minuto }}'</span></span>
											<span v-if="redCards(item.persona_id).length"><img src="/assets/wyscout/red.png" />{{ redCards(item.persona_id)[0].minuto }}'</span>
											<span v-if="goals(item.persona_id).length"><span v-for="goal in goals(item.persona_id)"><img src="/assets/wyscout/ball.png" :key="goal.id"/>{{ goal.minuto }}'</span></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>

					<ul class="dotsTeam teamB">
						<li v-for="item in formation_away_no_position" :key="'no_pos_B_' + item.id">
							{{ player(item.persona_id).maglia }}
							<span v-if="yellowCards(item.persona_id).length" class="player-amm"></span>
							<span v-if="redCards(item.persona_id).length" class="player-esp"></span>
							<span v-if="goals(item.persona_id).length" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>
							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="item.persona.avatar_url" />
									</div>
									<div class="player_info">
										<h1>{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</h1>
										<h2>
											<span v-if="playerOut(item.persona_id).length"><img src="/assets/wyscout/out.png" />{{ playerOut(item.persona_id)[0].minuto }}'</span>
											<span v-if="yellowCards(item.persona_id).length"><span v-for="yellow in yellowCards(item.persona_id)"><img src="/assets/wyscout/yellow.png" :key="yellow.id"/>{{ yellow.minuto }}'</span></span>
											<span v-if="redCards(item.persona_id).length"><img src="/assets/wyscout/red.png" />{{ redCards(item.persona_id)[0].minuto }}'</span>
											<span v-if="goals(item.persona_id).length"><span v-for="goal in goals(item.persona_id)"><img src="/assets/wyscout/ball.png" :key="goal.id"/>{{ goal.minuto }}'</span></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="teamSheetPitch" style="background-image: url(assets/wyscout/ws.png)">
					<ul class="gears-abs gears-fit dotsTeam teamA">
						<li v-for="item in formation_home" :key="item.id" :class=" 'teamA_' + item.posizione_id + '' + (playerOut(item.persona_id).length ? ' substituted' : '')">
							{{ item.maglia }}
							<span class="player-name">{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</span>
							<span v-if="yellowCards(item.persona_id).length" class="player-amm"></span>
							<span v-if="redCards(item.persona_id).length" class="player-esp"></span>
							<span v-if="goals(item.persona_id).length" class="player-goal" ><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="item.persona.avatar_url" />
									</div>
									<div class="player_info">
										<h1>{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</h1>
										<h2>
											<span v-if="playerOut(item.persona_id).length"><img src="/assets/wyscout/out.png" />{{ playerOut(item.persona_id)[0].minuto }}'</span>
											<span v-if="yellowCards(item.persona_id).length"><span v-for="yellow in yellowCards(item.persona_id)"><img src="/assets/wyscout/yellow.png" :key="yellow.id"/>{{ yellow.minuto }}'</span></span>
											<span v-if="redCards(item.persona_id).length"><img src="/assets/wyscout/red.png" />{{ redCards(item.persona_id)[0].minuto }}'</span>
											<span v-if="goals(item.persona_id).length"><span v-for="goal in goals(item.persona_id)"><img src="/assets/wyscout/ball.png" :key="goal.id"/>{{ goal.minuto }}'</span></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>
					<ul class="gears-abs gears-fit dotsTeam teamB">
						<li v-for="item in formation_away" :key="item.id" :class="'teamB_' + item.posizione_id + '' + (playerOut(item.persona_id).length ? ' substituted': '')">
							{{ item.maglia }}
							<span class="player-name">{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</span>
							<span v-if="yellowCards(item.persona_id).length" class="player-amm"></span>
							<span v-if="redCards(item.persona_id).length" class="player-esp"></span>
							<span v-if="goals(item.persona_id).length" class="player-goal" ><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="item.persona.avatar_url" />
									</div>
									<div class="player_info">
										<h1>{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</h1>
										<h2>
											<span v-if="playerOut(item.persona_id).length"><img src="/assets/wyscout/out.png" />{{ playerOut(item.persona_id)[0].minuto }}'</span>
											<span v-if="yellowCards(item.persona_id).length"><span v-for="yellow in yellowCards(item.persona_id)"><img src="/assets/wyscout/yellow.png" :key="yellow.id"/>{{ yellow.minuto }}'</span></span>
											<span v-if="redCards(item.persona_id).length"><img src="/assets/wyscout/red.png" />{{ redCards(item.persona_id)[0].minuto }}'</span>
											<span v-if="goals(item.persona_id).length"><span v-for="goal in goals(item.persona_id)"><img src="/assets/wyscout/ball.png" :key="goal.id"/>{{ goal.minuto }}'</span></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>

				<div class="teamSubstitutions clearfix">

					<ul class="dotsTeam teamA">
						<li v-for="item in getSubstitutionsHome" :key="'subA_' + item.id">
							{{ player(item.persona_id).maglia }}
							<span v-if="yellowCards(item.persona_id).length" class="player-amm"></span>
							<span v-if="redCards(item.persona_id).length" class="player-esp"></span>
							<span v-if="goals(item.persona_id).length" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="item.persona.avatar_url" />
									</div>
									<div class="player_info">
										<h1>{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</h1>
										<h2>
											<span v-if="playerIn(item.persona_id).length"><img src="/assets/wyscout/in.png" />{{ playerIn(item.persona_id)[0].minuto }}'</span>
											<span v-if="yellowCards(item.persona_id).length"><span v-for="yellow in yellowCards(item.persona_id)"><img src="/assets/wyscout/yellow.png" :key="yellow.id"/>{{ yellow.minuto }}'</span></span>
											<span v-if="redCards(item.persona_id).length"><img src="/assets/wyscout/red.png" />{{ redCards(item.persona_id)[0].minuto }}'</span>
											<span v-if="goals(item.persona_id).length"><span v-for="goal in goals(item.persona_id)"><img src="/assets/wyscout/ball.png" :key="goal.id"/>{{ goal.minuto }}'</span></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>

					<ul class="dotsTeam teamB">
						<li v-for="item in getSubstitutionsAway" :key="'subB_' + item.id">
							{{ player(item.persona_id).maglia }}
							<span v-if="yellowCards(item.persona_id).length" class="player-amm"></span>
							<span v-if="redCards(item.persona_id).length" class="player-esp"></span>
							<span v-if="goals(item.persona_id).length" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>
							<div class="player-tooltip">
								<div style="min-width:150px">
									<div class="player_thumb">
										<img width="30" :src="item.persona.avatar_url" />
									</div>
									<div class="player_info">
										<h1>{{ item.persona.nome.charAt(0) + '. ' + item.persona.cognome }}</h1>
										<h2>
											<span v-if="playerIn(item.persona_id).length"><img src="/assets/wyscout/in.png" />{{ playerIn(item.persona_id)[0].minuto }}'</span>
											<span v-if="yellowCards(item.persona_id).length"><span v-for="yellow in yellowCards(item.persona_id)"><img src="/assets/wyscout/yellow.png" :key="yellow.id"/>{{ yellow.minuto }}'</span></span>
											<span v-if="redCards(item.persona_id).length"><img src="/assets/wyscout/red.png" />{{ redCards(item.persona_id)[0].minuto }}'</span>
											<span v-if="goals(item.persona_id).length"><span v-for="goal in goals(item.persona_id)"><img src="/assets/wyscout/ball.png" :key="goal.id"/>{{ goal.minuto }}'</span></span>
										</h2>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		data: function() {
			return {
				modalita: null,
				window: {
					width: 0,
					height: 0
				},
			}
		},
		props: [ 'match' ],
		mounted: function() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		},
		computed: {
			formation_home: function() {
				return this.match.persona_rel.filter(item => item.team_id == this.match.home_id && item.partita_persona_tipo_glo_id == 1 && item.posizione_id);
			},
			formation_away: function() {
				return this.match.persona_rel.filter(item => item.team_id == this.match.away_id && item.partita_persona_tipo_glo_id == 1 && item.posizione_id);
			},
			formation_home_no_position: function() {
				return this.match.persona_rel.filter(item => item.team_id == this.match.home_id && item.partita_persona_tipo_glo_id == 1 && !item.posizione_id);
			},
			formation_away_no_position: function() {
				return this.match.persona_rel.filter(item => item.team_id == this.match.away_id && item.partita_persona_tipo_glo_id == 1 && !item.posizione_id);
			},
			getSubstitutionsHome() {
				return this.match.eventi.filter(item => item.partita_evento_tipo_glo_id == 7 && item.minuto > 0 && item.team_id == this.match.home_id);
			},
			getSubstitutionsAway() {
				return this.match.eventi.filter(item => item.partita_evento_tipo_glo_id == 7 && item.minuto > 0 && item.team_id == this.match.away_id);
			},
		},
		methods: {
			player(persona_id) {
				return this.match.persona_rel.find(item => item.persona_id == persona_id);	
			},
			playerIn(persona_id) {
				return this.match.eventi.filter(item => item.persona_id == persona_id && item.partita_evento_tipo_glo_id == 7 && item.minuto > 0);
			},
			playerOut(persona_id) {
				return this.match.eventi.filter(item => item.persona_id == persona_id && item.partita_evento_tipo_glo_id == 8);
			},
			yellowCards(persona_id) {
				return this.match.eventi.filter(item => item.persona_id == persona_id && item.partita_evento_tipo_glo_id == 5);
			},
			redCards(persona_id) {
				return this.match.eventi.filter(item => item.persona_id == persona_id && item.partita_evento_tipo_glo_id == 6);
			},
			goals(persona_id) {
				return this.match.eventi.filter(item => item.persona_id == persona_id && (item.partita_evento_tipo_glo_id == 1 || item.partita_evento_tipo_glo_id == 3 || item.partita_evento_tipo_glo_id == 4));
			},
		}
	}

</script>
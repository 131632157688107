<template>
	<div>
		<b-row @click.prevent="results && results.length ? ux.show = true : ''" style="cursor: pointer; height: 28px">
			<b-col :style="'line-height: 1;' + (showAmount(5)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold"><b-badge style="background-color: #46bdc6;width:90%;" class="p-1">{{ showAmount(5) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(4)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold"><b-badge style="background-color: #34a853;width:90%;" class="p-1">{{ showAmount(4) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(3)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold"><b-badge style="background-color: #ff6d01;width:90%;" class="p-1">{{ showAmount(3) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(2)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold"><b-badge style="background-color: #ea4336;width:90%;" class="p-1">{{ showAmount(2) }}</b-badge></div></b-col>
			<b-col :style="'line-height: 1;' + (showAmount(1)>0?'':'opacity: 0.3')" class="text-center text-muted"> <div style="font-weight: bold"><b-badge style="background-color: #674ea7;width:90%;" class="p-1">{{ showAmount(1) }}</b-badge></div></b-col>
		</b-row>

		<div class="text-center">
			<router-link :to="{ name: 'PlayerPersonaDashboard', params: { persona_id: thePlayer.persona_id }}" target="_blank" v-if="thePlayer.persona_id">
				<b-img rounded="circle" style="border: 1px solid rgba(0,0,0,.1)" :src="thePlayer.avatar_url" :alt="thePlayer.nome_breve" :height="120"/>
			</router-link>
			<b-img v-else rounded="circle" style="border: 1px solid rgba(0,0,0,.1)" :src="thePlayer.avatar_url" :alt="thePlayer.nome_breve" :height="120"/>

		</div>
		<div class="mt-1 text-center">
			<div class="m-0 p-0">{{ thePlayer.nome_breve }}</div>
			<b-row class="mb-1">
				<b-col>
					<small class="text-muted"><span>('{{ thePlayer.nascita_data | formatYear }})</span> <span>{{ thePlayer.ruolo }}</span></small>
				</b-col>
				<b-col>
					<span class="text-center" v-if="thePlayer.codice_nascita">
						<flag :country="thePlayer.codice_nascita" :size="16"></flag>
					</span>
					<span class="text-center" v-if="thePlayer.codice_passaporto">
						<flag :country="thePlayer.codice_passaporto" :size="16"></flag>	
					</span>
				</b-col>
			</b-row>
		</div>
		<b-row>
			<b-col>
				<valutazione-button 
					nm="Nuova Val." 
					size="xs" 
					:player="{ id: thePlayer.id, name: thePlayer.nome, surname: thePlayer.cognome, role: thePlayer.ruolo_group, avatar: thePlayer.avatar_url, persona_id: thePlayer.persona_id, team: team }" 
					@refresh="fetchAddress"
					:valutazioniPlayer="valutazioniPlayer"
				></valutazione-button>
			</b-col>
			<b-col v-if="results && results.length">
				<b-button @click.prevent="ux.show = true" block variant="outline-secondary" size="xs">Val {{ results.length }}</b-button>
			</b-col>
			<b-col v-if="thePlayer.persona_id">
				<b-button block variant="outline-info" size="xs" @click.prevent="showModalListe">Liste</b-button>
			</b-col>
		</b-row>


		<b-sidebar @hidden="ux.show = false" ref="mod" :visible="ux.show" width="80%" backdrop class="text-left" :title="thePlayer.nome_breve">
			<div class="mx-2 mb-3">
				<b-row>
					<b-col>
						<b-list-group v-for="valutazione in results" :key="valutazione.id">
							<b-list-group-item style="cursor:pointer;">
								<div @click.prevent="selectValutazione(valutazione)">
									<b-row>
										<b-col>
											<p class="lead mb-0"><span class="text-muted">Valutazione</span> {{ valutazione.glossario.nome }}</p>
										</b-col>
										<b-col class="text-right">
											<p class="mb-0">{{ valutazione.persona.nomeCompleto }}<br/>{{valutazione.created_at | date}}</p>
										</b-col>
									</b-row>
									<b-row align-v="center">
										<b-col cols="3">
											<div v-if="valutazione.partita_valutata">
												<div>{{ valutazione.partita_valutata.partita.competizione.paese.paese }} - {{ valutazione.partita_valutata.partita.competizione.nome }}</div>
												<div>{{ getTeamHome(valutazione.partita_valutata.partita).officialName }} - {{ getTeamAway(valutazione.partita_valutata.partita).officialName}}</div>
												<div>{{ valutazione.partita_valutata.partita.data | formatDateHour }}</div>
											</div>
											<!--div>{{ valutazione.persona.nomeCompleto }}<br/>{{valutazione.created_at | date}}</div-->
										</b-col>
										<b-col cols="1"><div style="font-weight: bold;font-size:2rem"><b-badge :style="'width:90%;background-color:' + getBgColor(getVotoValutazione(valutazione))" class="p-1">{{ getVotoValutazione(valutazione) }}</b-badge></div></b-col>
										<b-col cols="7">{{ getGiudizioValutazione(valutazione) }}</b-col>
										<b-col cols="1" class="text-right"><b-button size="sm" @click.stop="deleteValutazione(valutazione.id)"><i class="fa fa-trash"></i> </b-button></b-col>
									</b-row>
								</div>
								<b-row v-if="currentValutazione && currentValutazione.id == valutazione.id">
									<b-col>
										<singola-valutazione v-if="currentValutazione" :valutazione="currentValutazione"></singola-valutazione>
										<b-alert v-else variant="secondary" show>Seleziona una valutazione dalla lista.</b-alert>
										<div class="text-right my-1"><b-button size="sm" @click.stop="currentValutazione = null">Chiudi</b-button></div>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>
			</div>
		</b-sidebar>
		<b-modal ref="modalListe" :title="thePlayer.nome_breve" ok-only ok-title="Chiudi">
			<b-overlay :show="!liste">
				<p>Seleziona la lista a cui vuoi aggiungere il giocatore:</p>
				<b-form-checkbox v-for="lista in liste" :value="lista.id" v-model="lista_selected">{{ lista.nome }}</b-form-checkbox>
				<b-btn class="my-2" variant="primary" size="sm" @click="addGiocatoreLista" :disabled="!lista_selected">Salva</b-btn>
			</b-overlay>	
		</b-modal>
		<!-- <valutazioni-list-button v-if="player.osservato" :ref="'list_button_' + player.wyId" :address="{ Persona: player.osservato.id }"></valutazioni-list-button> -->


		<!-- <pre>{{thePlayer}}</pre>
			<pre>{{results}}</pre> -->
		</div>
	</template>

	<script>

	var Auth = require('../../../auth.js');
	import moment from 'moment';
	import * as types from '../../../store/types';

	export default {
		props: [ 'player', 'team', 'address', 'entita', 'valutazioniPlayer' ],
		components: {
			ValutazioneButton: require('@/components/layout/Valutazioni/Button.vue').default,
			SingolaValutazione: require('@/components/layout/Valutazioni/SingolaValutazione.vue').default,
			flag: require('@/components/layout/flag.vue').default,
		},
		data: function() {
			return {
				currentValutazione: null,
				results: null,
				liste: null,
				lista_selected: null,
				ux: {
					show: false
				}
			}
		},
		filters: {
			date: function(i) {
				return moment(i).format('DD/MM/YYYY')
			},
			formatYear: function(date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('YY'); 
				}
				return "";
			},
			formatDateHour: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('DD/MM/YYYY HH:mm'); 
				}
				return "";
			},
		},
		computed: {
			overallEvaluation: function() {
				return _.map(_.map(this.results, 'evaluation.conclusion.total.value'), function(i) {
					return _.ceil(i);
				});
			},
			thePlayer: function() {
				var player = null;
				if(this.entita == 'ws_player') {
					var ruolo = '';
					if(this.player.osservato && this.player.osservato.last_carriera_sportiva.ruoli.length > 0) {
						var ruoloIdeale = this.player.osservato.last_carriera_sportiva.ruoli.find(item => item.pivot.ideale);
						if(ruoloIdeale) {
							ruolo = ruoloIdeale.sigla;
						} else {
							ruolo = this.player.osservato.last_carriera_sportiva.ruoli[0].sigla;
						}
					}
					player = {
						id: this.player.wyId,
						nome_breve: this.player.shortName,
						cognome: this.player.lastName,
						nome: this.player.firstName,
						nome_breve: this.player.shortName,
						nascita_data: this.player.birthDate,
						avatar_url: this.player.imageDataURL,
						persona_id: this.player.osservato ? this.player.osservato.id : null,
						codice_nascita: this.player.birthArea && this.player.birthArea.alpha2code ? this.player.birthArea.alpha2code : null,
						codice_passaporto: this.player.birthArea && this.player.passportArea && this.player.passportArea.alpha2code != this.player.birthArea.alpha2code ? this.player.passportArea.alpha2code : null,
						ruolo: ruolo,
						ruolo_group: this.player.role.code2.toLowerCase(),
					}
				} else if(this.entita == 'Persona') {
					var ruolo = '';
					if(this.player.last_carriera_sportiva && this.player.last_carriera_sportiva.ruoli.length > 0) {
						var ruoloIdeale = this.player.last_carriera_sportiva.ruoli.find(item => item.pivot.ideale);
						if(ruoloIdeale) {
							ruolo = ruoloIdeale.sigla;
						} else {
							ruolo = this.player.last_carriera_sportiva.ruoli[0].sigla;
						}
					}
					var passaporti = this.player.documenti.filter(item => item.documento && item.documento.documento_tipo_glo_id == 2);
					player = this.player;
					player.persona_id = player.id;
					player.nome_breve = player.nome.charAt(0) + '. ' + player.cognome;
					player.ruolo = ruolo;
					player.codice_nascita = player.nascita_paese ? player.nascita_paese.sigla : null;
					player.codice_passaporto = passaporti.length > 0 && passaporti[0].documento.paese ? passaporti[0].documento.paese.sigla : null; 
					player.ruolo_group = player.ruolo == 'PO' ? 'gk' : 'md';
				}

		//	if(this.player.osservato) return this.player.osservato;
		//	return a;
				return player;
			},
		},
		watch: {
			address: {
				immediate: true,
				handler: function() {
					this.fetchAddress();
				}
			}
		},
		methods: {
			getBgColor(value) {
				var color = '#FFFFFF';
				var valore = _.ceil(value);
				if(valore == 5) {
					color = '#46bdc6';
				} else if(valore == 4) {
					color = '#34a853';
				} else if(valore == 3) {
					color = '#ff6d01';
				} else if(valore == 2) {
					color = '#ea4336';
				} else if(valore == 1) {
					color = '#674ea7';
				}
				return color;
			},
			getVotoValutazione(val) {
				return val.evaluation.conclusion.total.value;
			},
			getGiudizioValutazione(val) {
				return val.evaluation.conclusion.total.note;
			},
			selectValutazione(val) {
				if(this.currentValutazione && this.currentValutazione.id == val.id) {
					this.currentValutazione = null;
				} else {
					this.currentValutazione = val;
				}
			},
			showAmount: function(amount) {
				return this.overallEvaluation ? _.filter(this.overallEvaluation, function(p) { return p == amount; }).length : 0;	
		//	var i = _.filter(this.overallEvaluation, function(p) { return p == amount; }).length;
		//	if(i) return '('+i+')'
			},
			fetchAddress: function() {
				this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/has', { address: this.address }).then(function(data) {
					this.results = data.data;
				});
			},
			deleteValutazione: function(id) {
				if(confirm("Confermi di voler eliminare la valutazione ?")) {
					this.$http.get(this.$store.state.apiEndPoint + '/fr-valutazione/delete/' + id).then(function(data) {
						if(this.currentValutazione && this.currentValutazione.id == id) {
							this.currentValutazione = null;
						}
						this.fetchAddress();
					});
				}
			},
			getTeamHome(partita) {
				if(!partita)
					return null;
				return partita.home; 
			/*	var squadre = partita.squadre;
				if(squadre && squadre.length > 1) {
					if(squadre[0].pivot.side == 'home') {
						return squadre[0];
					} else {
						return squadre[1];
					}
				}  */
				return null;
			},

			getTeamAway(partita) {
				if(!partita)
					return null; 
				return partita.away;
			/*	var squadre = partita.squadre;
				if(squadre && squadre.length > 1) {
					if(squadre[0].pivot.side == 'home') {
						return squadre[1];
					} else {
						return squadre[0];
					}
				}  */
				return null;
			},
			showModalListe() {
				if(!this.liste) {
					this.fetchListe();
				}
				this.$refs.modalListe.show();
			},
			fetchListe: function() {
				this.$http.get(this.$store.state.apiEndPoint + '/liste', {params: {no_players: true, no_condivise: true, no_info: true }}).then(function(data) {
					this.liste = data.data;
				});
			},
			addGiocatoreLista: function() {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/add/giocatore/lista/' + this.lista_selected + '/' + this.thePlayer.persona_id, {params: {no_liste: true }}).then(function(data) {
					this.$refs.modalListe.hide();
					if(data.data == 'exist') {
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Il giocatore è già presente nella lista!', status: 0 });
					} else {
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Giocatore aggiunto alla lista!', status: 1 });
					}
				});
			},
		},
	}	

	</script>
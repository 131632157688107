<template>
	<div>
		<b-nav>
			<b-nav-item :class="{ 'sel': isSelected('Profile') }" :to="{ name: 'PlayerPersonaDashboard' }" :style="isSelected('Profile') ? 'background-color: rgba(0,0,0,.4);' : ''">Profilo</b-nav-item>
			<b-nav-item :class="{ 'sel': isSelected('Scouting') }" :to="{ name: 'PlayerPersonaEvaluations' }" :style="isSelected('Scouting') ? 'background-color: rgba(0,0,0,.4);' : ''">Valutazioni <b-badge v-if="player.valutazioni > 0">{{player.valutazioni}}</b-badge></b-nav-item>
			<b-nav-item :class="{ 'sel': isSelected('Notes') }" :to="{ name: 'PlayerPersonaNotes' }" :style="isSelected('Notes') ? 'background-color: rgba(0,0,0,.4);' : ''">Note <b-badge v-if="player.note > 0">{{player.note}}</b-badge></b-nav-item>
			<b-nav-item :class="{ 'sel': isSelected('Injuries') }" :to="{ name: 'PlayerPersonaInjuriesDashboard' }" :style="isSelected('Injuries') ? 'background-color: rgba(0,0,0,.4);' : ''">Infortuni</b-nav-item>
			<b-nav-item :class="{ 'sel': isSelected('Stats') }" :to="{ name: 'PlayerPersonaStats' }" :style="isSelected('Stats') ? 'background-color: rgba(0,0,0,.4);' : ''">Stats</b-nav-item>
			<!-- <b-nav-item :to="{ name: 'PlayerPersonaInjuriesDashboard' }" :style="isSelected('Injuries') ? 'background-color: rgba(0,0,0,.4);' : ''">Injuries</b-nav-item> -->
		</b-nav>
	</div>
</template>

<script>

export default {
	props: [ 'player' ],
	methods: {
		isSelected: function(name) {
			return name === _.last(this.$route.matched).meta.v2.area;
		}
	}
}	

</script>

<style scoped>

.sel a {
	color: white;
}

</style>
import _ from 'lodash';

export default {
	components: {
	},
	methods: {		
        getCompetitions: function() {
            return this.competitions;
        }
	},
	mounted: function() {
        this.$store.dispatch('scout/getCompetitions')
	}
};
import pageBase from '@/mixins/pageBase.js'
import * as types from '@/store/types'

export default {
	mixins: [ pageBase ],
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		valutazioniSelezionate: function() {
			return _.filter(this.valutazioni, { utente_id: this.utente_id });
		},
		valutazioneSquadra: function() {
			var squadra = _.first(_.filter(this.valutazioni, { utente_id: this.utente_id }));
			if(!squadra) return false;
			return _.first(_.filter(squadra.partite, { id_partita: parseInt(this.partiteId) }));
		},
		valutazioniByUtente: function() {
			return _.map(_.groupBy(this.valutazioni, 'utente_id'), function(b) {

				return {
					value: _.first(b).utente.id,
					label: _.first(b).utente.cognome + ' ' + (_.first(b).utente.nome.substr(0,1)) + '. (' + ( this.moment(_.first(b).created_at).format('D/M/YY') ) + ')',
					valutazioni: b
				}

			}.bind(this));
		}
	},
	components: {
		Intestazione: require('@/components/layout/nextscouting/intestazione-partita.vue').default,
		Campetto: require('@/components/layout/nextscouting/campetto.vue').default,
		ListaGiocatori: require('@/components/layout/nextscouting/lista-giocatori.vue').default,
		ListaValutazioni: require('@/components/layout/nextscouting/lista-valutazioni.vue').default,
		EditValutazioni: require('@/components/layout/nextscouting/edit-valutazioni.vue').default,
		EditFormazione: require('@/components/layout/nextscouting/edit-formazione.vue').default,
		ListaGiocatoriScout: require('@/components/layout/scout/lista-giocatori.vue').default,
		IntestazioneScout: require('@/components/layout/scout/intestazione-partita.vue').default,
		ValutazioniPartita: require('@/components/layout/scout/valutazioni-partita.vue').default,
		CampettoScout: require('@/components/layout/scout/campetto.vue').default,
		EditFormazioneScout: require('@/components/layout/scout/edit-formazione.vue').default,
		EditPartita: require('@/components/layout/scout/edit-partita.vue').default,
	},
	methods: {

		exitMode: function() {
		//	console.log(this.valutazioni);
			if(this.ux.detail == true) {
				this.ux.detail = false;
			} else if(this.ux.formazioni == true) {
				this.ux.formazioni = false;
			} else {
				this.ux.editing = false; 
				this.utente_id = this.valutazioni && this.valutazioni.length ? (_.first(_.cloneDeep(this.valutazioni)).utente_id) : null;
			}

		},
		reset: function() {

			if(this.utente_id)
				this.utente_id = null;

			if(this.ux.formazioni)
				this.ux.formazioni = false;

			if(this.ux.detail)
				this.ux.detail = false;

			if(this.ux.editing)
				this.ux.editing = false;

		},
		doSalvaFormazione: function(payload) {

			this.$emit('wait');
			this.$http.post(this.$store.state.apiEndPoint + '/nazionale/salva/formazione', payload).then(function(data) {

				if(this.partiteId) {
					this.$emit('ready');
					this.start();
				} else {

					var params = {
						paese_id: this.$route.params.paese_id,
						competizione_id: this.$route.params.competizione_id,
						squadra_id: this.$route.params.squadra_id,
						partite_id: data.data.partita_id
					};

					var name = this.area + '_next_club_paese_competizione_squadra_partita_manuale';
					
					if(this.wyId) {
						name = this.area + '_next_club_paese_competizione_squadra_partita_partitewy';
						params.wyId = this.wyId;
					}

					this.$router.push({ name, params });

				}

			});

		},
		salvaValutazioni: function(area, valutazioni) {

			this.$emit('wait');
			this.$http.post(this.$store.state.apiEndPoint + '/nazionale/salva/valutazioni/' + area, valutazioni).then(function(data) {

				this.$store.commit(types.ALERTS_PUSH, { msg: data.data.message, status: 1 });
				
				if(this.partiteId) {
					this.$emit('ready');
					this.start();
				} else {

					var params = {
						paese_id: this.$route.params.paese_id,
						competizione_id: this.$route.params.competizione_id,
						squadra_id: this.$route.params.squadra_id,
						partite_id: data.data.partita_id
					};

					var name = this.area + '_next_club_paese_competizione_squadra_partita_manuale';
					
					if(this.wyId) {
						name = this.area + '_next_club_paese_competizione_squadra_partita_partitewy';
						params.wyId = this.wyId;
					}

					this.$router.push({ name, params });

				}
			});
		},
		fetchRuoli: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then(function(data) {
				this.ruoli = data.data;
			});
		},
		fetchGradimenti: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then(function(data) {
				this.gradimenti = data.data;
			});
		},
		fetchFormazioneManuale: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/formazione/' +  this.wyId).then(function(data) {
				if(!data.data) {
					this.formazioneManuale = { home: [], away:[], id: null, id_partita: null, wyscout_match_id: this.wyId};
				} else {
					data.data.home = JSON.parse(data.data.home);
					data.data.away = JSON.parse(data.data.away);
					this.formazioneManuale = data.data;
				}
			});
		},
		fetchMatchFormationsWS: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/match/formations/' +  this.wyId).then(function(data) {
				this.matchFormationsWs = data.data;
			});
		},
		fetchMatch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/partita/' +  this.partiteId).then(function(data) {
				this.match = data.data;
			});
		},
		fetchPartita: function() {
			this.match = null;
			this.$http.get(this.$store.state.apiEndPoint + '/scout/partita/' +  this.partiteId).then(function(data) {
				this.match = data.data;
				this.match.label = this.match.home_team + ' - ' + this.match.away_team + ', ' + this.match.home_score + '-' + this.match.away_score;
			});
		},
		fetchMatchWithPlayerWS: function() {
			this.$emit('wait');
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/match/with/players/' +  this.wyId).then(function(data) {
				this.$emit('ready');
				this.matchWs = data.data;
			});
		},
		fetchValutazioni: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/valutazioni/partita/' +  this.wyId + '/' + this.area).then(function(data) {
				this.valutazioni = data.data;
				for (var i in this.valutazioni) {
					var lingua_obj = {
						note_1: {}
					};
					for(var j in this.valutazioni[i].lingue) {
						var lingua = this.valutazioni[i].lingue[j];
						lingua_obj.note_1[lingua.lingua_glo_id] = lingua.note_1;
						if(!this.lingua.master && lingua.master) {
							this.lingua.selected = lingua.lingua_glo_id;
							this.lingua.master = lingua.lingua_glo_id;
						}
					}
					this.valutazioni[i].lingua = lingua_obj;
				}
				if(!this.lingua.selected) {
					this.lingua.selected = 1;
					this.lingua.master = 1;
				}
				if(this.utente_id !== undefined && this.valutazioni && this.valutazioni.length) {
					this.utente_id = _.cloneDeep(_.first(this.valutazioni).utente_id);
				}
			});
		},
		fetchValutazioniManuali: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/valutazioni/partita/inserita/' +  this.partiteId + '/' + this.area).then(function(data) {
				this.valutazioni = data.data;
				for (var i in this.valutazioni) {
					var lingua_obj = {
						note_1: {}
					};
					for(var j in this.valutazioni[i].lingue) {
						var lingua = this.valutazioni[i].lingue[j];
						lingua_obj.note_1[lingua.lingua_glo_id] = lingua.note_1;
						if(!this.lingua.master && lingua.master) {
							this.lingua.selected = lingua.lingua_glo_id;
							this.lingua.master = lingua.lingua_glo_id;
						}
					}
					this.valutazioni[i].lingua = lingua_obj;
				}
				if(!this.lingua.selected) {
					this.lingua.selected = 1;
					this.lingua.master = 1;
				}
				if(this.utente_id !== undefined && this.valutazioni && this.valutazioni.length) {
					this.utente_id = _.cloneDeep(_.first(this.valutazioni).utente_id);
				}
			});
		},
		fetchFormazioneManualeManuale: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/formazione/partita/' +  this.partiteId).then(function(data) {
				var formazione = data.data.formazione;
				if(formazione) {
					data.data.formazione.home = JSON.parse(formazione.home);
					data.data.formazione.away = JSON.parse(formazione.away);
				} else {
					data.data.formazione = {
						home: [],
						away: [],
						id: null,
						id_partita: this.partiteId,
					}
				}
				this.formazioneManualeManuale = data.data;
			});
		},
		interfaccia_fixValutazioneSquadra(contenitore, destinazione, origine) {

			if(origine == 1)
				contenitore[destinazione] = 3;
			else if(origine == 3)
				contenitore[destinazione] = 1;
			else
				contenitore[destinazione] = origine;

		}
	}
};
export const ROUTES_SCOUT = [
    // {
    //     path: '/scout/dashboard',
    //     redirect: '/scout/esplora',
    // },
    {
        path: '/scout/dashboard',
        name: 'scout_dashboard',
        component: require('../components/pages/scout/dashboard.vue').default,
        meta: {
            module: 'scout',
            label: 'Dashboard',
            requiresAuth: true,
        //  showInMenu: false,
            showInTabs: true,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/esplora',
        name: 'scout_continenti',
        component: require('../components/pages/scout/continenti.vue').default,
        meta: {
            module: 'scout',

            label: 'Esplora',
            requiresAuth: true,
        //  showInMenu: false,
            showInTabs: true,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/cerca',
        name: 'scout_cerca',
        component: require('../components/pages/scout/cerca.vue').default,
        meta: {
            module: 'scout',

            label: 'Cerca',
            requiresAuth: true,
        //  showInMenu: false,
            showInTabs: true,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/liste',
        name: 'scout_liste',
        component: require('../components/pages/scout/liste.vue').default,
        meta: {
            module: 'scout',

            label: 'Liste',
            requiresAuth: true,
        //  showInMenu: false,
            showInTabs: true,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/lista/in/campo/:id/:formazioneId?',
        name: 'scout_lista_in_campo',
        component: require('../components/pages/scout/lista_in_campo.vue').default,
        meta: {
            module: 'scout',

            label: 'Lista',
            requiresAuth: true,
        //  showInMenu: false,
            showInTabs: false,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/valutazioni/partita/:id',
        name: 'scout_valutazioni_partita',
        component: require('../components/pages/scout/valutazioni-partita.vue').default,
        meta: {
            module: 'scout',

            label: 'Valutazioni Partita',
            requiresAuth: true,
        //  showInMenu: false,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/paesi/:continente_id',
        name: 'scout_paesi',
        component: require('../components/pages/scout/paesi.vue').default,
        meta: {
            module: 'scout',

            label: 'Paesi',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/competizioni/:continente_id/:paese_id',
        name: 'scout_competizioni',
        component: require('../components/pages/scout/competizioni.vue').default,
        meta: {
            module: 'scout',

            label: 'Competizioni',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/nazionali/:continente_id/:paese_id',
        name: 'scout_nazionali',
        component: require('../components/pages/scout/paese-nazionali.vue').default,
        meta: {
            module: 'scout',

            label: 'Nazionali',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/competizione/:continente_id/:paese_id/:competizione_id',
        name: 'scout_competizione',
        component: require('../components/pages/scout/competizione.vue').default,
        meta: {
            module: 'scout',

            label: 'Competizione',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
        }
    },
    {
        path: '/scout/nazionali/squadra/:continente_id/:paese_id/:squadra_id',
        name: 'scout_nazionali_squadra',
        component: require('../components/pages/scout/squadra.vue').default,
        meta: {
            module: 'scout',
            area: 'new',
            label: 'Squadra',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
            contesto: 'giocatori',
        }
    },
    {
        path: '/scout/competizione/squadra/:continente_id/:paese_id/:competizione_id/:squadra_id',
        name: 'scout_competizione_squadra',
        component: require('../components/pages/scout/squadra.vue').default,
        meta: {
            module: 'scout',
            area: 'new',
            label: 'Squadra',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
            contesto: 'giocatori',
        }
    },
    {
        path: '/scout/squadra/:squadra_id',
        name: 'scout_squadra',
        component: require('../components/pages/scout/squadra.vue').default,
        meta: {
            module: 'scout',
            area: 'new',
            label: 'Squadra',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
            contesto: 'giocatori',
        }
    },
    {
        path: '/scout/:continente_id/:paese_id/:competizione_id/:squadra_id/evento-partita/:partita_id',
        name: 'scout_competizione_squadra_partita',
        component: require('../components/pages/scout/squadra.vue').default,
        meta: {
            module: 'scout',
            area: 'new',
            label: 'Partita',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
            contesto: 'partita',
        }
    },
    {
        path: '/scout/:continente_id/:paese_id/:competizione_id/:squadra_id/evento-partite-wyscout/:partite_id/:wy_id',
        name: 'scout_competizione_squadra_partita_partitewy',
        component: require('../components/pages/scout/squadra.vue').default,
        meta: {
            module: 'scout',
            area: 'new',
            label: 'Partita',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
            contesto: 'partita-partite-wyscout',
        }
    },

    {
        path: '/scout/:continente_id/:paese_id/:competizione_id/:squadra_id/evento-partite-wyscout/:partite_id/:wy_id',
        name: 'scout_competizione_squadra_partita_manuale',
        component: require('../components/pages/scout/squadra.vue').default,
        meta: {
            module: 'scout',
            area: 'new',
            label: 'Partita',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
            contesto: 'partita-partite',
        }
    },

    {
        path: '/scout/:continente_id/:paese_id/:competizione_id/:squadra_id/evento-wyscout/:wy_id',
        name: 'scout_competizione_squadra_partita_wy',
        component: require('../components/pages/scout/squadra.vue').default,
        meta: {
            module: 'scout',
            area: 'new',
            label: 'Partita',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
            contesto: 'partita-wyscout',
        }
    },

    {
        path: '/scout/giocatore/:id',
        name: 'scout_giocatore',
        component: require('../components/pages/scout/giocatore.vue').default,
        meta: {
            module: 'scout',
            label: 'Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
        }
    },

];

<template>
	<section class="next-matches">
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card title="Next Matches" :no-body="isWidget ? true : false">
			
            <b-tabs v-model="activeIndex" @activate-tab="onTabActivate">
				<b-tab v-for="(tab, index) in tabs" :key="tab.id" :title="tab.label" @click="selectTab(index, true)">
					
                    <b-table-simple class="text-center mb-0" :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 54) + 'px' : '70vh'" striped responsive fixed>
						<b-thead>
							<b-tr>
								<b-th>
                                    Data
                                </b-th>
								<b-th>
                                    Partita
                                </b-th>
                            </b-tr>
					    </b-thead>
                        <b-tbody ref="tbodyScrollContainer">
                            <b-tr 
								v-for="match in rows"
								role="button"
								@click="openBlankUrl($router.resolve(`/scout/0/0/0/${match.home.id}/evento-partita/${match.id}`).href , '_blank' )"
                                :key="match.id"
                            >
                                <b-td class="align-middle">
                                    {{ moment(match.data).format('DD/MM/YYYY HH:mm') }}
                                </b-td>
                                <b-td class="d-flex justify-content-center align-items-center align-middle">
                                    <div>
										<LazyImage
											@click.stop="openBlankUrl($router.resolve(`/scout/competizione/squadra/0/0/0/${match.home.id}`).href , '_blank' )"
											:customStyle="logoStyle"
											:src="match.home.logo_url"
											v-b-tooltip.hover :title="match.home.officialName" :alt="match.home.officialName"
										/>
                                    </div>
                                    <div class="px-4">
                                        VS
                                    </div> 
                                    <div>
										<LazyImage
											@click.stop="openBlankUrl($router.resolve(`/scout/competizione/squadra/0/0/0/${match.away.id}`).href , '_blank' )"
											:customStyle="logoStyle"
											:src="match.away.logo_url" 
											v-b-tooltip.hover :title="match.away.officialName" :alt="match.away.officialName" 
										/>
                                    </div>
                                </b-td>
                            </b-tr>

							<b-tr role="button" v-if="!disableInfiniteScroll">
								<b-td colspan="2">
									<b-button size="sm" variant="primary" class="w-100" @click="getNextInfinitePage">
										Carica altro
									</b-button>
								</b-td>
							</b-tr>

                        </b-tbody>
			        </b-table-simple>

		        </b-tab>

				<b-tab title="Aggiungi" @click="showCompetitionSelectModal"></b-tab>
	        </b-tabs>

			<!-- Select tab competition modal  -->
			<b-modal
				ref="selectCompetition" 
				title="Seleziona competizioni da visualizzare" 
				@ok="updateTabs"
			>
				<div>
					<div class="mb-2"> Cerca competizione </div>

					<AutocompleteCompetition
						:suggestions="competitions" 
						ref="autocompleteCompetitions" 
						@select="selectCompetitionEvent"
					>
					</AutocompleteCompetition>

					<b-table-simple class="text-center mb-0" striped responsive fixed :sticky-header="'60vh'">
						<b-thead>
							<b-tr>
								<b-th> Competizione </b-th>
								<b-th> Azioni </b-th>
							</b-tr>
						</b-thead>
						
						<b-tbody>
							<b-tr v-for="competition,index in tabs">
								<b-td> {{ competition.label }} </b-td>
								<b-td> 
									<b-button
										v-if="index !== activeIndex"
										size="sm"
										@click="unsetCompetition(competition.id)"
									>
										<i class="fa fa-trash" aria-hidden="true"></i>
									</b-button>
								</b-td>
							</b-tr>
						</b-tbody>
						
					</b-table-simple>
				</div>
			</b-modal>

        </b-card>
    </section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
import { mapState } from 'vuex';
var Auth = require('@/auth.js');

export default {
	mixins: [ pageBase ],
	components: {
		AutocompleteCompetition: require('@/components/layout/autocomplete_competition.vue').default,
	},
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function() {
		return {
			loading: false,
			tabs: [],
			rows: [],
			activeIndex: 0,
			infinitePage: 1,
			disableInfiniteScroll: false,
			logoStyle: {
				maxWidth: '35px',
				maxHeight: '35px',
				width: 'auto',
				height: 'auto'
			}
		}
	},
	mounted() {
		this.setTabs();
		this.fetchMainCompetitions();
	},
	computed: {
		user: function() {
			return Auth.user;
		},
		...mapState( 'scout', ['competitions'] ),
	},
	watch: {
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},
	},
	created: function() {
	},
	methods: {
		unsetCompetition( competitionId ) {
			/** Get active competition index before unset */
			const activeCompetition = this.tabs[this.activeIndex].id
			
			/** Unset removed tab competition */
			this.tabs = this.tabs.filter( ( tab ) => tab.id !== competitionId )
			
			/** Get newActiveIndex */
			const newActiveIndex = this.tabs.findIndex( ( tab ) => tab.id === activeCompetition )
	
			/** Update WidgetConfig */
			setTimeout( () => {
				this.updateTabs();
				this.selectTab(newActiveIndex, false)
			}, 100 );
		},
		selectCompetitionEvent(competition) {
			const competitionExists = this.tabs.find( ( tab ) => tab.id === competition.id );
			
			if( !!!competitionExists ){				
				this.tabs.push({
					id: competition.id,
					label: `${competition.nome} [${competition.paese.sigla}]`
				})
			}
		},
		onTabActivate(newIndex, oldIndex, event ) {
			/** Prevent change on next tab click ("Aggiungi") */
			if (newIndex === this.tabs.length) {
				event.preventDefault();
			}else{
				this.activeIndex = newIndex;
			}
		},
		showCompetitionSelectModal() {
			this.$refs.selectCompetition.show();
		},
		selectTab(index, fetchMatches) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'activeTab', index);
				this.activeIndex = index

				if( fetchMatches ){
					this.fetchNextMatches();
				}
			}
		},
		updateTabs() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'tabs', this.tabs);	
			}
		},
		refreshData: function() {
			this.tabs = [];
			this.fetchMainCompetitions();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		fetchMainCompetitions: function() {
			this.activeIndex = 0;
			this.loading = true;

			if( this.tabs.length === 0 ){

				this.$http.get( this.$store.state.apiEndPoint + '/scout/competizioni_principali' )
					.then( ( { body: mainCompetitions } ) => {					

						this.tabs = mainCompetitions.map( ( mainCompetition ) => (({
							id: mainCompetition.id,
							label: mainCompetition.nome
						})))
						
						this.updateTabs();

						this.loading = false;

						_.delay( function() {
							this.setTabActive();
							this.fetchNextMatches();
						}.bind(this), 100 );
					})

			}else{

				_.delay( function() {
					this.setTabActive();
					this.fetchNextMatches();
				}.bind(this), 100 );

			}
			
		},
		fetchNextMatches: function() {
			this.rows = [];
			this.loading = true;
            
			const competitionId = this.tabs[this.activeIndex].id
			this.$http.get( `${this.$store.state.apiEndPoint}/scout/prossime_partite?competition=${competitionId}`)
            	.then( function( { data }) {
					this.rows = data
					this.loading = false;
				});
		},
		getNextInfinitePage(){
			this.loading = true;
			this.infinitePage += 1;
			const competitionId = this.tabs[this.activeIndex].id

			this.$http.get( `${this.$store.state.apiEndPoint}/scout/prossime_partite?competition=${competitionId}&page=${this.infinitePage}`)
            	.then( function( { data }) {
					if( data && data.length > 0 ){
						this.rows = this.rows.concat(data)
					}else{
						this.disableInfiniteScroll = true;
					}
					this.loading = false;
				});
		},
		setTabs() {
			this.tabs = this.configWidget && this.configWidget.tabs != undefined ? this.configWidget.tabs : [];
		},
		setTabActive() {
			this.activeIndex = this.configWidget && this.configWidget.activeTab != undefined ? this.configWidget.activeTab : 0;
		}
	}
};
</script>

<style>

.next-matches .nav.nav-tabs{
	overflow-x: scroll;
	flex-wrap: nowrap;
	padding-bottom: 5px;
}

</style>
<template>
	<div>
		<b-card no-body class="p-2 mb-1" v-for="(player, index) in theFormazione" :bg-variant="index % 2 == 0 ? 'light' : 'default'">
			<b-row>
				<b-col cols="1">
					<strong>{{ player.maglia }}</strong>
				</b-col>
				<b-col cols="1">
					<em>{{ player.ruolo ? player.ruolo.sigla : ''}}</em>
				</b-col>
				<b-col>
					<div> {{ player.persona.nome.charAt(0) + '. ' + player.persona.cognome }}</div>
				</b-col>
				<b-col cols="4">
					<valutazioni-list-button :ref="'list_button_' + player.persona_id" :address="{ Persona: player.persona_id ? player.persona_id : null, Partita: match.id }" :showGrade="true" :player="player"></valutazioni-list-button>
				</b-col>
				<b-col cols="1">
					<valutazione-button 
						nm="+" 
						:player="{ id: player.persona_id, name: player.persona.nome, surname: player.persona.cognome, role: player.posizione_id, avatar: player.persona.avatar_url, persona_id: player.persona_id ? player.persona_id : null, team: (match.home_id == teamId ? match.home_team : match.away_team ) }" 
						:match="match" 
						@refresh="refreshListButton" 
						title="Nuova valutazione"
						:valutazioniPlayer="valutazioniPlayer"
					>
					</valutazione-button>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			valutazioniPlayer: null
		}
	},
	components: {
		ValutazioneButton: require('@/components/layout/Valutazioni/Button.vue').default,
		ValutazioniListButton: require('@/components/layout/Valutazioni/ListButton.vue').default,
	},
	props: [ 'match', 'teamId', 'ruoli' ],
	methods: {
		refreshListButton(playerId) {
			this.$refs['list_button_' + playerId][0].fetchAddress();
		},
		hasValBreve: function(persona_id) {
			return this.valutazioni.find(item => item.persona_id == persona_id && item.breve);
		},
		isValutato: function(persona_id, grad) {
			return this.valutazioni.find(item => item.persona_id == persona_id && item.id_gradimento == grad);
		},
		setPlayersScoutingGlossary: function() {
			this.$http.get( this.$store.state.apiEndPoint + `/fr-valutazione/glo?area=scouting&entity=Persona` )
				.then( function({data}){	
					this.valutazioniPlayer = data.map( ( glossary ) => glossary )
				});
		},
		getRuolo: function(posizione_id) {
			return this.ruoli.find(item => item.id == posizione_id);
		}

	},
	computed: {
		theFormazione: function() {
			var formazione = this.match.persona_rel.map(obj => (
				{ ...obj,
				 ruolo: this.getRuolo(obj.posizione_id)}
			));
			var titolari = _.sortBy(formazione.filter(item => item.team_id == this.teamId && item.partita_persona_tipo_glo_id == 1), ['ruolo.sigla_tools']);
			var subInIds = this.match.eventi.filter(item => item.team_id == this.teamId && item.partita_evento_tipo_glo_id == 7 && item.minuto > 0).map(item => item.persona_id);
			var sostituzioni =  _.sortBy(formazione.filter(item => item.team_id == this.teamId && subInIds.includes(item.persona_id)), ['ruolo.sigla_tools']);
			var formazione = titolari.concat(sostituzioni);
			return formazione;
		}
	},
	created: function() {
		this.setPlayersScoutingGlossary()
	}
}

</script>
<template>
	<div>
		<b-overlay :show="!match" no-wrap></b-overlay>
		<IntestazioneScout :match="match" :readonly="true" v-if="match">
			
			<slot slot="left">
				<b-button v-if="isShowFormazioni" @click.prevent="showFormazioni" variant="secondary" title="Gestisci formazioni"><em class="fa fa-list"></em></b-button>
				<!--span v-if="ux.formazioni">
					<b-button :disabled="!ux.canSaveFormazioni" @click.prevent="doSalvaFormazione(formazioneManualeManuale.formazione)" variant="primary"><em class="fa fa-floppy-o"></em></b-button>
				</span-->
			</slot>
			<slot slot="right">
				<b-button v-if="!ux.formazioni" :to="{ name: 'scout_valutazioni_partita', params: { id: partiteId } }" target="_blank"><i class="fa fa-search"></i></b-button>
				<b-button v-else @click.prevent="exitMode" variant="secondary"><em class="fa fa-sign-out"></em></b-button>
			</slot>

		</IntestazioneScout>
		
		<div v-if="!ux.formazioni">
			
			<CampettoScout :readonly="true" @change.prevent="false" :match="match">
			</CampettoScout>	

			<b-row v-if="ux.detail == false">
				<b-col cols="6">
					<ListaGiocatoriScout v-if="match" :match="match" :teamId="match.home_id" :ruoli="ruoli"></ListaGiocatoriScout>
				</b-col>
				<b-col cols="6">
					<ListaGiocatoriScout v-if="match" :match="match" :teamId="match.away_id" :ruoli="ruoli"></ListaGiocatoriScout>
				</b-col>
			</b-row>
		</div>
		<div v-if="ux.formazioni == true">
			<EditPartita :match="match" :ruoli="ruoli" :team="team" @matchUpdated="matchUpdated"/>
		</div>
	</div>
</template>

<script>

	import pageBase from '@/mixins/pageBase.js'
	import scoutingBase from '@/mixins/scoutingBase.js'
	import linguaBase from '@/mixins/linguaBase.js'

	export default {
		mixins: [ pageBase, scoutingBase, linguaBase ],
		props: [ 'partiteId', 'team' ],
		data: function() {
			return {
				loadingMatch: false,
				utente_id: null,
				formazioneManuale: null,
				formazioneManualeManuale: null,
				matchFormationsWs: null,
				match: null,
				valutazioni: null,
				payloadSubmit: {},
				payloadFormazioni: {},
				ruoli: null,
				gradimenti: null,
				formValutazione: {
					modalita: null,
					voto_home: null,
					voto_away: null
				},
				ux: {
					canSaveValutazioni: false,
					canSaveFormazioni: false,
					detail: false,
					formazioni: false,
					editing: false
				},
				lingue: [],
				lingua: {
					selected: null,
					master: null,
				}
			}
		},
		watch: {
			'formazioneManualeManuale.formazione': {
				deep: true,
				handler: function() {

					if(!this.$refs.formhome || !this.$refs.formaway) return false;

					var hasHomeErrors = _.filter(this.$refs.formhome.errors, function(b) { return b.length > 0 }).length > 0;
					var hasAwayErrors = _.filter(this.$refs.formaway.errors, function(b) { return b.length > 0 }).length > 0;

					this.ux.canSaveFormazioni = !hasHomeErrors && !hasAwayErrors;

				}
			},
			utente_id: {
				handler: function() {
					this.formValutazione.voto_home = this.valutazioneSquadra.voto_team;
					this.formValutazione.voto_away = this.valutazioneSquadra.voto_opponent;
					this.formValutazione.modalita = this.valutazioneSquadra.modalita;
				}
			},
			partiteId: {
				immediate: true,
				handler: function() {
					this.start();
				}
			}
		},
		methods: {
			matchUpdated: function() {
				this.ux.formazioni = false;
				this.fetchPartita();
			},
			start: function() {
				this.ux.editing = false;
				this.fetchPartita();
			   	this.fetchRuoli();
			},
			showFormazioni: function() {
				this.ux.formazioni = true;
			},
			importLastVal: function() {

				if(confirm()) {
					this.$refs.valhome.importVal();
					this.$refs.valaway.importVal();
				}

			},
			showDetail: function() {
				this.ux.detail = !this.ux.detail;
			},
			showEdit: function() {
				this.ux.editing = !this.ux.editing;
			},
			createPayloadFormazioni: function() {
				this.payloadFormazioni = {};
			},
			createPayload: function() {

				if(!this.$refs.valhome || !this.$refs.valaway) return false;

				var out = {};

				_.extend(out, this.$refs.valhome.form);
				_.extend(out, this.$refs.valaway.form);

				out.wyscout_match_id = null;
				out.wyscout_match_label = this.label;
				out.id_partita = this.partiteId;
				out.voto_away = this.formValutazione.voto_away;
				out.voto_home = this.formValutazione.voto_home;
				out.modalita = this.formValutazione.modalita;

				out.lingua_master = this.lingua.master;

				this.payloadSubmit = out;


				if(_.filter(_.values(this.$refs.valhome.errors)).length > 0 || _.filter(_.values(this.$refs.valaway.errors)).length > 0)
					this.ux.canSaveValutazioni = false;
				else
					this.ux.canSaveValutazioni = true;


			}
		},
		computed: {
			label: function() {
				if(!this.match) return false;
				return this.match.home_team + ' - ' + this.match.away_team + ', ' + this.match.home_score + '-' + this.match.away_score;
			},
			isShowFormazioni: function() {

			//	return false;  /*** RAF ***/

				if(this.ux.formazioni) {
					return false;
				}
				if(this.match.imported && this.match.lineups_provider) {
					return false;
				}
				if(!this.match.scout && (this.match.home.societa.length || this.match.away.societa.length)) {
					return false;
				}
				return true;
			}
		}
	}

</script>
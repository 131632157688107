<template>
    <div class="lazy-image">
        <b-img
            v-show="loaded"
            :class="customClasses"
            :style="customStyle"
            :src="src"
            :alt="alt"
            :width="width"
            @load="onLoad"
            @error="onError"
        />
        <b-spinner v-if="!loaded" small />
    </div>
</template>

<script>
export default {
    props: {
        src: String,
        alt: String,
        customClasses: String,
        customStyle: String|Object,
        width: {
            type: String,
            default: "16"
        }
    },
    data() {
        return {
            loaded: false
        };
    },
    methods: {
        onLoad() {
            this.loaded = true;
        },
        onError() {
            this.loaded = false;
        }
    }
};
</script>

<style scoped>
.lazy-image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 16px;
    min-height: 16px;
}
</style>

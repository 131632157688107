<template>
	<div>
		<h5 class="color-rosso">Giocatori</h5>
		<b-card class="mb-1" v-for="i in giocatori" :title="i.name" v-if="i.players.length">
			<b-row>
				<b-col sm="2" class="mb-1" v-for="player in i.players">
					<b-card no-body class="p-2 h-100">

						<CardGiocatoreScout 
							:player="player" 
							:team="team" 
							:address="{ Persona: player ? player.id : null }" entita="Persona"
							:valutazioniPlayer="valutazioniPlayer">
						</CardGiocatoreScout>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import moment from 'moment'

export default {
	mixins: [ pageBase ],
	components: {
		CardGiocatoreScout: require('@/components/layout/scouting/Card.vue').default,
	},
	props: ['players', 'team'],
	filters: {
	},
	data: function() {
		return {
			valutazioniPlayer: null,
		}
	},
	methods: {
		setPlayersScoutingGlossary: function() {
			this.$http.get( this.$store.state.apiEndPoint + `/fr-valutazione/glo?area=scouting&entity=Persona` )
				.then( function({data}){	
					this.valutazioniPlayer = data.map( ( glossary ) => glossary )
				});
		}
	},
	computed: {
		giocatori: function() {

			return [ 
				{ name: 'Ruolo Sconosciuto', players: this.senza_ruolo },
				{ name: 'Portiere', players: this.portieri }, 
				{ name: 'Difensore', players: this.difensori }, 
				{ name: 'Centrocampista', players: this.centrocampisti }, 
				{ name: 'Attaccanti', players: this.attaccanti }
			];

		},
		portieri: function() {
			return this.players.filter(item => item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length > 0 && item.last_carriera_sportiva.ruolo_ideale[0].sigla == 'PO');
		},
		difensori: function() {
			return this.players.filter(item => item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length > 0 && ['DC','DCD','DCS','DID','DIS','TD','TS'].includes(item.last_carriera_sportiva.ruolo_ideale[0].sigla));
		},
		centrocampisti: function() {
			return this.players.filter(item => item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length > 0 && ['EBD','EBS','CM','CID','CIS','CC','CCD','CCS', 'ED','ES','CDD','CDS','CED','CES'].includes(item.last_carriera_sportiva.ruolo_ideale[0].sigla));
		},
		attaccanti: function() {
			return this.players.filter(item => item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length > 0 && ['TQC', 'TQD', 'TQS','AD','AS','AC','PC','SP'].includes(item.last_carriera_sportiva.ruolo_ideale[0].sigla));
		},
		senza_ruolo: function() {
			return this.players.filter(item => !item.last_carriera_sportiva || (item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length == 0));
		},
	},
	created: function(){
		this.setPlayersScoutingGlossary()
	},
}

</script>
<template>
    <b-card no-body class="h-100" :border-variant="persona.is_master ? 'success' : ''">

        <b-row class="small">
            <slot name="selected" />
            <b-col class="text-center" align-self="center">
                <b-img :src="persona.avatar_url" :height="130"/>
                <div>Persona ID: {{ persona.id }}</div>
                <div>{{ persona.cognome }} {{ persona.nome }}</div>
                <div>{{ persona.nascita_data |  dateFormat }}</div>
                <div v-if="persona.nascita_paese">{{ persona.nascita_paese.paese }}</div>
            </b-col>
            <b-col v-if="persona.info_extra">
                <div style="background-color: #e9ecef;">INFO EXTRA</div>
                <div v-for="item,key in persona.info_extra">
                    <span>{{ key }}:</span> <span v-if="key == 'created_at' || key == 'updated_at'">{{ item | dateTimeFormat }}</span><span v-else>{{ item }}</span>
                </div>
            </b-col>
            <b-col v-if="persona.info_fisica">
                <div style="background-color: #e9ecef;">INFO FISICA</div>
                <div v-for="item,key in persona.info_fisica">
                    {{ key }}: <span v-if="key == 'created_at' || key == 'updated_at'">{{ item | dateTimeFormat }}</span><span v-else>{{ item }}</span>
                </div>
            </b-col>
            <b-col v-if="persona.info_economica">
                <div style="background-color: #e9ecef;">INFO ECONOMICA</div>
                <div v-for="item,key in persona.info_economica">
                    {{ key }}: <span v-if="key == 'created_at' || key == 'updated_at'">{{ item | dateTimeFormat }}</span><span v-else>{{ item }}</span>
                </div>
            </b-col>
            <b-col v-if="persona.giocatore">
                <div style="background-color: #e9ecef;">GIOCATORE</div>
                <div v-for="item,key in persona.giocatore">
                    {{ key }}: <span v-if="key == 'created_at' || key == 'updated_at'">{{ item | dateTimeFormat }}</span><span v-else>{{ item }}</span>
                </div>
            </b-col>
            <b-col v-if="persona.osservato">
                <div style="background-color: #e9ecef;">OSSERVATO</div>
                <div v-for="item,key in persona.osservato">
                    {{ key }}: <span v-if="key == 'created_at' || key == 'updated_at'">{{ item | dateTimeFormat }}</span><span v-else>{{ item }}</span>
                </div>
            </b-col>
            <b-col v-if="persona.contratto">
                <div style="background-color: #e9ecef;">CONTRATTO</div>
                <div v-for="item,key in persona.contratto">
                    {{ key }}: <span v-if="key == 'created_at' || key == 'updated_at'">{{ item | dateTimeFormat }}</span><span v-else>{{ item }}</span>
                </div>
            </b-col>
            <b-col v-if="persona.opta && persona.opta.length > 0">
                <div style="background-color: #e9ecef;">OPTA</div>
                <div v-for="item,key in persona.opta[0]">
                    {{ key }}: <span v-if="key == 'created_at' || key == 'updated_at'">{{ item | dateTimeFormat }}</span><span v-else>{{ item }}</span>
                </div>
            </b-col>
        </b-row>

        <entita-api-manager :entityId="persona.id" entity="Persona" class="small"/>
        
        <b-table-simple small v-if="persona.utente">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="8">UTENTE ID: {{ persona.utente.id }} - USERNAME: {{ persona.utente.username }}</b-th>
                </b-tr>
                <b-tr class="text-center" v-if="persona.utente.utenti_gruppi.length > 0">
                    <b-th class="small" colspan="4">Gruppo</b-th>
                    <b-th class="small" colspan="4">Categoria</b-th>
                </b-tr>
                <b-tr class="text-center" v-for="utenteGruppo in persona.utente.utenti_gruppi">
                    <b-td class="small" colspan="4">{{ utenteGruppo.gruppo.nome }}</b-td>
                    <b-td class="small" colspan="4">{{ utenteGruppo.categoria ? utenteGruppo.categoria.name : '' }}</b-td>
                </b-tr>
            </b-thead>
        </b-table-simple>

        <b-table-simple small v-if="persona.arbitro">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="8">ARBITRO: {{ persona.arbitro.categoria }} - {{ persona.arbitro.sezione }}</b-th>
                </b-tr>
            </b-thead>
        </b-table-simple>

        <b-table-simple small v-if="persona.relazioni && persona.relazioni.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="5">RELAZIONI ({{ persona.relazioni.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Persona ID</b-th>
                    <b-th class="small">Cognome</b-th>
                    <b-th class="small">Nome</b-th>
                    <b-th class="small">Tipo</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="relazione in persona.relazioni" class="text-center">
                    <b-td class="small">{{ relazione.id }}</b-td>
                    <b-td class="small">{{ relazione.relazionante.id }}</b-td>
                    <b-td class="small">{{ relazione.relazionante.cognome }}</b-td>
                    <b-td class="small">{{ relazione.relazionante.nome }}</b-td>
                    <b-td class="small">{{ relazione.tipo.nome }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.relazionanti && persona.relazionanti.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="3">RELAZIONANTI ({{ persona.relazionanti.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">E'</b-th>
                    <b-th class="small">di</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="relazionante in persona.relazionanti" class="text-center">
                    <b-td class="small">{{ relazionante.id }}</b-td>
                    <b-td class="small">{{ relazionante.tipo.nome }}</b-td>
                    <b-td class="small">{{ relazionante.relazionato.cognome }} {{ relazionante.relazionato.nome }} (Persona ID: {{ relazionante.relazionato.id }})</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.indirizzi && persona.indirizzi.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="9">INDIRIZZI ({{ persona.indirizzi.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Tipo</b-th>
                    <b-th class="small">Indirizzo</b-th>
                    <b-th class="small">CAP</b-th>
                    <b-th class="small">Città</b-th>
                    <b-th class="small">Provincia</b-th>
                    <b-th class="small">Paese</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="indirizzo in persona.indirizzi" class="text-center">
                    <b-td class="small">{{ indirizzo.indirizzo.id }}</b-td>
                    <b-td class="small">{{ indirizzo.indirizzo.tipo }}</b-td>
                    <b-td class="small">{{ indirizzo.indirizzo.indirizzo }}</b-td>
                    <b-td class="small">{{ indirizzo.indirizzo.cap }}</b-td>
                    <b-td class="small">{{ indirizzo.indirizzo.citta }}</b-td>
                    <b-td class="small">{{ indirizzo.indirizzo.provincia }}</b-td>
                    <b-td class="small">{{ indirizzo.indirizzo.paese ?  indirizzo.indirizzo.paese.paese : '' }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.contatti && persona.contatti.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="9">CONTATTI ({{ persona.contatti.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Tipo</b-th>
                    <b-th class="small">Contatto</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="contatto in persona.contatti" class="text-center">
                    <b-td class="small">{{ contatto.contatto.id }}</b-td>
                    <b-td class="small">{{ contatto.contatto.tipo }}</b-td>
                    <b-td class="small">{{ contatto.contatto.valore }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.documenti && persona.documenti.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="9">DOCUMENTI ({{ persona.documenti.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Tipo</b-th>
                    <b-th class="small">Numero</b-th>
                    <b-th class="small">Emissione</b-th>
                    <b-th class="small">Scadenza</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="documento in persona.documenti" class="text-center">
                    <b-td class="small">{{ documento.documento.id }}</b-td>
                    <b-td class="small">{{ documento.documento.tipo.nome }}</b-td>
                    <b-td class="small">{{ documento.documento.numero }}</b-td>
                    <b-td class="small">{{ documento.documento.emissione | dateFormat }}</b-td>
                    <b-td class="small">{{ documento.documento.scadenza | dateFormat }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.carriere_sportive && persona.carriere_sportive.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="10">CARRIERE SPORTIVE ({{ persona.carriere_sportive.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Inizio</b-th>
                    <b-th class="small">Fine</b-th>
                    <b-th class="small">Tipologia</b-th>
                    <b-th class="small">Maglia</b-th>
                    <b-th class="small">Ruolo</b-th>
                    <b-th class="small">Ruoli</b-th>
                    <b-th class="small">Squadra</b-th>
                    <b-th class="small">Stagione</b-th>
                    <b-th class="small"></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="carriera in getSortCarriera(persona.carriere_sportive)" class="text-center">
                    <b-td class="small">{{ carriera.id }}</b-td>
                    <b-td class="small">{{ carriera.inizio | dateFormat }}</b-td>
                    <b-td class="small">{{ carriera.fine | dateFormat }}</b-td>
                    <b-td class="small">{{ carriera.tipologia }}</b-td>
                    <b-td class="small">{{ carriera.maglia }}</b-td>
                    <b-td class="small">{{ carriera.persona_ruolo_glo ? carriera.persona_ruolo_glo.nome : 'N/D'}}</b-td>
                    <b-td class="small">
                        <div v-for="ruolo in carriera.ruoli">
                            {{ ruolo.sigla }}
                        </div>
                    </b-td>
                    <b-td class="small"><a href="javascript:;" @click="goToSquadra(carriera)">{{ carriera.squadra ? carriera.squadra.officialName + ' (ID: ' + carriera.squadra.id + ')' : ''}} </a></b-td>
                    <b-td class="small">{{ carriera.stagione ? carriera.stagione.stagione : 'N/D'}}</b-td>
                    <b-th class="small"><b-button size="xs" @click="deleteCarriera(carriera.id)"><i class="fa fa-trash" aria-hidden="true"></i></b-button></b-th>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.injuries && persona.injuries.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="9">INJURIES ({{ persona.injuries.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Date of Injury</b-th>
                    <b-th class="small">Date of return</b-th>
                    <b-th class="small">Second Injury</b-th>
                    <b-th class="small">Type of Injury</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="injury in persona.injuries" class="text-center">
                    <b-td class="small">{{ injury.id }}</b-td>
                    <b-td class="small">{{ injury.data | dateFormat }}</b-td>
                    <b-td class="small">{{ injury.return | dateFormat }}</b-td>
                    <b-td class="small">{{ injury.reinjury ? reinjury_label[injury.reinjury] : 'No' }}</b-td>
                    <b-td class="small">{{ injury.pathology ? injury.pathology.name : 'N/D'}}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.illnesses && persona.illnesses.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="9">ILLNESSES ({{ persona.illnesses.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Date of Injury</b-th>
                    <b-th class="small">Date of Illness</b-th>
                    <b-th class="small">Second Illness</b-th>
                    <b-th class="small">Type of Illness</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="illness in persona.illnesses" class="text-center">
                    <b-td class="small">{{ illness.id }}</b-td>
                    <b-td class="small">{{ illness.data | dateFormat }}</b-td>
                    <b-td class="small">{{ illness.return | dateFormat }}</b-td>
                    <b-td class="small">{{ illness.reillness ? illness_label[illness.reillness] : 'No' }}</b-td>
                    <b-td class="small">
                        <div v-for="item in illness.illness_types" v-if="item.id != 7"> {{item.name}} </div>
                        <div v-for="item in illness.illness_other_types"> {{item.name}} </div>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <!--b-table-simple small v-if="persona.providers && persona.providers.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="3">PROVIDERS ({{ persona.providers.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Fornitore</b-th>
                    <b-th class="small">Chiave</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="provider in persona.providers" class="text-center">
                    <b-td class="small">{{ provider.id }}</b-td>
                    <b-td class="small">{{ provider.fornitore }}</b-td>
                    <b-td class="small">{{ provider.chiave }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple-->

        <b-table-simple small v-if="persona.videotags && persona.videotags.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="8">VIDEOTAGS: {{ persona.videotags.length }}</b-th>
                </b-tr>
            </b-thead>
        </b-table-simple>

        <b-table-simple small v-if="persona.liste && persona.liste.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="3">LISTE ({{ persona.liste.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Nome</b-th>
                    <b-th class="small">Utente</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="lista in persona.liste" class="text-center">
                    <b-td class="small">{{ lista.id }}</b-td>
                    <b-td class="small">{{ lista.nome }}</b-td>
                    <b-td class="small">{{ lista.utente.nomeCompleto }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.liste_confronto && persona.liste_confronto.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="3">LISTE CONFRONTO ({{ persona.liste_confronto.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Nome</b-th>
                    <b-th class="small">Utente</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="lista in persona.liste_confronto" class="text-center">
                    <b-td class="small">{{ lista.id }}</b-td>
                    <b-td class="small">{{ lista.nome }}</b-td>
                    <b-td class="small">{{ lista.utente.nomeCompleto }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.valutazioni_rel && persona.valutazioni_rel.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="5">VALUTAZIONI SCOUT ({{ persona.valutazioni_rel.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">Rel ID</b-th>
                    <b-th class="small">Val ID</b-th>
                    <b-th class="small">Data</b-th>
                    <b-th class="small">Valutazione Tipo ID</b-th>
                    <b-th class="small">Utente</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="val_rel in persona.valutazioni_rel" class="text-center">
                    <b-td class="small">{{ val_rel.id }}</b-td>
                    <b-td class="small">{{ val_rel.valutazione.id }}</b-td>
                    <b-td class="small">{{ val_rel.valutazione.created_at | dateFormat }}</b-td>
                    <b-td class="small">{{ val_rel.valutazione.valutazione_glo_id }}</b-td>
                    <b-td class="small">{{ val_rel.valutazione.persona ? val_rel.valutazione.persona.nomeCompleto : 'N/D'}}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.valutazioni && persona.valutazioni.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="4">VALUTAZIONI SCOUTING ({{ persona.valutazioni.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Data</b-th>
                    <b-th class="small">Settore</b-th>
                    <b-th class="small">Utente</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="valutazione in persona.valutazioni" class="text-center">
                    <b-td class="small">{{ valutazione.id }}</b-td>
                    <b-td class="small">{{ valutazione.created_at | dateFormat }}</b-td>
                    <b-td class="small">{{ getScout(valutazione.academy) }}</b-td>
                    <b-td class="small">{{ valutazione.utente.nomeCompleto }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.partite_report_giocatori && persona.partite_report_giocatori.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="8">REPORTS PARTITE: {{ persona.partite_report_giocatori.length }}</b-th>
                </b-tr>
            </b-thead>
        </b-table-simple>

        <b-table-simple small v-if="persona.partite && persona.partite.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="7">PARTITE FOOTBALL ({{ persona.partite.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Partita</b-th>
                    <b-th class="small">Data</b-th>
                    <b-th class="small">Categoria</b-th>
                    <b-th class="small">Stagione</b-th>
                    <b-th class="small">Created_at</b-th>
                    <b-th class="small">Updated_at</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="partita in getSortPartite(persona.partite)" class="text-center">
                    <b-td class="small"><a href="javascript:;"  @click="goToPartita(partita)">{{ partita.id }}</a></b-td>
                    <b-td class="small">{{ partita.home ? partita.home.officialName : 'N/D'}} - {{ partita.away ? partita.away.officialName : 'N/D'}}</b-td>
                    <b-td class="small">{{ partita.data | dateTimeFormat }}</b-td>
                    <b-td class="small">{{ partita.categoria ? partita.categoria.name : 'N/D'}}</b-td>
                    <b-td class="small">{{ partita.stagione ? partita.stagione.stagione : 'N/D' }}</b-td>
                    <b-td class="small">{{ partita.created_at | dateTimeFormat }}</b-td>
                    <b-td class="small">{{ partita.updated_at | dateTimeFormat }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        
        <b-table-simple small v-if="persona.partita_eventi && persona.partita_eventi.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="6">EVENTI PARTITE FOOTBALL ({{  persona.partita_eventi.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Partita ID</b-th>
                    <b-th class="small">Minuto</b-th>
                    <b-th class="small">Tipo</b-th>
                    <b-th class="small">Created_at</b-th>
                    <b-th class="small">Updated_at</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="evento in persona.partita_eventi" class="text-center">
                    <b-td class="small">{{ evento.id }}</b-td>
                    <b-td class="small">{{ evento.partita_id }}</b-td>
                    <b-td class="small">{{ evento.minuto }}</b-td>
                    <b-td class="small">{{ evento.tipo.nome }}</b-td>
                    <b-td class="small">{{ evento.created_at | dateTimeFormat }}</b-td>
                    <b-td class="small">{{ evento.updated_at | dateTimeFormat }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.rosa_valutazioni && persona.rosa_valutazioni.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="4">VALUTAZIONI ROSA ({{ persona.rosa_valutazioni.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Dal</b-th>
                    <b-th class="small">Al</b-th>
                    <b-th class="small">Utente</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="valutazione in persona.rosa_valutazioni" class="text-center">
                    <b-td class="small">{{ valutazione.id }}</b-td>
                    <b-td class="small">{{ valutazione.dal | dateFormat }}</b-td>
                    <b-td class="small">{{ valutazione.al | dateFormat  }}</b-td>
                    <b-td class="small">{{ valutazione.utente ? valutazione.utente.nomeCompleto : 'N/D'}}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.prestiti_partite && persona.prestiti_partite.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="5">PARTITE PRESTITI ({{ persona.prestiti_partite.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Categoria</b-th>
                    <b-th class="small">Home</b-th>
                    <b-th class="small">Away</b-th>
                    <b-th class="small">Data</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="partita in persona.prestiti_partite" class="text-center">
                    <b-td class="small">{{ partita.id }}</b-td>
                    <b-td class="small">{{ partita.categoria }}</b-td>
                    <b-td class="small">{{ partita.home }}</b-td>
                    <b-td class="small">{{ partita.away }}</b-td>
                    <b-td class="small">{{ partita.data | dateTimeFormat }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.logs && persona.logs.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="4">LOGS App\Models\Persona ({{ persona.logs.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Utente</b-th>
                    <b-th class="small">Descrizione</b-th>
                    <b-th class="small">Data</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="log in persona.logs" class="text-center">
                    <b-td class="small">{{ log.id }}</b-td>
                    <b-td class="small">{{ log.causer ? log.causer.nomeCompleto : 'N/D' }}</b-td>
                    <b-td class="small">{{ log.description }}</b-td>
                    <b-td class="small">{{ log.created_at | dateTimeFormat }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple small v-if="persona.logs_giocatore && persona.logs_giocatore.length > 0">
            <b-thead head-variant="light">
                <b-tr class="text-center">
                    <b-th class="small" colspan="4">LOGS App\Models\Giocatori ({{ persona.logs_giocatore.length }})</b-th>
                </b-tr>
                <b-tr class="text-center">
                    <b-th class="small">ID</b-th>
                    <b-th class="small">Utente</b-th>
                    <b-th class="small">Descrizione</b-th>
                    <b-th class="small">Data</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="log in persona.logs_giocatore" class="text-center">
                    <b-td class="small">{{ log.id }}</b-td>
                    <b-td class="small">{{ log.causer ? log.causer.nomeCompleto : 'N/D'}}</b-td>
                    <b-td class="small">{{ log.description }}</b-td>
                    <b-td class="small">{{ log.created_at | dateTimeFormat }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

    </b-card>
</template>

<script>

import moment from 'moment';
import * as types from '@/store/types';
export default {
    data: function() {
        return {
            reinjury_label: [ 'No', 'Reinjury', 'Subsequent new injury', 'Subsequent local injury', 'Exacerbation'],    
            illness_label: [ 'No', 'Reillness', 'Subsequent new illness', 'Subsequent local illness', 'Exacerbation'],  
        }
    },
    props: ['persona'],

    components: {
        EntitaApiManager: require('@/components/layout/entita_api/manager.vue').default,
    },

    computed: {
    },

    methods: {
        getScout(academy) {
            if(academy == 0) {
                return 'Archivio';
            } else if(academy == 1) {
                return 'World';
            } else if(academy == 2) {
                return 'Usa';
            } else if(academy == 3) {
                return 'Scouting';
            } else if(academy == 4) {
                return 'Youth';
            } else if(academy == 5) {
                return 'Young';
            }
        },

        getSortCarriera(carriera) {
            return _.orderBy(carriera, ['stagione.stagione'], ['desc']);
        },

        getSortPartite(partite) {
            return _.orderBy(partite, ['data'], ['desc']);
        },

        goToPartita(partita) {
            var team = null;
            if(partita.home && partita.home.societa.length > 0) {
                team = partita.home;
            } else if(partita.away && partita.away.societa.length > 0) {
                team = partita.away;
            }
            if(team) {
                this.$store.commit('changeRosaTeamId', team.id);
                this.$store.commit('changeCategoriaId', team.id_categoria);
                this.$store.commit('changeSeasonId', partita.stagione_id);
                let routeData = this.$router.resolve({ path: "/football/partita/" + partita.id  });
                window.open(routeData.href, '_blank');
            }
        },
        goToSquadra(carriera) {
            if(carriera.squadra) {
                this.$store.commit('changeSeasonId', carriera.stagione_id);
                if(carriera.squadra.societa.length > 0) {
                    this.$store.commit('changeRosaTeamId', carriera.squadra_id);
                    this.$store.commit('changeCategoriaId', carriera.squadra.id_categoria);
                    let routeData = this.$router.resolve({ path: "/football/rosa" });
                    window.open(routeData.href, '_blank');
                } else {
                    let routeData = this.$router.resolve({ path: "/football/opponents/" + carriera.squadra_id + "/" + carriera.squadra.id_categoria});
                    window.open(routeData.href, '_blank');
                }
            }
        },
        deleteCarriera(id) {
            if(confirm("Confermi di voler eliminare la carriera?")) {
                this.$http.get(this.$store.state.apiEndPoint +'/persona/delete/carriera/sportiva/' + id).then(function(res) {
                    this.$store.commit(types.ALERTS_PUSH, { status: 1, msg: res.data.message });
                }).catch(function (error) {
                    this.$store.commit(types.ALERTS_PUSH, { status: 0, msg: error.data.error });
                });
            }
        },
    },

    filters: {
        dateFormat: function (date) {
            if(date) {
                return moment(date).format('DD/MM/YYYY');
            } 
            return 'DD/MM/YYYY';
        },

        dateTimeFormat: function (date) {
            return moment(date).format('DD/MM/YYYY HH:mm');
        }
    }
}
</script>

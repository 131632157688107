<template>
	<section class="scout-dashboard">
		<headapp></headapp>
		
        <menusub></menusub>

		<div style="position: fixed; bottom: 0; left: 0; width: 100%; z-index: 9999">
			<b-button size="lg" variant="primary" style="position: absolute; bottom: 30px; left: 20px;border-radius:100%" v-b-toggle.sidebar-1><i :class="'fa fa-' + (shown ? 'compress' : 'expand')" aria-hidden="true"></i></b-button>
		</div>

		<b-sidebar id="sidebar-1" title="Widgets" shadow v-model="shown">
			<div class="px-3 py-2">
				<b-form-group label="Aggiungi i widgets che vuoi visualizzare sulla dashboard:">
					<b-row v-for="widget in dashboardWidgets" v-if="auth.isUserEnable(widget.permesso)">
						<b-col cols="9"> {{ widget.titolo }} </b-col>
						<b-col>
							<b-button variant="success" size="xs" @click.stop="addWidget(widget.link)"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
							<b-button variant="primary" size="xs" @click.stop="goTo(widget.link)"><i class="fa fa-eye" aria-hidden="true"></i></b-button>
						</b-col>
					</b-row>
				</b-form-group>
				<b-form-group label="Azioni disponibili:" class="mt-3">
                    <b-button block class="m-0 mb-1" size="sm" variant="success" @click="saveReportsWidgets">Salva dashboard</b-button>
                    <b-button block class="m-0 mb-1" size="sm" @click="resetReportsWidgets">Torna alla dashboard di default</b-button>
                    <b-button block class="m-0" size="sm" variant="danger" @click="removeAllReportsWidgets">Rimuovi tutti i widgets dalla dashboard</b-button>
				</b-form-group>
			</div>
		</b-sidebar>
		
        <div class="container-fluid m-y-1">
			<b-card no-body class="border-0" style="height: 90vh; overflow-y:scroll;">
				<smart-widget-grid :layout="widgets">
					<smart-widget v-for="widget in widgets" :slot="widget.i" :title="widget.titolo" v-if="auth.isUserEnable(widget.permesso)" fullscreen @before-fullscreen="widget.fullscreen = !widget.fullscreen">                        
                        <template slot="toolbar">
                            <div style="margin: 0 12px;">
                                <!-- <b-button variant="primary" size="sm" @click="goTo(widget.link)"><i class="fa fa-eye" aria-hidden="true"></i></b-button> -->
                                <b-button variant="success" size="sm" @click="showConfigWidget(widget.i)"><i class="fa fa-pencil" aria-hidden="true"></i></b-button>
                                <b-button size="sm" @click="removeWidget(widget.i)"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
                            </div>
                        </template>
                        <template v-slot="{contentH}">
                            <div class="content-widget">
                                <div v-if="widget.component" :is="widget.component" :contentHeight="contentH" :isWidget="widget.i" :isFullscreen="widget.fullscreen" :config="widget.config" :refresh="widget.refresh" @updateConfigWidget="updateConfigWidget" :configWidget="widget.configWidget" @updateTitleWidget="updateTitleWidget">
                                </div>
                                <router-link :to="{ name: widget.link }" v-else>
                                    <div class="p-1">
                                        <img :alt="widget.titolo" :src="widget.img" style="height: 100%; width: 100%; object-fit: contain">
                                    </div>
                                </router-link>
                            </div>
                        </template>
					</smart-widget>
				</smart-widget-grid>
			</b-card>

			<b-modal ref="config_widget" title="Configura Widget" @ok="saveReportsWidgets">
				<div v-if="widget_selected">
					<b-form-group label="Titolo">
						<b-form-input type="text" v-model="widget_selected.titolo"/>
					</b-form-group>
					<b-form-group label="Aggiornamento dati (minuti)">
						<b-form-input type="number" v-model="widget_selected.refresh"/>
					</b-form-group>
					<div class="color-rosso">Saranno inoltre salvati sul widget gli attuali filtri/parametri impostati e visualizzati in modalità fullscreen.</div>
    			</div>
  			</b-modal>
		</div>

		<footerapp></footerapp>
	</section>
</template>
<script>

import pageBase from '@/mixins/pageBase.js';
import scoutDashboardMixin from '@/mixins/scoutDashboardMixin.js';
var Auth = require('@/auth.js');

export default {
	mixins: [ pageBase, scoutDashboardMixin ],
	data: function() {
		return {
			dashboardWidgets: [ 
				{
                    titolo: 'Last Matches', 
                    permesso: 'scout', 
                    link: 'scout_dashboard_last_matches', 
                    component: 'LastMatches'
                },
				{
                    titolo: 'Next Matches', 
                    permesso: 'scout', 
                    link: 'scout_dashboard_next_matches', 
                    component: 'NextMatches'
                },
				{
                    titolo: 'XPhy Rank', 
                    permesso: 'scout', 
                    link: 'scout_dashboard_xphy_rank', 
                    component: 'XPhyRank',
                    config: {
                        defaultRole: 'central_defender'
                    }
                },
				{
                    titolo: 'XTech Rank', 
                    permesso: 'scout', 
                    link: 'scout_dashboard_xtech_rank', 
                    component: 'XTechRank',
                    config: {
                        defaultRole: 'central_defender'
                    }
                }
			],
			shown: false,
			widgets: [],
			show_import: false,
			team_to_import: null,
			widget_selected: null,
		}
	},
	components: {
		'LastMatches': require('@/components/layout/widgets/scout/LastMatches.vue').default,
		'NextMatches': require('@/components/layout/widgets/scout/NextMatches.vue').default,
		'XPhyRank': require('@/components/layout/widgets/scout/XPhyRank.vue').default,
		'XTechRank': require('@/components/layout/widgets/scout/XTechRank.vue').default,
	},
	computed: {
		auth: function() {
			return Auth;
		}		
	},
	watch: {},
	methods: {
		goTo: function(where) {
			this.$router.push({name: where});	
		},
		saveReportsWidgets: function() {
			if(confirm("Confermi di voler salvare la configurazione attuale della dashboard ?")) {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'scoutDashboardWidgets', persone: this.widgets }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.alertPush("Dashboard salvata con successo!", 1);
				});
			}
		},
		resetReportsWidgets: function() {
			if(confirm("Confermi di voler resettare la dashboard alla configurazione iniziale di default ? L'azione è comunque reversibile (basta aggiornare la pagina) fino a che non viene salvata la nuova configurazione della dashboard.")) {
				this.widgets = [];
				var x = 0;
				var y = 0;
				for(var i in this.dashboardWidgets) {
					var widget = this.dashboardWidgets[i];
					if(this.auth.isUserEnable(widget.permesso)) {
						this.widgets.push({ x: x, y: y, w: 4, h: 8, i: widget.permesso + '_' + Math.random(), titolo: widget.titolo, permesso: widget.permesso, link: widget.link, img: widget.img, component: widget.component ? widget.component : null, fullscreen: false, config: widget.config ? widget.config : null, refresh: 0, configWidget: {} });
					}
					if(x == 8) {
						x = 0;
						y = y + 8;
					} else {
						x = x + 4;
					}
				}
			}
		},
		getReportsWidgets: function() {
			if(this.auth.presets()['scoutDashboardWidgets'] != undefined) {
				this.widgets = _.cloneDeep(this.auth.presets()['scoutDashboardWidgets']);
				for(var i in this.widgets) {
					this.widgets[i].fullscreen = false;
				}
			} else {
				this.widgets = [];
				var x = 0;
				var y = 0;
				for(var i in this.dashboardWidgets) {
					var widget = this.dashboardWidgets[i];
					if(this.auth.isUserEnable(widget.permesso)) {
						this.widgets.push({ x: x, y: y, w: 4, h: 8, i: widget.permesso + '_' + Math.random(), titolo: widget.titolo, permesso: widget.permesso, link: widget.link, img: widget.img, component: widget.component ? widget.component : null, fullscreen: false, config: widget.config ? widget.config : null, refresh: 0, configWidget: {} });
					}
					if(x == 8) {
						x = 0;
						y = y + 8;
					} else {
						x = x + 4;
					}
				}
			}
		},
		addWidget: function(link) {
			var countWidgets = this.widgets.length;
			var x = 0;
			var y = 0;
			for(var i = 0; i < countWidgets; i++) {
				if(x == 8) {
					x = 0;
					y = y + 8;
				} else {
					x = x + 4;
				}
			}
			var widget = this.dashboardWidgets.find(item => item.link == link);
			if(widget && this.auth.isUserEnable(widget.permesso)) {
				this.widgets.push({ x: x, y: y, w: 4, h: 8, i: widget.permesso + '_' + Math.random(), titolo: widget.titolo, permesso: widget.permesso, link: widget.link, img: widget.img, component: widget.component ? widget.component : null, fullscreen: false, config: widget.config ? widget.config : {}, refresh: 0, configWidget: {} });
			}
		},
		removeWidget: function(key) {
			if(confirm("Confermi di voler eliminare il widget ?")) {
				var findIndex = this.widgets.findIndex(item => item.i == key);
				if(findIndex > -1) {
					this.widgets.splice(findIndex, 1);
				}
			}	
		},
		removeAllReportsWidgets: function() {
			if(confirm("Confermi di voler eliminare tutti i widgets dalla dashboard ? L'azione è comunque reversibile (basta aggiornare la pagina) fino a che non viene salvata la nuova configurazione della dashboard.")) {
				this.widgets = [];		
			}	
		},
		showConfigWidget: function(key) {
			var findIndex = this.widgets.findIndex(item => item.i == key);
			if(findIndex > -1) {
				this.widget_selected = this.widgets[findIndex];
				this.$refs.config_widget.show();
			}	
		},
		updateConfigWidget: function(id, key, value) {
			var findIndex = this.widgets.findIndex(item => item.i == id);
			if(findIndex > -1) {
				if(!this.widgets[findIndex].configWidget || (this.widgets[findIndex].configWidget && Array.isArray(this.widgets[findIndex].configWidget) && this.widgets[findIndex].configWidget.length == 0)) {
					this.widgets[findIndex].configWidget = {};
				}
				this.widgets[findIndex].configWidget[key] = value;
			}
		},
		updateTitleWidget: function(id, value) {
			var findIndex = this.widgets.findIndex(item => item.i == id);
			if(findIndex > -1) {
				this.widgets[findIndex].titolo = value;
			}
		},
	},
	created: function() {
		this.getReportsWidgets();
	}
};

</script>
<style>
.content-widget {
	font-size: 0.8rem;
}
.smartwidget.smartwidget-fullscreen {
	z-index: 1000 !important;
}
</style>
<template>
	<div v-if="!sessione || isAlreadyImported">
		<b-overlay :show="ux.waiting">
			<b-card v-if="!ux.collapsed" :no-body="isWidget ? true : false" :style="(isWidget ? 'border:none;' : '') + (contentHeight && isFullscreen ? 'max-height:' + contentHeight + 'px;overflow-y:auto;overflow-x:hidden;' : '')">
				<template #header v-if="!isWidget">
					<b-row>
						<b-col>
							Tools Analysis
						</b-col>
						<b-col v-if="collapsable" class="text-right">
							<a href="javascript:;" @click.prevent="ux.collapsed = true">Chiudi</a>
						</b-col>
					</b-row>
				</template>

				<div v-if="results && (isFullscreen || !isWidget)">
					<ToolsTimeline @selectfase="filteringFase" :timeline="results.timeline" :filters="filters"></ToolsTimeline>
				</div>

				<b-row class="mb-2" v-if="!isWidget || isFullscreen">
					<b-col v-if="!sessione">
						
						<b-form-group label="Data Inizio">
							<b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="dateFrom"></b-form-datepicker>
						</b-form-group>
						<b-form-group label="Data Fine">
							<b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="dateTo"></b-form-datepicker>
						</b-form-group>

						<b-button @click.prevent="fetchResults">Cerca</b-button>

					</b-col>
					<b-col v-if="daysDataset && daysDataset.length > 1">
						<b-row>
							<b-col>
								Giorni
							</b-col>
							<b-col class="text-right">
								<a href="javascript:;" class="small text-muted" v-if="filters.days.length" @click.prevent="filters.days = []">Svuota</a>
							</b-col>
						</b-row>
						<b-form-select v-model="filters.days" multiple :select-size="7">
							<b-form-select-option v-for="day in filteredDaysDataset" :value="day.split(' ')[0]">{{ day.split(' ')[0] | date }} {{ day.split(' ')[1] }}</b-form-select-option>
						</b-form-select>
					</b-col>
					<b-col v-if="results && !sessione">
						<b-row>
							<b-col cols="10">
								<b-checkbox switch v-model="ux.otherCategory" :value="true"> <strong>Includi sessioni di altre categorie</strong></b-checkbox>
							</b-col>
							<b-col class="text-right">
								<a href="javascript:;" class="small text-muted" v-if="filters.sessioni.length" @click.prevent="filters.sessioni = []">Svuota</a>
							</b-col>
						</b-row>
						
						<b-form-select multiple :select-size="7" v-model="filters.sessioni">
							<b-form-select-option v-for="sess in sessioni_catapult" :value="sess.id">{{ ux.otherCategory && sess.categoria_id != categoriaId ? sess.categoria.name + ' - ' : ''}} {{ sess.nome }} {{ sess.inizio | date('DD/MM/YYYY HH:mm') }}</b-form-select-option>
						</b-form-select>

						<b-row align-v="center" class="mt-2">
							<b-col cols="9">
								<b-button-group>
									<b-button variant="outline-secondary" size="sm" @click.prevent="selectSessions(15)">Match</b-button>
									<b-button variant="outline-secondary" size="sm" @click.prevent="selectSessions(9)">Std</b-button>
									<b-button variant="outline-secondary" size="sm" @click.prevent="selectSessions(10)">RTP</b-button>
									<b-button variant="outline-secondary" size="sm" @click.prevent="selectSessions(13)">Diff</b-button>
									<b-button variant="outline-secondary" size="sm" @click.prevent="selectSessions(14)">Rec</b-button>
									<b-button variant="outline-secondary" size="sm" @click.prevent="selectSessions(8)">Gym</b-button>
									<b-button variant="outline-secondary" size="sm" @click.prevent="selectSessions(11)">Perf</b-button>
								</b-button-group>
							</b-col>
							<b-col cols="3" class="text-right">
								<b-form-checkbox switch size="sm" v-model="ux.daysOffShow">Days Off</b-form-checkbox>
							</b-col>
						</b-row>

					</b-col>
					<b-col v-if="results">
						<b-row>
							<b-col cols="9">
								<b-checkbox switch v-model="ux.allDataset" :value="true"> <strong>Allenamento Completo</strong></b-checkbox>
							</b-col>
							<b-col class="text-right">
								<a href="javascript:;" class="small text-muted" v-if="ux.allDataset == false && filters.fasi.length" @click.prevent="filters.fasi = []">Svuota</a>
							</b-col>
						</b-row>


						<b-form-select :disabled="ux.allDataset == true" multiple :select-size="9" v-model="filters.fasi">
							<b-form-select-option v-for="fase in fasi_catapult" :value="fase.id">{{ fase.nome }}</b-form-select-option>
						</b-form-select>
					</b-col>
					<b-col v-if="results">
						<b-row>
							<b-col>
								Giocatori
							</b-col>
							<b-col class="text-right">
								<a href="javascript:;" class="small text-muted" v-if="filters.players.length" @click.prevent="filters.players = []">Svuota</a>
							</b-col>
						</b-row>
						<b-form-select multiple :select-size="9" v-model="filters.players">
							<b-form-select-option v-for="player in players_catapult" :value="player.id">{{ player.cognome }}</b-form-select-option>
						</b-form-select>
					</b-col>
				</b-row>

				<div v-if="results">

					<!-- <b-button @click.prevent="fetchResults">!</b-button> -->
					<b-nav tabs class="mb-2 d-flex align-items-center">
						<b-nav-text :class="ux.table == 'drill' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="drill" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'drill'">General Analysis</span></b-nav-text>
						<b-nav-text v-if="!ux.showVelocity2Tab" :class="ux.table == 'velocity' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="velocity" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'velocity'">Velocity Analysis</span></b-nav-text>
						<b-nav-text v-if="ux.showVelocity2Tab" :class="ux.table == 'velocity2' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="velocity2" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'velocity2'">Velocity Analysis</span></b-nav-text>
						<b-nav-text :class="ux.table == 'neurom' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="neurom" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'neurom'">Neurom</span></b-nav-text>
						<b-nav-text :class="ux.table == 'hr' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="hr" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'hr'">Heart Rate</span></b-nav-text>
						<b-nav-text v-if="results && results.fasi.length > 0" :class="ux.table == 'drill_by_fase' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="drill_by_fase" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'drill_by_fase'">General Analysis By Exercise</span></b-nav-text>
						<b-nav-text v-if="results && results.fasi.length > 0 && !ux.showVelocity2ByExeTab" :class="ux.table == 'velocity_by_fase' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="velocity_by_fase" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'velocity_by_fase'">Velocity Analysis By Excercise</span></b-nav-text>
						<b-nav-text v-if="results && results.fasi.length > 0 && ux.showVelocity2ByExeTab" :class="ux.table == 'velocity2_by_fase' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="velocity2_by_fase" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'velocity2_by_fase'">Velocity Analysis By Excercise</span></b-nav-text>
						<b-nav-text v-if="results && results.fasi.length > 0" :class="ux.table == 'neurom_by_fase' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="neurom_by_fase" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'neurom_by_fase'">Neurom By Excercise</span></b-nav-text>
						<b-nav-text v-if="results && results.fasi.length > 0" :class="ux.table == 'hr_by_fase' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="hr_by_fase" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'hr_by_fase'">Heart Rate By Excercise</span></b-nav-text>
						<b-nav-text v-if="auth.isUserEnable('tools_chart')" :class="ux.table == 'chart' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="chart" button size="sm" button-variant="outline-primary" :disabled="true"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'chart'">Chart</span></b-nav-text>
						<b-nav-text v-if="sessione" :class="ux.table == 'interno' ? 'nav-link active' : 'nav-link'" style="display: flex;"><b-form-checkbox v-model="ux.tab_sel" name="tool_tabs" value="interno" button size="sm" button-variant="outline-primary"><i class="fa fa-print" aria-hidden="true"></i></b-form-checkbox><span style="cursor: pointer;" @click.prevent="ux.table = 'interno'">Interno</span></b-nav-text>
						<span class="flex-grow-1 text-right">
							<b-button-group>
								<b-button v-if="auth.isUserEnable('whiteboard_config')" size="sm" variant="outline-success" @click.prevent="sendToMonitor(ux.table)">Invia al Monitor {{ ux.tab_sel.length > 0 ? '('+ ux.tab_sel.length +' selezionati)' : 'corrente'}}</b-button>		
								<b-button size="sm" @click.prevent="esportaCsv(ux.table)">Esporta Csv</b-button>		
								<b-button variant="primary" size="sm" @click.prevent="stampaTabella(ux.table)">Stampa tab {{ ux.tab_sel.length > 0 ? '('+ ux.tab_sel.length +' selezionati)' : 'corrente'}}</b-button>

								<b-button variant="outline-primary" v-if="ux.table == 'velocity'" size="sm" @click.prevent="ux.showVelocity2Tab = !ux.showVelocity2Tab; ux.table = 'velocity2'">% Max Velocity</b-button>	
								<b-button variant="outline-primary" v-if="ux.table == 'velocity2'" size="sm" @click.prevent="ux.showVelocity2Tab = !ux.showVelocity2Tab; ux.table = 'velocity'">Absolute Velocity</b-button>
								<b-button variant="outline-primary" v-if="ux.table == 'velocity_by_fase'" size="sm" @click.prevent="ux.showVelocity2ByExeTab = !ux.showVelocity2ByExeTab; ux.table = 'velocity2_by_fase'">% Max Velocity</b-button>	
								<b-button variant="outline-primary" v-if="ux.table == 'velocity2_by_fase'" size="sm" @click.prevent="ux.showVelocity2ByExeTab = !ux.showVelocity2ByExeTab; ux.table = 'velocity_by_fase'">Absolute Velocity</b-button>

								<b-button variant="outline-secondary" v-if="(ux.table == 'velocity' || ux.table == 'velocity2') && auth.isUserEnable('tools_chart')" size="sm" @click.prevent="ux.showVelocityChart = !ux.showVelocityChart">{{ ux.showVelocityChart ? 'Tabella' : 'Grafico'}}</b-button>
								<b-button variant="outline-secondary" v-if="ux.table == 'neurom' && auth.isUserEnable('tools_chart')" size="sm" @click.prevent="ux.showNeuromChart = !ux.showNeuromChart">{{ ux.showNeuromChart ? 'Tabella' : 'Grafico'}}</b-button>
								<b-button variant="outline-secondary" v-if="ux.table == 'drill' && auth.isUserEnable('tools_chart')" size="sm" @click.prevent="ux.showGeneralChart = !ux.showGeneralChart">{{ ux.showGeneralChart ? 'Tabella' : 'Grafico'}}</b-button>	
								<b-button v-if="['drill','velocity','velocity2','neurom','hr'].includes(ux.table)" size="sm" variant="outline-secondary" @click.prevent="ux.splitByDay = !ux.splitByDay">{{ ux.splitByDay ? 'Aggrega per periodo' : 'Splitta per giorno'}}</b-button>
								<b-button size="sm" @click.prevent="printToolsPlayerGraph" v-if="sessione && auth.isUserEnable('print_tools_playergraph')">Performance Summary</b-button>
								<b-button size="sm" @click.prevent="exportSummaryCsv" variant="outline-primary" v-if="auth.isAdmin() || auth.isUserEnable('export_summary')">Export Summary</b-button>
							</b-button-group>
						</span>
					</b-nav>
					<div v-if="ux.table == 'interno'">
						
						<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'" v-if="!ux.showGeneralChart">
							<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
								<b-tr>
									<th :colspan="ux.splitByDay ? 2 : 1"></th>
									<th colspan="3" class="text-center" style="backgroundColor: #feeada">Interno</th>
									<th></th>
								</b-tr>
								<b-tr>
									<th></th>
									<!-- <th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
										<a href="" @click.prevent="setOrdering('drill', 'edward')">Edwards TL</a> <span v-if="'edward' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
										<div class="small text-muted">(ua)</div>
									</th> -->
									<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
										<a href="" @click.prevent="setOrdering('drill', 'session_minutes')">Session</a>
										<div class="small text-muted">(min)</div>
									</th>
									<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
										<a href="" @click.prevent="setOrdering('drill', 'rpe')">RPE</a>
										<div class="small text-muted">(ua)</div>
									</th>
									<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
										<a href="" @click.prevent="setOrdering('drill', 'sessione_tl')">Session TL</a>
										<div class="small text-muted">(ua)</div>
									</th>
									<th></th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="p in theInternaTable" v-if="p.durata && (!p.day_off || (p.day_off && daysOffByPersonRaw[p.persona_id]['hr'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['catapult'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['durata'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['rpe'].includes(p.date)))">
									<td class="text-right">{{ p.persona.cognome }}</td>
									<td class="text-center" v-if="ux.splitByDay">{{ p.tables.drill.date }}</td>
									<!-- <td class="text-center" width="5%">{{ p.tables.drill.edward }}</td> -->
									<td class="text-center" width="5%">{{ p.durata }}</td>
									<td class="text-center" width="5%">{{ p.rpe }}</td>
									<td class="text-center" width="5%">{{ p.tl }}</td>
									<td class="text-center" width="60%"></td>
								</b-tr>
							</b-tbody>
							<!--<b-tfoot>
								<b-tr>
									<th class="text-right">Totale</th>
									<th class="text-center"></th>
									<th class="text-center" v-if="ux.splitByDay"></th>
									<! -- <th class="text-center" width="5%">{{ sumOf('drill', 'edward') }}</th> -- >
									<th class="text-center" width="5%">{{ sumOf('drill', 'session_minutes') }}</th>
									<th class="text-center" width="5%">{{ sumOf('drill', 'rpe') }}</th>
									<th class="text-center" width="5%">{{ sumOf('drill', 'sessione_tl') }}</th>
								</b-tr>
								<b-tr>
									<th class="text-right">Media</th>
									<th class="text-center"></th>
									<th class="text-center" v-if="ux.splitByDay"></th>
									<! -- <th class="text-center" width="5%">{{ meanOf('drill', 'edward') }}</th> -- >
									<th class="text-center" width="5%">{{ meanOf('drill', 'session_minutes') }}</th>
									<th class="text-center" width="5%">{{ meanOf('drill', 'rpe') }}</th>
									<th class="text-center" width="5%">{{ meanOf('drill', 'sessione_tl') }}</th>
								</b-tr>
								<b-tr>
									<th class="text-right">Media Team</th>
									<th class="text-center"></th>
									<th class="text-center" v-if="ux.splitByDay"></th>
									<! -- <th class="text-center" width="5%">{{ meanOfByDay('edward') }}</th> -- >
									<th class="text-center" width="5%">{{ meanOfByDay('session_minutes') }}</th>
									<th class="text-center" width="5%">{{ meanOfByDay('rpe') }}</th>
									<th class="text-center" width="5%">{{ meanOfByDay('sessione_tl') }}</th>
								</b-tr>
								<b-tr>
									<th class="text-right">SD</th>
									<th class="text-center"></th>
									<th class="text-center" v-if="ux.splitByDay"></th>
									<! -- <th class="text-center" width="5%">{{ standardDeviation('drill', 'edward') }}</th> -- >
									<th class="text-center" width="5%">{{ standardDeviation('drill', 'session_minutes') }}</th>
									<th class="text-center" width="5%">{{ standardDeviation('drill', 'rpe') }}</th>
									<th class="text-center" width="5%">{{ standardDeviation('drill', 'sessione_tl') }}</th>
								</b-tr>
							</b-tfoot>-->
						</b-table-simple>

						<!-- <pre>{{theInternaTable}}</pre> -->
						

					</div>
					<div v-if="ux.table == 'chart'">
						<ToolsChart :filters="filters" :show-editor="true" :results="results" :days="daysDataset" :dataset-nopeople="theResultsNoPeople" :dataset="theResults" :all-dataset="ux.allDataset" :the-tables="theTables" :matchCalendar="matchCalendar" :preset="['total_distance', 'velocity_20_25', 'velocity__25', 'num_acc', 'num_dec', 'rpe']" :daysOffShow="ux.daysOffShow" :daysOffKpi="daysOffKpi" :daysOff="daysOff" :daysOffByPerson="daysOffByPerson" :categoriaId="categoriaId"/>
					</div>
					<div v-if="ux.table == 'hr'">
						<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
							<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
								<b-tr>
									<th width="15%" class="text-right"><a href="" @click.prevent="setOrdering('hr', 'cognome')">Player</a> <span v-if="'cognome' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span></th>
									<th class="text-center" width="4%"><a href="" @click.prevent="setOrdering('hr', 'ruolo_order')">R</a> <span v-if="'ruolo_order' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span></th>
									<th v-if="ux.splitByDay" class="text-center"><a href="" @click.prevent="setOrdering('hr', 'date')">Date</a> <span v-if="'date' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span></th>
									<th class="text-center"><a href="" @click.prevent="setOrdering('hr', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
										<div class="small text-muted">(min)</div></th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'avg_hr')">AVG HR</a> <span v-if="'avg_hr' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(bpm)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'avg_hr_p')">AVG HR</a> <span v-if="'avg_hr_p' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'max_hr')">Max HR</a> <span v-if="'max_hr' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(bpm)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'max_hr_p')">MAX HR</a> <span v-if="'max_hr_p' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_0_55')">HR 0-55</a> <span v-if="'hr_0_55' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_55_65')">HR 55-65</a> <span v-if="'hr_55_65' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_65_75')">HR 65-75</a> <span v-if="'hr_65_75' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_75_85')">HR 75-85</a> <span v-if="'hr_75_85' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_85_95')">HR 85-95</a> <span v-if="'hr_85_95' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_95')">HR 95</a> <span v-if="'hr_95' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_85')">HR 85</a> <span v-if="'hr_85' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
											<a href="" @click.prevent="setOrdering('hr', 'hr_85_raw')">HR 85</a> <span v-if="'hr_85' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(min)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
											<a href="" @click.prevent="setOrdering('hr', 'edward')">Edward TL</a> <span v-if="'edward' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(ua)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
											<a href="" @click.prevent="setOrdering('hr', 'sessione_tl')">Session TL</a> <span v-if="'sessione_tl' == orderings.hr.key">{{ orderings.hr.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(ua)</div>
										</th>
									</b-tr>
								</b-thead>
								<b-tbody>
									<b-tr v-for="p in theTables" v-if="!p.day_off || (p.day_off && daysOffByPersonRaw[p.persona_id]['hr'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['catapult'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['durata'].includes(p.date))">
										<td class="text-right">{{ p.persona.cognome }} 
											<b-badge v-if="p.tables.hr.alert_h" pill variant="danger">H</b-badge> <b-badge v-if="p.tables.hr.alert_v" pill variant="danger">V</b-badge> <b-badge v-if="p.tables.hr.alert_d" pill variant="danger">D</b-badge>
										</td>
										<td class="text-center">{{ p.tables.hr.ruolo }}</td>
										<td class="text-center" v-if="ux.splitByDay">{{ p.tables.hr.date }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.gps_minutes }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.avg_hr }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.avg_hr_p }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.max_hr }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.max_hr_p }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_0_55 }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_55_65 }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_65_75 }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_75_85 }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_85_95 }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_95 }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_85 }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.hr_85_raw }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.edward }}</td>
										<td class="text-center" width="5%">{{ p.tables.hr.sessione_tl }}</td>
									</b-tr>
								</b-tbody>
								<b-tfoot>
									<b-tr>
										<th class="text-right">Totale</th>
										<th class="text-center"></th>
										<th class="text-center" v-if="ux.splitByDay"></th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'gps_minutes') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'avg_hr') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'avg_hr_p') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'max_hr') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'max_hr_p') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_0_55') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_55_65') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_65_75') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_75_85') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_85_95') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_95') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_85') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'hr_85_raw') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'edward') }}</th>
										<th class="text-center" width="5%">{{ sumOf('hr', 'sessione_tl') }}</th>
									</b-tr>
									<b-tr>
										<th class="text-right">Media</th>
										<th class="text-center"></th>
										<th class="text-center" v-if="ux.splitByDay"></th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'gps_minutes') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'avg_hr') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'avg_hr_p') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'max_hr') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'max_hr_p') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_0_55') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_55_65') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_65_75') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_75_85') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_85_95') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_95') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_85') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'hr_85_raw') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'edward') }}</th>
										<th class="text-center" width="5%">{{ meanOf('hr', 'sessione_tl') }}</th>
									</b-tr>
									<b-tr>
										<th class="text-right">Media Team</th>
										<th class="text-center"></th>
										<th class="text-center" v-if="ux.splitByDay"></th>
										<th class="text-center" width="5%">{{ meanOfByDay('gps_minutes') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('avg_hr') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('avg_hr_p') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('max_hr') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('max_hr_p') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_0_55') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_55_65') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_65_75') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_75_85') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_85_95') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_95') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_85') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('hr_85_raw') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('edward') }}</th>
										<th class="text-center" width="5%">{{ meanOfByDay('sessione_tl') }}</th>
									</b-tr>
									<b-tr>
										<th class="text-right">SD</th>
										<th class="text-center"></th>
										<th class="text-center" v-if="ux.splitByDay"></th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'gps_minutes') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'avg_hr') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'avg_hr_p') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'max_hr') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'max_hr_p') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_0_55') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_55_65') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_65_75') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_75_85') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_85_95') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_95') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_85') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'hr_85_raw') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'edward') }}</th>
										<th class="text-center" width="5%">{{ standardDeviation('hr', 'sessione_tl') }}</th>
									</b-tr>
								</b-tfoot>
							</b-table-simple>
						</div>

						<div v-if="ux.table == 'neurom'">

							<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'" v-if="!ux.showNeuromChart">
								<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
									<b-tr>
										<th :colspan="ux.splitByDay ? 4 : 3"></th>
										<th colspan="2" class="text-center" style="backgroundColor: #e4dfed">Metabolico</th>
										<th colspan="9" class="text-center" style="backgroundColor: #ceb1af">Neuromuscolare</th>
									</b-tr>
									<b-tr>
										<th :width="ux.splitByDay ? '11%' : '15%'" class="text-right"><a href="" @click.prevent="setOrdering('neurom', 'cognome')">Player</a> <span v-if="'cognome' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span></th>
										<th class="text-center" width="4%"><a href="" @click.prevent="setOrdering('neurom', 'ruolo_order')">R</a> <span v-if="'ruolo_order' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span></th>
										<th v-if="ux.splitByDay" class="text-center"  width="4%"><a href="" @click.prevent="setOrdering('neurom', 'date')">Date</a> <span v-if="'date' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span></th>
										<th class="text-center"><a href="" @click.prevent="setOrdering('neurom', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span><div class="small text-muted">(min)</div></th>
										<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
											<a href="" @click.prevent="setOrdering('neurom', 'mp')">MP</a> <span v-if="'mp' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(W/Kg)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
											<a href="" @click.prevent="setOrdering('neurom', 'edi')">EDI</a> <span v-if="'edi' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'pow_ev')">Pow. Ev.</a> <span v-if="'pow_ev' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'num_acc')">Acc</a> <span v-if="'num_acc' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'acc1')">Acc 1</a> <span v-if="'acc1' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'acc2')">Acc 2</a> <span v-if="'acc2' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'acc3')">Acc 3</a> <span v-if="'acc3' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'num_dec')">Dec</a> <span v-if="'num_dec' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'dec3')">Dec 3</a> <span v-if="'dec3' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'dec2')">Dec 2</a> <span v-if="'dec2' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
										<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
											<a href="" @click.prevent="setOrdering('neurom', 'dec1')">Dec 1</a> <span v-if="'dec1' == orderings.neurom.key">{{ orderings.neurom.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</th>
									</b-tr>
								</b-thead>
								<b-tbody>
									<b-tr v-for="p in theTables" v-if="!p.day_off || (p.day_off && daysOffByPersonRaw[p.persona_id]['catapult'].includes(p.date))">
										<td class="text-right">{{ p.persona.cognome }} 
											<b-badge v-if="p.tables.neurom.alert_h" pill variant="danger">H</b-badge> <b-badge v-if="p.tables.neurom.alert_v" pill variant="danger">V</b-badge> <b-badge v-if="p.tables.neurom.alert_d" pill variant="danger">D</b-badge></td>
											<td class="text-center">{{ p.tables.neurom.ruolo }}</td>
											<td class="text-center" v-if="ux.splitByDay">{{ p.tables.neurom.date }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.gps_minutes }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.mp }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.edi }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.pow_ev }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.num_acc }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.acc1 }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.acc2 }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.acc3 }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.num_dec }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.dec3 }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.dec2 }}</td>
											<td class="text-center" width="7%">{{ p.tables.neurom.dec1 }}</td>
										</b-tr>
									</b-tbody>
									<b-tfoot>
										<b-tr>
											<th class="text-right">Totale</th>
											<th class="text-center"></th>
											<th class="text-center" v-if="ux.splitByDay"></th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'gps_minutes') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'mp') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'edi') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'pow_ev') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'num_acc') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'acc1') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'acc2') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'acc3') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'num_dec') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'dec3') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'dec2') }}</th>
											<th class="text-center" width="5%">{{ sumOf('neurom', 'dec1') }}</th>
										</b-tr>
										<b-tr>
											<th class="text-right">Media</th>
											<th class="text-center"></th>
											<th class="text-center" v-if="ux.splitByDay"></th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'gps_minutes') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'mp') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'edi') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'pow_ev') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'num_acc') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'acc1') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'acc2') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'acc3') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'num_dec') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'dec3') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'dec2') }}</th>
											<th class="text-center" width="5%">{{ meanOf('neurom', 'dec1') }}</th>
										</b-tr>
										<b-tr>
											<th class="text-right">Media Team</th>
											<th class="text-center"></th>
											<th class="text-center" v-if="ux.splitByDay"></th>
											<th class="text-center" width="5%">{{ meanOfByDay('gps_minutes') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('mp') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('edi') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('pow_ev') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('num_acc') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('acc1') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('acc2') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('acc3') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('num_dec') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('dec3') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('dec2') }}</th>
											<th class="text-center" width="5%">{{ meanOfByDay('dec1') }}</th>
										</b-tr>
										<b-tr>
											<th class="text-right">SD</th>
											<th class="text-center"></th>
											<th class="text-center" v-if="ux.splitByDay"></th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'gps_minutes') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'mp') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'edi') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'pow_ev') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'num_acc') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'acc1') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'acc2') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'acc3') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'num_dec') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'dec3') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'dec2') }}</th>
											<th class="text-center" width="5%">{{ standardDeviation('neurom', 'dec1') }}</th>
										</b-tr>
									</b-tfoot>
								</b-table-simple>

								<ToolsNeuromChart v-else :dataset="theResults" :filters="filters" :results="results" :dataset-nopeople="theResultsNoPeople" :days="daysDataset"/>

							</div>

							<div v-if="ux.table == 'velocity'">

								<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'" v-if="!ux.showVelocityChart">
									<b-thead>
										<b-tr>
											<th width="15%" class="text-right" style="backgroundColor: #FFFFFF">
												<a href="" @click.prevent="setOrdering('velocity', 'cognome')">Player</a> <span v-if="'cognome' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											</th>
											<th width="4%" class="text-center" style="backgroundColor: #FFFFFF">
												<a href="" @click.prevent="setOrdering('velocity', 'ruolo_order')">R</a> <span v-if="'ruolo_order' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											</th>
											<th v-if="ux.splitByDay" width="4%" class="text-center" style="backgroundColor: #FFFFFF"><a href="" @click.prevent="setOrdering('velocity', 'date')">Date</a> <span v-if="'date' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span></th>
											<th class="text-center" style="backgroundColor: #FFFFFF">
												<a href="" @click.prevent="setOrdering('velocity', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(min)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
												<a href="" @click.prevent="setOrdering('velocity', 'total_distance')">Tot Distance</a> <span v-if="'total_distance' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity_0_8')">0-8 km/h</a> <span v-if="'velocity_0_8' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity_8_13')">8-13 km/h</a> <span v-if="'velocity_8_13' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity_13_15')">13-15 km/h</a> <span v-if="'velocity_13_15' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity_15_20')">15-20 km/h</a> <span v-if="'velocity_15_20' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity_20_25')">20-25 km/h</a> <span v-if="'velocity_20_25' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity__25')">>25 km/h</a> <span v-if="'velocity__25' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity__15')">>15 km/h</a> <span v-if="'velocity__15' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity__20')">>20 km/h</a> <span v-if="'velocity__20' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(m)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
												<a href="" @click.prevent="setOrdering('velocity', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(ua)</div>
											</th>
											<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
												<a href="" @click.prevent="setOrdering('velocity', 'velocity__15_p')">> 15 km/h</a> <span v-if="'velocity__15_p' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
												<div class="small text-muted">(%)</div>
											</th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="p in theTables" v-if="!p.day_off || (p.day_off && daysOffByPersonRaw[p.persona_id]['catapult'].includes(p.date))">
											<td class="text-right">{{ p.persona.cognome }} 
												<b-badge v-if="p.tables.velocity.alert_h" pill variant="danger">H</b-badge> <b-badge v-if="p.tables.velocity.alert_v" pill variant="danger">V</b-badge> <b-badge v-if="p.tables.velocity.alert_d" pill variant="danger">D</b-badge></td>
												<td class="text-center">{{ p.tables.velocity.ruolo }}</td>
												<td class="text-center" v-if="ux.splitByDay">{{ p.tables.velocity.date }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.gps_minutes }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.total_distance }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity_0_8 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity_8_13 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity_13_15 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity_15_20 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity_20_25 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity__25 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity__15 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity__20 }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.sp_ev }}</td>
												<td class="text-center" width="6.5%">{{ p.tables.velocity.velocity__15_p }}</td>
											</b-tr>
										</b-tbody>
										<b-tfoot>
											<b-tr>
												<th class="text-right">Totale</th>
												<th class="text-center"></th>
												<th class="text-center" v-if="ux.splitByDay"></th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'gps_minutes') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'total_distance') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity_0_8') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity_8_13') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity_13_15') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity_15_20') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity_20_25') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity__25') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity__15') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity__20') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'sp_ev') }}</th>
												<th class="text-center" width="5%">{{ sumOf('velocity', 'velocity__15_p') }}</th>
											</b-tr>
											<b-tr>
												<th class="text-right">Media</th>
												<th class="text-center"></th>
												<th class="text-center" v-if="ux.splitByDay"></th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'gps_minutes') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'total_distance') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_0_8') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_8_13') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_13_15') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_15_20') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_20_25') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__25') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__15') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__20') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'sp_ev') }}</th>
												<th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__15_p') }}</th>
											</b-tr>
											<b-tr>
												<th class="text-right">Media Team</th>
												<th class="text-center"></th>
												<th class="text-center" v-if="ux.splitByDay"></th>
												<th class="text-center" width="5%">{{ meanOfByDay('gps_minutes') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('total_distance') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity_0_8') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity_8_13') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity_13_15') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity_15_20') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity_20_25') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity__25') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity__15') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity__20') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('sp_ev') }}</th>
												<th class="text-center" width="5%">{{ meanOfByDay('velocity__15_p') }}</th>
											</b-tr>
											<b-tr>
												<th class="text-right">SD</th>
												<th class="text-center"></th>
												<th class="text-center" v-if="ux.splitByDay"></th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'gps_minutes') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'total_distance') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_0_8') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_8_13') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_13_15') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_15_20') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_20_25') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__25') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__15') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__20') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'sp_ev') }}</th>
												<th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__15_p') }}</th>
											</b-tr>
										</b-tfoot>
									</b-table-simple>

									<ToolsVelocityChart v-else :dataset="theResults" :filters="filters" :results="results"/>
								</div>


								<div v-if="ux.table == 'velocity2'">

									<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'" v-if="!ux.showVelocityChart">
										<b-thead>
											<b-tr>
												<th width="15%" class="text-right" style="backgroundColor: #FFFFFF">
													<a href="" @click.prevent="setOrdering('velocity2', 'cognome')">Player</a> <span v-if="'cognome' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
												</th>
												<th width="4%" class="text-center" style="backgroundColor: #FFFFFF">
													<a href="" @click.prevent="setOrdering('velocity2', 'ruolo_order')">R</a> <span v-if="'ruolo_order' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
												</th>
												<th v-if="ux.splitByDay" width="4%" class="text-center" style="backgroundColor: #FFFFFF"><a href="" @click.prevent="setOrdering('velocity2', 'date')">Date</a> <span v-if="'date' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span></th>
												<th class="text-center" style="backgroundColor: #FFFFFF">
													<a href="" @click.prevent="setOrdering('velocity2', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(min)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
													<a href="" @click.prevent="setOrdering('velocity2', 'total_distance')">Tot Distance</a> <span v-if="'total_distance' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc_0_60')">0-60% Max Vel</a> <span v-if="'vel_max_perc_0_60' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc_60_70')">60-70% Max Vel</a> <span v-if="'vel_max_perc_60_70' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc_70_80')">70-80% Max Vel</a> <span v-if="'vel_max_perc_70_80' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc_80_90')">80-90% Max Vel</a> <span v-if="'vel_max_perc_80_90' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc_90_100')">90-100% Max Vel</a> <span v-if="'vel_max_perc_90_100' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc__100')">>100% Max Vel</a> <span v-if="'vel_max_perc__100' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc__80')">>80% Max Vel</a> <span v-if="'vel_max_perc__80' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc__90')">>90% Max Vel</a> <span v-if="'vel_max_perc__90' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(m)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
													<a href="" @click.prevent="setOrdering('velocity2', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(ua)</div>
												</th>
												<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
													<a href="" @click.prevent="setOrdering('velocity2', 'vel_max_perc__80_p')">>80% Max Vel</a> <span v-if="'vel_max_perc__80_p' == orderings.velocity2.key">{{ orderings.velocity2.order == -1 ? '↥' : '↧' }}</span>
													<div class="small text-muted">(%)</div>
												</th>
											</b-tr>
										</b-thead>
										<b-tbody>
											<b-tr v-for="p in theTables" v-if="!p.day_off || (p.day_off && daysOffByPersonRaw[p.persona_id]['catapult'].includes(p.date))">
												<td class="text-right">{{ p.persona.cognome }} 
													<b-badge v-if="p.tables.velocity.alert_h" pill variant="danger">H</b-badge> <b-badge v-if="p.tables.velocity.alert_v" pill variant="danger">V</b-badge> <b-badge v-if="p.tables.velocity.alert_d" pill variant="danger">D</b-badge></td>
													<td class="text-center">{{ p.tables.velocity.ruolo }}</td>
													<td class="text-center" v-if="ux.splitByDay">{{ p.tables.velocity.date }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.gps_minutes }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.total_distance }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc_0_60 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc_60_70 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc_70_80 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc_80_90 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc_90_100 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc__100 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc__80 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc__90 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.sp_ev }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity.vel_max_perc__80_p }}</td>
												</b-tr>
											</b-tbody>
											<b-tfoot>
												<b-tr>
													<th class="text-right">Totale</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc_0_60') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc_60_70') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc_70_80') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc_80_90') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc_90_100') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc__100') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc__80') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc__90') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ sumOf('velocity', 'vel_max_perc__80_p') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc_0_60') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc_60_70') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc_70_80') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc_80_90') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc_90_100') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc__100') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc__80') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc__90') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOf('velocity', 'vel_max_perc__80_p') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media Team</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ meanOfByDay('gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('total_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc_0_60') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc_60_70') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc_70_80') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc_80_90') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc_90_100') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc__100') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc__80') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc__90') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('sp_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('vel_max_perc__80_p') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">SD</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc_0_60') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc_60_70') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc_70_80') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc_80_90') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc_90_100') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc__100') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc__80') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc__90') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('velocity', 'vel_max_perc__80_p') }}</th>
												</b-tr>
											</b-tfoot>
										</b-table-simple>
										<ToolsVelocity2Chart v-else :dataset="theResults" :filters="filters" :results="results"/>
									</div>


									<div v-if="ux.table == 'drill'">

										<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'" v-if="!ux.showGeneralChart">
											<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
												<b-tr>
													<th :colspan="ux.splitByDay ? 4 : 3"></th>
													<th colspan="4" class="text-center" style="backgroundColor: #dde6f1">Cinematico</th>
													<th colspan="2" class="text-center" style="backgroundColor: #e4dfed">Metabolico</th>
													<th colspan="4" class="text-center" style="backgroundColor: #f2dcdb">Neuromuscolare</th>
													<th colspan="4" class="text-center" style="backgroundColor: #feeada">Interno</th>
												</b-tr>
												<b-tr>
													<th width="15%" class="text-right">
														<a href="" @click.prevent="setOrdering('drill', 'cognome')">Player</a> <span v-if="'cognome' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
													</th>
													<th class="text-center" width="4%">
														<a href="" @click.prevent="setOrdering('drill', 'ruolo_order')">R</a> <span v-if="'ruolo_order' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
													</th>
													<th v-if="ux.splitByDay" class="text-center"><a href="" @click.prevent="setOrdering('drill', 'date')">Date</a> <span v-if="'date' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span></th>
													<th class="text-center">
														<a href="" @click.prevent="setOrdering('drill', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(min)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill', 'total_distance')">Tot. Dist.</a> <span v-if="'total_distance' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill', 'relative_distance')">D. Rel</a> <span v-if="'relative_distance' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m/min)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill', 'cc_dist_15')">Dist > 15</a> <span v-if="'cc_dist_15' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill', 'max_vel')">Vel Max</a> <span v-if="'max_vel' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(km/h)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
														<a href="" @click.prevent="setOrdering('drill', 'mp')">MP</a> <span v-if="'mp' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(W/Kg)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
														<a href="" @click.prevent="setOrdering('drill', 'edi')">EDI</a> <span v-if="'edi' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(%)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill', 'pow_ev')">Pow. Ev.</a> <span v-if="'pow_ev' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill', 'num_acc')">Acc</a> <span v-if="'num_acc' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill', 'num_dec')">Dec</a> <span v-if="'num_dec' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
														<a href="" @click.prevent="setOrdering('drill', 'edward')">Edwards TL</a> <span v-if="'edward' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(ua)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
														<a href="" @click.prevent="setOrdering('drill', 'session_minutes')">Session</a> <span v-if="'session_minutes' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(min)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
														<a href="" @click.prevent="setOrdering('drill', 'rpe')">RPE</a> <span v-if="'rpe' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(ua)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
														<a href="" @click.prevent="setOrdering('drill', 'sessione_tl')">Session TL</a> <span v-if="'sessione_tl' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(ua)</div>
													</th>
												</b-tr>
											</b-thead>
											<b-tbody>
												<b-tr v-for="p in theTables" v-if="!p.day_off || (p.day_off && daysOffByPersonRaw[p.persona_id]['hr'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['catapult'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['durata'].includes(p.date) && daysOffByPersonRaw[p.persona_id]['rpe'].includes(p.date))">
													<td class="text-right">{{ p.persona.cognome }} <b-badge v-if="p.tables.drill.alert_h" pill variant="danger">H</b-badge> <b-badge v-if="p.tables.drill.alert_v" pill variant="danger">V</b-badge> <b-badge v-if="p.tables.drill.alert_d" pill variant="danger">D</b-badge></td>
													<td class="text-center">{{ p.tables.drill.ruolo }}</td>
													<td class="text-center" v-if="ux.splitByDay">{{ p.tables.drill.date }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.gps_minutes }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.total_distance }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.relative_distance }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.cc_dist_15 }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.max_vel }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.mp }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.edi }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.pow_ev }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.num_acc }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.num_dec }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.sp_ev }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.edward }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.session_minutes }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.rpe }}</td>
													<td class="text-center" width="5%">{{ p.tables.drill.sessione_tl }}</td>
												</b-tr>
											</b-tbody>
											<b-tfoot>
												<b-tr>
													<th class="text-right">Totale</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'relative_distance') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'cc_dist_15') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'max_vel') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'mp') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'edi') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'pow_ev') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'num_acc') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'num_dec') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'edward') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'session_minutes') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'rpe') }}</th>
													<th class="text-center" width="5%">{{ sumOf('drill', 'sessione_tl') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'relative_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'cc_dist_15') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'max_vel') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'mp') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'edi') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'pow_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'num_acc') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'num_dec') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'edward') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'session_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'rpe') }}</th>
													<th class="text-center" width="5%">{{ meanOf('drill', 'sessione_tl') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media Team</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ meanOfByDay('gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('total_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('relative_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('cc_dist_15') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('max_vel') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('mp') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('edi') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('pow_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('num_acc') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('num_dec') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('sp_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('edward') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('session_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('rpe') }}</th>
													<th class="text-center" width="5%">{{ meanOfByDay('sessione_tl') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">SD</th>
													<th class="text-center"></th>
													<th class="text-center" v-if="ux.splitByDay"></th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'relative_distance') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'cc_dist_15') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'max_vel') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'mp') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'edi') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'pow_ev') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'num_acc') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'num_dec') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'edward') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'session_minutes') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'rpe') }}</th>
													<th class="text-center" width="5%">{{ standardDeviation('drill', 'sessione_tl') }}</th>
												</b-tr>
											</b-tfoot>
										</b-table-simple>

										<ToolsChart v-else :filters="filters" :show-editor="false" :preset="['gps_minutes', 'total_distance', 'relative_distance', 'cc_dist_15', 'max_vel', 'mp', 'edi', 'pow_ev', 'num_acc', 'num_dec', 'sp_ev', 'edward', 'session_minutes', 'rpe', 'sessione_tl']" :results="results" :days="daysDataset" :dataset-nopeople="theResultsNoPeople" :dataset="theResults" :all-dataset="ux.allDataset" :the-tables="theTables" :matchCalendar="matchCalendar" :daysOffShow="ux.daysOffShow" :daysOffKpi="daysOffKpi" :daysOff="daysOff" :daysOffByPerson="daysOffByPerson" :categoriaId="categoriaId"/>
									</div>

									<div v-if="ux.table == 'drill_by_fase'">

										<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
											<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
												<b-tr>
													<th colspan="2"></th>
													<th colspan="4" class="text-center" style="backgroundColor: #dde6f1">Cinematico</th>
													<th colspan="2" class="text-center" style="backgroundColor: #e4dfed">Metabolico</th>
													<th colspan="4" class="text-center" style="backgroundColor: #f2dcdb">Neuromuscolare</th>
													<th colspan="2" class="text-center" style="backgroundColor: #feeada">Interno</th>
												</b-tr>
												<b-tr>
													<th width="15%" class="text-right">
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'fase')">Excercise</a> <span v-if="'fase' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
													</th>
													<th class="text-center">
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(min)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'total_distance')">Tot. Dist.</a> <span v-if="'total_distance' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'relative_distance')">D. Rel</a> <span v-if="'relative_distance' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m/min)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'cc_dist_15')">Dist > 15</a> <span v-if="'cc_dist_15' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'max_vel')">Vel Max</a> <span v-if="'max_vel' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(km/h)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'mp')">MP</a> <span v-if="'mp' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(W/Kg)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'edi')">EDI</a> <span v-if="'edi' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(%)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'pow_ev')">Pow. Ev.</a> <span v-if="'pow_ev' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'num_acc')">Acc</a> <span v-if="'num_acc' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'num_dec')">Dec</a> <span v-if="'num_dec' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'player_load')">Player Load</a> <span v-if="'player_load' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted"></div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
														<a href="" @click.prevent="setOrdering('drill_by_fase', 'player_load_distance')">PL / Metri</a> <span v-if="'player_load_distance' == orderings.drill_by_fase.key">{{ orderings.drill_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted"></div>
													</th>
												</b-tr>
											</b-thead>
											<b-tbody>
												<b-tr v-for="p in theTablesByFase">
													<td class="text-right">{{ p.fase.nome }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.gps_minutes }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.total_distance }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.relative_distance }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.cc_dist_15 }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.max_vel }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.mp }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.edi }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.pow_ev }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.num_acc }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.num_dec }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.sp_ev }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.player_load }}</td>
													<td class="text-center" width="6%">{{ p.tables.drill_by_fase.player_load_distance }}</td>
												</b-tr>
											</b-tbody>
											<b-tfoot>
												<b-tr>
													<th class="text-right">Totale</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'relative_distance') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'cc_dist_15') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'max_vel') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'mp') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'edi') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'pow_ev') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'num_acc') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'num_dec') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'player_load') }}</th>
													<th class="text-center" width="6%">{{ sumOfByFase('drill_by_fase', 'player_load_distance') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'relative_distance') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'cc_dist_15') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'max_vel') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'mp') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'edi') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'pow_ev') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'num_acc') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'num_dec') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'player_load') }}</th>
													<th class="text-center" width="6%">{{ meanOfByFase('drill_by_fase', 'player_load_distance') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">SD</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'relative_distance') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'cc_dist_15') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'max_vel') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'mp') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'edi') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'pow_ev') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'num_acc') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'num_dec') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'player_load') }}</th>
													<th class="text-center" width="6%">{{ standardDeviationByFase('drill_by_fase', 'player_load_distance') }}</th>
												</b-tr>
											</b-tfoot>
										</b-table-simple>
									</div>

									<div v-if="ux.table == 'velocity_by_fase'">

										<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
											<b-thead>
												<b-tr>
													<th width="15%" class="text-right" style="backgroundColor: #FFFFFF">
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'cognome')">Excercise</a> <span v-if="'fase' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
													</th>
													<th class="text-center" style="backgroundColor: #FFFFFF">
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(min)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'total_distance')">Tot Distance</a> <span v-if="'total_distance' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity_0_8')">0-8 km/h</a> <span v-if="'velocity_0_8' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity_8_13')">8-13 km/h</a> <span v-if="'velocity_8_13' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity_13_15')">13-15 km/h</a> <span v-if="'velocity_13_15' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity_15_20')">15-20 km/h</a> <span v-if="'velocity_15_20' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity_20_25')">20-25 km/h</a> <span v-if="'velocity_20_25' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity__25')">>25 km/h</a> <span v-if="'velocity__25' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity__15')">>15 km/h</a> <span v-if="'velocity__15' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity__20')">>20 km/h</a> <span v-if="'velocity__20' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(ua)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity_by_fase', 'velocity__15_p')">> 15 km/h</a> <span v-if="'velocity__15_p' == orderings.velocity_by_fase.key">{{ orderings.velocity_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(%)</div>
													</th>
												</b-tr>
											</b-thead>
											<b-tbody>
												<b-tr v-for="p in theTablesByFase">
													<td class="text-right">{{ p.fase.nome }} </td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.gps_minutes }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.total_distance }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity_0_8 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity_8_13 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity_13_15 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity_15_20 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity_20_25 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity__25 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity__15 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity__20 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.sp_ev }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.velocity__15_p }}</td>
												</b-tr>
											</b-tbody>
											<b-tfoot>
												<b-tr>
													<th class="text-right">Totale</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity_0_8') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity_8_13') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity_13_15') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity_15_20') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity_20_25') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity__25') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity__15') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity__20') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'velocity__15_p') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity_0_8') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity_8_13') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity_13_15') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity_15_20') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity_20_25') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity__25') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity__15') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity__20') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'velocity__15_p') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">SD</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity_0_8') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity_8_13') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity_13_15') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity_15_20') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity_20_25') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity__25') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity__15') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity__20') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'velocity__15_p') }}</th>
												</b-tr>
											</b-tfoot>
										</b-table-simple>

									</div>

									<div v-if="ux.table == 'velocity2_by_fase'">

										<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
											<b-thead>
												<b-tr>
													<th width="15%" class="text-right" style="backgroundColor: #FFFFFF">
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'cognome')">Excercise</a> <span v-if="'fase' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
													</th>
													<th class="text-center" style="backgroundColor: #FFFFFF">
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(min)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'total_distance')">Tot Distance</a> <span v-if="'total_distance' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc_0_60')">0-60% Max Vel</a> <span v-if="'vel_max_perc_0_60' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc_60_70')">60-70% Max Vel</a> <span v-if="'vel_max_perc_60_70' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc_70_80')">70-80% Max Vel</a> <span v-if="'vel_max_perc_70_80' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc_80_90')">80-90% Max Vel</a> <span v-if="'vel_max_perc_80_90' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #FFFFFF">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc_90_100')">90-100% Max Vel</a> <span v-if="'vel_max_perc_90_100' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc__100')">>100% Max Vel</a> <span v-if="'vel_max_perc__100' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc__80')">>80% Max Vel</a> <span v-if="'vel_max_perc__80' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc__90')">>90% Max Vel</a> <span v-if="'vel_max_perc__90' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(m)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(ua)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
														<a href="" @click.prevent="setOrdering('velocity2_by_fase', 'vel_max_perc__80_p')">>80% Max Vel</a> <span v-if="'vel_max_perc__80_p' == orderings.velocity2_by_fase.key">{{ orderings.velocity2_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(%)</div>
													</th>
												</b-tr>
											</b-thead>
											<b-tbody>
												<b-tr v-for="p in theTablesByFase">
													<td class="text-right">{{ p.fase.nome }} </td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.gps_minutes }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.total_distance }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc_0_60 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc_60_70 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc_70_80 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc_80_90 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc_90_100 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc__100 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc__80 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc__90 }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.sp_ev }}</td>
													<td class="text-center" width="6.5%">{{ p.tables.velocity_by_fase.vel_max_perc__80_p }}</td>
												</b-tr>
											</b-tbody>
											<b-tfoot>
												<b-tr>
													<th class="text-right">Totale</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc_0_60') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc_60_70') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc_70_80') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc_80_90') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc_90_100') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc__100') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc__80') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc__90') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('velocity_by_fase', 'vel_max_perc__80_p') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc_0_60') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc_60_70') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc_70_80') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc_80_90') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc_90_100') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc__100') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc__80') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc__90') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('velocity_by_fase', 'vel_max_perc__80_p') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">SD</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'total_distance') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc_0_60') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc_60_70') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc_70_80') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc_80_90') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc_90_100') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc__100') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc__80') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc__90') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'sp_ev') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('velocity_by_fase', 'vel_max_perc__80_p') }}</th>
												</b-tr>
											</b-tfoot>
										</b-table-simple>

									</div>

									<div v-if="ux.table == 'neurom_by_fase'">

										<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
											<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
												<b-tr>
													<th colspan="2"></th>
													<th colspan="2" class="text-center" style="backgroundColor: #e4dfed">Metabolico</th>
													<th colspan="9" class="text-center" style="backgroundColor: #ceb1af">Neuromuscolare</th>
												</b-tr>
												<b-tr>
													<th width="15%" class="text-right"><a href="" @click.prevent="setOrdering('neurom_by_fase', 'fase')">Excercise</a> <span v-if="'fase' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span></th>
													<th class="text-center"><a href="" @click.prevent="setOrdering('neurom_by_fase', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span><div class="small text-muted">(min)</div></th>
													<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'mp')">MP</a> <span v-if="'mp' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(W/Kg)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'edi')">EDI</a> <span v-if="'edi' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(%)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'pow_ev')">Pow. Ev.</a> <span v-if="'pow_ev' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'num_acc')">Acc</a> <span v-if="'num_acc' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'acc1')">Acc 1</a> <span v-if="'acc1' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'acc2')">Acc 2</a> <span v-if="'acc2' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'acc3')">Acc 3</a> <span v-if="'acc3' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'num_dec')">Dec</a> <span v-if="'num_dec' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'dec3')">Dec 3</a> <span v-if="'dec3' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'dec2')">Dec 2</a> <span v-if="'dec2' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
													<th rowspan="1" class="text-center" style="backgroundColor: #ceb1af">	
														<a href="" @click.prevent="setOrdering('neurom_by_fase', 'dec1')">Dec 1</a> <span v-if="'dec1' == orderings.neurom_by_fase.key">{{ orderings.neurom_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(n.)</div>
													</th>
												</b-tr>
											</b-thead>
											<b-tbody>
												<b-tr v-for="p in theTablesByFase">
													<td class="text-right">{{ p.fase.nome }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.gps_minutes }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.mp }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.edi }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.pow_ev }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.num_acc }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.acc1 }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.acc2 }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.acc3 }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.num_dec }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.dec3 }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.dec2 }}</td>
													<td class="text-center" width="7%">{{ p.tables.neurom_by_fase.dec1 }}</td>
												</b-tr>
											</b-tbody>
											<b-tfoot>
												<b-tr>
													<th class="text-right">Totale</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'mp') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'edi') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'pow_ev') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'num_acc') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'acc1') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'acc2') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'acc3') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'num_dec') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'dec3') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'dec2') }}</th>
													<th class="text-center" width="5%">{{ sumOfByFase('neurom_by_fase', 'dec1') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">Media</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'mp') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'edi') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'pow_ev') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'num_acc') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'acc1') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'acc2') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'acc3') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'num_dec') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'dec3') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'dec2') }}</th>
													<th class="text-center" width="5%">{{ meanOfByFase('neurom_by_fase', 'dec1') }}</th>
												</b-tr>
												<b-tr>
													<th class="text-right">SD</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'gps_minutes') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'mp') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'edi') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'pow_ev') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'num_acc') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'acc1') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'acc2') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'acc3') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'num_dec') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'dec3') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'dec2') }}</th>
													<th class="text-center" width="5%">{{ standardDeviationByFase('neurom_by_fase', 'dec1') }}</th>
												</b-tr>
											</b-tfoot>
										</b-table-simple>

									</div>

									<div v-if="ux.table == 'hr_by_fase'">
										<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
											<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
												<b-tr>
													<th width="15%" class="text-right"><a href="" @click.prevent="setOrdering('hr_by_fase', 'fase')">Excercise</a> <span v-if="'fase' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span></th>
													<th class="text-center"><a href="" @click.prevent="setOrdering('hr_by_fase', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
														<div class="small text-muted">(min)</div></th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'avg_hr')">AVG HR</a> <span v-if="'avg_hr' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(bpm)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'avg_hr_p')">AVG HR</a> <span v-if="'avg_hr_p' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'max_hr')">Max HR</a> <span v-if="'max_hr' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(bpm)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'max_hr_p')">MAX HR</a> <span v-if="'max_hr_p' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_0_55')">HR 0-55</a> <span v-if="'hr_0_55' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_55_65')">HR 55-65</a> <span v-if="'hr_55_65' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_65_75')">HR 65-75</a> <span v-if="'hr_65_75' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_75_85')">HR 75-85</a> <span v-if="'hr_75_85' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_85_95')">HR 85-95</a> <span v-if="'hr_85_95' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_95')">HR 95</a> <span v-if="'hr_95' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_85')">HR 85</a> <span v-if="'hr_85' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(%)</div>
														</th>
														<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'hr_85_raw')">HR 85</a> <span v-if="'hr_85' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(min)</div>
														</th>
														<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'edward')">Edward TL</a> <span v-if="'edward' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(ua)</div>
														</th>
														<th rowspan="1" class="text-center" style="backgroundColor: #fcd5b5">	
															<a href="" @click.prevent="setOrdering('hr_by_fase', 'sessione_tl')">Session TL</a> <span v-if="'sessione_tl' == orderings.hr_by_fase.key">{{ orderings.hr_by_fase.order == -1 ? '↥' : '↧' }}</span>
															<div class="small text-muted">(ua)</div>
														</th>
													</b-tr>
												</b-thead>
												<b-tbody>
													<b-tr v-for="p in theTablesByFase">
														<td class="text-right">{{ p.fase.nome }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.gps_minutes }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.avg_hr }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.avg_hr_p }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.max_hr }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.max_hr_p }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_0_55 }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_55_65 }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_65_75 }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_75_85 }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_85_95 }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_95 }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_85 }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.hr_85_raw }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.edward }}</td>
														<td class="text-center" width="5%">{{ p.tables.hr_by_fase.sessione_tl }}</td>
													</b-tr>
												</b-tbody>
												<b-tfoot>
													<b-tr>
														<th class="text-right">Totale</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'gps_minutes') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'avg_hr') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'avg_hr_p') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'max_hr') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'max_hr_p') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_0_55') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_55_65') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_65_75') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_75_85') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_85_95') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_95') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_85') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'hr_85_raw') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'edward') }}</th>
														<th class="text-center" width="5%">{{ sumOfByFase('hr_by_fase', 'sessione_tl') }}</th>
													</b-tr>
													<b-tr>
														<th class="text-right">Media</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'gps_minutes') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'avg_hr') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'avg_hr_p') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'max_hr') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'max_hr_p') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_0_55') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_55_65') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_65_75') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_75_85') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_85_95') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_95') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_85') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'hr_85_raw') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'edward') }}</th>
														<th class="text-center" width="5%">{{ meanOfByFase('hr_by_fase', 'sessione_tl') }}</th>
													</b-tr>
													<b-tr>
														<th class="text-right">SD</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'gps_minutes') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'avg_hr') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'avg_hr_p') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'max_hr') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'max_hr_p') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_0_55') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_55_65') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_65_75') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_75_85') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_85_95') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_95') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_85') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'hr_85_raw') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'edward') }}</th>
														<th class="text-center" width="5%">{{ standardDeviationByFase('hr_by_fase', 'sessione_tl') }}</th>
													</b-tr>
												</b-tfoot>
											</b-table-simple>

										</div>

										<b-form-group label="Note Tools" v-if="sessione">
											<b-textarea v-model="sessione.setup.note_tools" :disabled="!auth.isUserEnable('import_tools_analysis')"></b-textarea>
										</b-form-group>
									</div>

								</b-card>
								<span v-else-if="collapsable">
									<b-button size="sm" variant="primary" block @click.prevent="ux.collapsed = false">Apri Tools Analysis</b-button>
								</span>
							</b-overlay>
						</div>
					</template>

					<script>

					import toolsHelper from '@/tools_helper.js'

					function buildKpiValues(dati, ds = null) {
						var out = {};
						out = _.merge({}, toolsHelper.buildNeurom(dati, ds));
						out = _.merge(out, toolsHelper.buildDrill(dati, ds));
						out = _.merge(out, toolsHelper.buildVelocity(dati, ds));
						out = _.merge(out, toolsHelper.buildHeartRate(dati, ds));
						out.date = _.values(dati)[0].date;
						return out;
					}

					function buildDuration(dati, ds = null) {

						var duration = _.sumBy(dati, 'total_duration')/60;
						if(ds !== null) {

							duration = 0;

							var valid_sessions = _.keys(_.groupBy(dati, 'sessione_id'));

							var fasiInCuiHoPartecipato = _.filter(ds.timeline.timeline, function(a) {
								return a.persone.indexOf(dati[0].persona_id) !== -1 && !a.is_sessione;
							});

							var fasiInCuiHoPartecipatoG = _.groupBy(fasiInCuiHoPartecipato, 'sessione_id');
	//	console.log(fasiInCuiHoPartecipatoG);
							var durateSessione = {};

							_.each(fasiInCuiHoPartecipatoG, function(a, kk) {

								if(valid_sessions.indexOf(kk) !== -1) {
			//	console.log('Sessione: ' + kk, dati[0].persona.cognome);
									var c = _.first(a);
									var b = _.last(a);

									duration += ((b.end - c.start)/60);
			//	console.log(duration);

								}

							});

						}

						return _.round(duration);

					}

					function buildDurationByFase(dati, ds = null) {
						var duration = (_.sumBy(dati, 'total_duration')/60)/_.size(dati);
						return _.round(duration);
					}

					function buildRpe(dati) {
						var rpeByDay = [];
						var datiWithRpe = _.filter(dati, function(a) { return a.fase_rpe != null; });
						var datiByDay = _.groupBy(datiWithRpe, 'date');
						_.forEach(datiByDay, function(a) {
							var datiBySession = _.groupBy(a, 'sessione_id');
							var rpe = 0;
							_.forEach(datiBySession, function(b) {
								rpe += b[0].fase_rpe;
							});
							rpeByDay.push(rpe/_.keys(datiBySession).length);
						});

						if(_.keys(datiByDay).length > 0) {
							return _.sum(rpeByDay) / _.keys(datiByDay).length;
						}
						return null;
					}

					function buildSessionTl(dati) {

						var datiWithRpeAndDurata = _.filter(dati, function(a) { return a.fase_rpe != null && a.fase_durata != null; });
						var datiBySession = _.groupBy(datiWithRpeAndDurata, 'sessione_id');
						var sessione_tl = 0;
						_.forEach(datiBySession, function(a) {
							sessione_tl += a[0].fase_rpe * a[0].fase_durata;
						});
						if(_.keys(datiBySession).length > 0) {
							return sessione_tl;
						}
						return null;
					}

					function buildNeurom(persona, dati, ds = null, date = null) {

						var duration = buildDuration(dati, ds);

						var ruolo = _.find(persona.last_carriera_sportiva.ruoli, function(a) {
							return a.pivot.ideale == true || a.pivot.ideale == 1;
						});

						if(!ruolo && persona.last_carriera_sportiva.ruoli.length > 0)
							ruolo = persona.last_carriera_sportiva.ruoli[0];
						else if(!ruolo)
							ruolo = { sigla_tools: ' ' };

						var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
						var edi = ((_.sumBy(dati, 'catapult_equivalent_distance') / _.sumBy(dati, 'total_distance'))-1) * 100;
						var pow_ev = _.sum([
							_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
						]);
						var num_acc = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
						]);

						var num_acc0 = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band5_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
						]);
						var acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count');
						var acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count');
						var acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count');

						var acc0 = _.sumBy(dati, 'catapult_gen2_acceleration_band5_total_effort_count');

						var num_dec = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
						]);

						var num_dec0 = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band4_total_effort_count')
						]);

						var dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count');
						var dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count');
						var dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count');

						var dec0 = _.sumBy(dati, 'catapult_gen2_acceleration_band4_total_effort_count');

						var mm_num_acc = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_distance')
						]);

						var mm_num_acc0 = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band5_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_distance')
						]);

						var mm_acc0 = _.sumBy(dati, 'catapult_gen2_acceleration_band5_total_distance');
						var mm_acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_distance');
						var mm_acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_distance');
						var mm_acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_distance');
						
						var mm_num_dec = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_distance')
						]);

						var mm_num_dec0 = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_distance'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band4_total_distance')
						]);

						var mm_dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_distance');
						var mm_dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_distance');
						var mm_dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_distance');
						var mm_dec0 = _.sumBy(dati, 'catapult_gen2_acceleration_band4_total_distance');

						var ss_num_acc = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_duration')
						]);

						var ss_num_acc0 = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band5_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_duration')
						]);

						var ss_acc0 = _.sumBy(dati, 'catapult_gen2_acceleration_band5_total_duration');

						var ss_acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_duration');
						var ss_acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_duration');
						var ss_acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_duration');

						var ss_num_dec = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_duration')
						]);

						var ss_num_dec0 = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_duration'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band4_total_duration')
						]);

						var ss_dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_duration');
						var ss_dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_duration');
						var ss_dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_duration');
						var ss_dec0 = _.sumBy(dati, 'catapult_gen2_acceleration_band4_total_duration');

						var velocity__15 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							]);

						var dati_data = _.uniq(_.map(dati, 'date'));
						var time = null;
						if(dati_data.length == 1)
							time = moment(_.min(_.map(dati, 'start_time')), 'X').format('HH:mm');

						dati_data = _.map(dati_data, function(p) {
							return moment(p).format('DD/MM/YYYY');
						});
						

						return {
							cognome: persona.cognome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							ruolo: ruolo.sigla_tools.split(',')[1],
							ruolo_order: parseInt(ruolo.sigla_tools.split(',')[0]),
							date: date || _.first(dati_data),
							time: time,
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							mp: isValidNumber(mp) ? _.round(mp, 1) : '',
							edi: isValidNumber(edi) ? _.round(edi, 1) : '',
							pow_ev: isValidNumber(pow_ev) ? _.round(pow_ev, 0) : '',
							num_acc: isValidNumber(num_acc) ? _.round(num_acc, 0) : '',
							acc1: isValidNumber(acc1) ? _.round(acc1, 2) : '',
							acc2: isValidNumber(acc2) ? _.round(acc2, 2) : '',
							acc3: isValidNumber(acc3) ? _.round(acc3, 2) : '',
							num_dec: isValidNumber(num_dec) ? _.round(num_dec, 2) : '',
							dec3: isValidNumber(dec3) ? _.round(dec3, 2) : '',
							dec2: isValidNumber(dec2) ? _.round(dec2, 2) : '',
							dec1: isValidNumber(dec1) ? _.round(dec1, 2) : '',
							mm_num_acc: isValidNumber(mm_num_acc) ? _.round(mm_num_acc, 0) : '',
							mm_acc1: isValidNumber(mm_acc1) ? _.round(mm_acc1, 2) : '',
							mm_acc2: isValidNumber(mm_acc2) ? _.round(mm_acc2, 2) : '',
							mm_acc3: isValidNumber(mm_acc3) ? _.round(mm_acc3, 2) : '',
							mm_num_dec: isValidNumber(mm_num_dec) ? _.round(mm_num_dec, 2) : '',
							mm_dec3: isValidNumber(mm_dec3) ? _.round(mm_dec3, 2) : '',
							mm_dec2: isValidNumber(mm_dec2) ? _.round(mm_dec2, 2) : '',
							mm_dec1: isValidNumber(mm_dec1) ? _.round(mm_dec1, 2) : '',
							ss_num_acc: isValidNumber(ss_num_acc) ? _.round(ss_num_acc, 0) : '',
							ss_acc1: isValidNumber(ss_acc1) ? _.round(ss_acc1, 2) : '',
							ss_acc2: isValidNumber(ss_acc2) ? _.round(ss_acc2, 2) : '',
							ss_acc3: isValidNumber(ss_acc3) ? _.round(ss_acc3, 2) : '',
							ss_num_dec: isValidNumber(ss_num_dec) ? _.round(ss_num_dec, 2) : '',
							ss_dec3: isValidNumber(ss_dec3) ? _.round(ss_dec3, 2) : '',
							ss_dec2: isValidNumber(ss_dec2) ? _.round(ss_dec2, 2) : '',
							ss_dec1: isValidNumber(ss_dec1) ? _.round(ss_dec1, 2) : '',

							
							num_accTotal: isValidNumber(num_acc0) ? _.round(num_acc0, 0) : '',
							acc0: isValidNumber(acc0) ? _.round(acc0, 2) : '',
							mm_num_accTotal: isValidNumber(mm_num_acc0) ? _.round(mm_num_acc0, 0) : '',
							mm_acc0: isValidNumber(mm_acc0) ? _.round(mm_acc0, 2) : '',
							ss_num_accTotal: isValidNumber(ss_num_acc0) ? _.round(ss_num_acc0, 0) : '',
							ss_acc0: isValidNumber(ss_acc0) ? _.round(ss_acc0, 2) : '',
							
							num_decTotal: isValidNumber(num_dec0) ? _.round(num_dec0, 2) : '',
							dec0: isValidNumber(dec0) ? _.round(dec0, 2) : '',
							mm_num_decTotal: isValidNumber(mm_num_dec0) ? _.round(mm_num_dec0, 2) : '',
							mm_dec0: isValidNumber(mm_dec0) ? _.round(mm_dec0, 2) : '',
							ss_num_decTotal: isValidNumber(ss_num_dec0) ? _.round(ss_num_dec0, 2) : '',
							ss_dec0: isValidNumber(ss_dec0) ? _.round(ss_dec0, 2) : '',

							alert_d: _.filter(_.map(dati, 'alert_d')).length > 0,
							alert_v: _.filter(_.map(dati, 'alert_v')).length > 0,
							alert_h: _.filter(_.map(dati, 'alert_h')).length > 0
						}
					}	

					function buildNeuromByFase(fase, dati, ds = null) {

						var duration = buildDurationByFase(dati, ds);

						var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
						var edi = _.sum(_.filter(_.map(dati, function(a) { 
							return  (a.catapult_equivalent_distance / a.total_distance) - 1;}), function(b) { 
							return  isValidNumber(b) }))/_.size(dati) * 100;
						var pow_ev = _.sum([
							_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
							])/_.size(dati);
						var num_acc = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
							])/_.size(dati);
						var acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count')/_.size(dati);
						var acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count')/_.size(dati);
						var acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')/_.size(dati);
						var num_dec = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
							])/_.size(dati);
						var dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count')/_.size(dati);
						var dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count')/_.size(dati);
						var dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')/_.size(dati);

						return {
							fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							mp: isValidNumber(mp) ? _.round(mp, 1) : '',
							edi: isValidNumber(edi) ? _.round(edi, 1) : '',
							pow_ev: isValidNumber(pow_ev) ? _.round(pow_ev, 0) : '',
							num_acc: isValidNumber(num_acc) ? _.round(num_acc, 0) : '',
							acc1: isValidNumber(acc1) ? _.round(acc1, 2) : '',
							acc2: isValidNumber(acc2) ? _.round(acc2, 2) : '',
							acc3: isValidNumber(acc3) ? _.round(acc3, 2) : '',
							num_dec: isValidNumber(num_dec) ? _.round(num_dec, 2) : '',
							dec3: isValidNumber(dec3) ? _.round(dec3, 2) : '',
							dec2: isValidNumber(dec2) ? _.round(dec2, 2) : '',
							dec1: isValidNumber(dec1) ? _.round(dec1, 2) : '',
						}
					}	

					function buildDrill(persona, dati, ds = null, date = null) {

						var duration = buildDuration(dati, ds);

						var ruolo = _.find(persona.last_carriera_sportiva.ruoli, function(a) {
							return a.pivot.ideale == true || a.pivot.ideale == 1;
						});

						if(!ruolo && persona.last_carriera_sportiva.ruoli.length > 0)
							ruolo = persona.last_carriera_sportiva.ruoli[0];
						else if(!ruolo)
							ruolo = { sigla_tools: ' ' };

						var total_distance = _.sumBy(dati, 'total_distance');
						var relative_distance =  _.sumBy(dati, 'total_distance')/duration;
						var cc_dist_15 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							]);
						var max_vel = _.maxBy(dati, 'catapult_max_vel') ? _.maxBy(dati, 'catapult_max_vel').catapult_max_vel : 0;
						var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
						var edi = 100 * ((_.sumBy(dati, 'catapult_equivalent_distance') / _.sumBy(dati, 'total_distance'))-1);
						var pow_ev = _.sum([
							_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
							]);
						var num_acc = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
							]);
						var num_dec =_.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
							]);
						var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count');
						var edward = _.sum([
							_.sumBy(dati, 'firstbeat_heart_rate_50_60'),
							_.sumBy(dati, 'firstbeat_heart_rate_60_70') * 2,
							_.sumBy(dati, 'firstbeat_heart_rate_70_80') * 3,
							_.sumBy(dati, 'firstbeat_heart_rate_80_90') * 4,
							_.sumBy(dati, 'firstbeat_heart_rate_90') * 5,
							])/12;
						var session_minutes = _.sum(_.map(_.groupBy(dati, 'sessione_id'), function(a) { return a[0].fase_durata; }));
						var rpe = buildRpe(dati);
						var sessione_tl = buildSessionTl(dati);

						var dati_data = _.uniq(_.map(dati, 'date'));
						var time = null;
						if(dati_data.length == 1)
							time = moment(_.min(_.map(dati, 'start_time')), 'X').format('HH:mm');
						
						dati_data = _.map(dati_data, function(p) {
							return moment(p).format('DD/MM/YYYY');
						});

						return {
							cognome: persona.cognome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							ruolo: ruolo.sigla_tools.split(',')[1],
							ruolo_order: parseInt(ruolo.sigla_tools.split(',')[0]),
							date: date || _.first(dati_data),
							time: time,
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : '',
							relative_distance: isValidNumber(relative_distance) ? _.round(relative_distance) : '',
							cc_dist_15: isValidNumber(cc_dist_15) ? _.round(cc_dist_15) : '',
							max_vel: isValidNumber(max_vel) ? _.round(max_vel, 1) : '',
							mp: isValidNumber(mp) ? _.round(mp, 1) : '',
							edi: isValidNumber(edi) ? _.round(edi, 1) : '',
							pow_ev: isValidNumber(pow_ev) ? _.round(pow_ev, 0) : '',
							num_acc: isValidNumber(num_acc) ? _.round(num_acc, 2) : '',
							num_dec: isValidNumber(num_dec) ? _.round(num_dec, 2) : '',
							sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : '',
							edward: isValidNumber(edward) ? _.round(edward, 0) : '',
							session_minutes: isValidNumber(session_minutes) ? _.round(session_minutes, 0) : '',
							rpe: isValidNumber(rpe) ? _.round(rpe, 1) : '',
							sessione_tl: isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : '',
							alert_d: _.filter(_.map(dati, 'alert_d')).length > 0,
							alert_v: _.filter(_.map(dati, 'alert_v')).length > 0,
							alert_h: _.filter(_.map(dati, 'alert_h')).length > 0
						}
					}

					function buildDrillByFase(fase, dati, ds = null) {

						var duration = buildDurationByFase(dati, ds);

						var total_distance = _.sumBy(dati, 'total_distance')/_.size(dati);
						var relative_distance =  total_distance/duration;
						var cc_dist_15 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							])/_.size(dati);
						var max_vel = _.sumBy(dati, 'catapult_max_vel')/_.size(dati);
						var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
						var edi = _.sum(_.filter(_.map(dati, function(a) { 
							return  (a.catapult_equivalent_distance / a.total_distance) - 1;}), function(b) { 
							return  isValidNumber(b) }))/_.size(dati) * 100;
						var pow_ev = _.sum([
							_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
							_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
							])/_.size(dati);
						var num_acc = _.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
							])/_.size(dati);
						var num_dec =_.sum([
							_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
							_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
							])/_.size(dati);
						var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count')/_.size(dati);

						var player_load = _.sumBy(dati, 'catapult_total_player_load')/_.size(dati);
						var player_load_distance = _.sum(_.map(dati, function(a) { return a.total_distance ? a.catapult_total_player_load/a.total_distance : null; }))/_.size(dati);

						return {
							fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : '',
							relative_distance: isValidNumber(relative_distance) ? _.round(relative_distance) : '',
							cc_dist_15: isValidNumber(cc_dist_15) ? _.round(cc_dist_15) : '',
							max_vel: isValidNumber(max_vel) ? _.round(max_vel, 1) : '',
							mp: isValidNumber(mp) ? _.round(mp, 1) : '',
							edi: isValidNumber(edi) ? _.round(edi, 1) : '',
							pow_ev: isValidNumber(pow_ev) ? _.round(pow_ev, 0) : '',
							num_acc: isValidNumber(num_acc) ? _.round(num_acc, 2) : '',
							num_dec: isValidNumber(num_dec) ? _.round(num_dec, 2) : '',
							sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : '',
							player_load: isValidNumber(player_load) ? _.round(player_load, 0) : '',
							player_load_distance: isValidNumber(player_load_distance) ? _.round(player_load_distance, 2) : '',
						}
					}							

					function buildVelocity(persona, dati, ds = null, date = null) {

						var duration = buildDuration(dati, ds);

						var ruolo = _.find(persona.last_carriera_sportiva.ruoli, function(a) {
							return a.pivot.ideale == true || a.pivot.ideale == 1;
						});

						if(!ruolo && persona.last_carriera_sportiva.ruoli.length > 0)
							ruolo = persona.last_carriera_sportiva.ruoli[0];
						else if(!ruolo)
							ruolo = { sigla_tools: ' ' };


						var ss_velocity_0_8 =_.sum([
							_.sumBy(dati, 'catapult_velocity_band1_total_duration'),
							_.sumBy(dati, 'catapult_velocity_band2_total_duration'),
							_.sumBy(dati, 'catapult_velocity_band3_total_duration'),
							]);
						var ss_velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_duration');
						var ss_velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_duration');
						var ss_velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_duration');
						var ss_velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_duration');
						var ss_velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_duration');
						var ss_velocity__15 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_duration'),
							_.sumBy(dati, 'catapult_velocity_band7_total_duration'),
							_.sumBy(dati, 'catapult_velocity_band8_total_duration'),
							]);
						var ss_velocity__20 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band7_total_duration'),
							_.sumBy(dati, 'catapult_velocity_band8_total_duration'),
							]);

						var total_distance = _.sumBy(dati, 'total_distance');
						var velocity_0_8 =_.sum([
							_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
							]);
						var velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_distance');
						var velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_distance');
						var velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_distance');
						var velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_distance');
						var velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_distance');
						var velocity__15 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							]);
						var velocity__20 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							]);
						var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count');
						var velocity__15_p = 100 * (_.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							]) / _.sum([
							_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band4_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band5_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance')
							]));

						var vel_max_perc_0_60 =_.sum([
							_.sumBy(dati, 'catapult_velocity2_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band3_total_distance'),
							]);
						var vel_max_perc_60_70 = _.sumBy(dati, 'catapult_velocity2_band4_total_distance');
						var vel_max_perc_70_80 = _.sumBy(dati, 'catapult_velocity2_band5_total_distance');
						var vel_max_perc_80_90 = _.sumBy(dati, 'catapult_velocity2_band6_total_distance');
						var vel_max_perc_90_100 = _.sumBy(dati, 'catapult_velocity2_band7_total_distance');
						var vel_max_perc__100 = _.sumBy(dati, 'catapult_velocity2_band8_total_distance');
						var vel_max_perc__80 = _.sum([
							_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
							]);
						var vel_max_perc__90 = _.sum([
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
							]);
						var vel_max_perc__80_p = 100 * (_.sum([
							_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
							]) / _.sum([
							_.sumBy(dati, 'catapult_velocity2_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band3_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band4_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band5_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance')
							]));

						var dati_data = _.uniq(_.map(dati, 'date'));
						var time = null;
						if(dati_data.length == 1)
							time = moment(_.min(_.map(dati, 'start_time')), 'X').format('HH:mm');
						
						dati_data = _.map(dati_data, function(p) {
							return moment(p).format('DD/MM/YYYY');
						});

						return {
							cognome: persona.cognome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							ruolo: ruolo.sigla_tools.split(',')[1],
							ruolo_order: parseInt(ruolo.sigla_tools.split(',')[0]),
							date: date || _.first(dati_data),
							time: time,
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : '',
							velocity_0_8: isValidNumber(velocity_0_8) ? _.round(velocity_0_8, 0) : '',
							velocity_8_13: isValidNumber(velocity_8_13) ? _.round(velocity_8_13, 0) : '',
							velocity_13_15: isValidNumber(velocity_13_15) ? _.round(velocity_13_15, 0) : '',
							velocity_15_20: isValidNumber(velocity_15_20) ? _.round(velocity_15_20, 0) : '',
							velocity_20_25: isValidNumber(velocity_20_25) ? _.round(velocity_20_25, 0) : '',
							velocity__25: isValidNumber(velocity__25) ? _.round(velocity__25, 0) : '',
							velocity__15: isValidNumber(velocity__15) ? _.round(velocity__15, 0) : '',
							velocity__20: isValidNumber(velocity__20) ? _.round(velocity__20, 0) : '',

							ss_velocity_0_8: isValidNumber(ss_velocity_0_8) ? _.round(ss_velocity_0_8, 0) : '',
							ss_velocity_8_13: isValidNumber(ss_velocity_8_13) ? _.round(ss_velocity_8_13, 0) : '',
							ss_velocity_13_15: isValidNumber(ss_velocity_13_15) ? _.round(ss_velocity_13_15, 0) : '',
							ss_velocity_15_20: isValidNumber(ss_velocity_15_20) ? _.round(ss_velocity_15_20, 0) : '',
							ss_velocity_20_25: isValidNumber(ss_velocity_20_25) ? _.round(ss_velocity_20_25, 0) : '',
							ss_velocity__25: isValidNumber(ss_velocity__25) ? _.round(ss_velocity__25, 0) : '',
							ss_velocity__15: isValidNumber(ss_velocity__15) ? _.round(ss_velocity__15, 0) : '',
							ss_velocity__20: isValidNumber(ss_velocity__20) ? _.round(ss_velocity__20, 0) : '',

							sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : '',
							velocity__15_p: isValidNumber(velocity__15_p) ? _.round(velocity__15_p, 1) : '',

							vel_max_perc_0_60: isValidNumber(vel_max_perc_0_60) ? _.round(vel_max_perc_0_60, 0) : '',
							vel_max_perc_60_70: isValidNumber(vel_max_perc_60_70) ? _.round(vel_max_perc_60_70, 0) : '',
							vel_max_perc_70_80: isValidNumber(vel_max_perc_70_80) ? _.round(vel_max_perc_70_80, 0) : '',
							vel_max_perc_80_90: isValidNumber(vel_max_perc_80_90) ? _.round(vel_max_perc_80_90, 0) : '',
							vel_max_perc_90_100: isValidNumber(vel_max_perc_90_100) ? _.round(vel_max_perc_90_100, 0) : '',
							vel_max_perc__100: isValidNumber(vel_max_perc__100) ? _.round(vel_max_perc__100, 0) : '',
							vel_max_perc__80: isValidNumber(vel_max_perc__80) ? _.round(vel_max_perc__80, 0) : '',
							vel_max_perc__90: isValidNumber(vel_max_perc__90) ? _.round(vel_max_perc__90, 0) : '',
							vel_max_perc__80_p: isValidNumber(vel_max_perc__80_p) ? _.round(vel_max_perc__80_p, 1) : '',

							alert_d: _.filter(_.map(dati, 'alert_d')).length > 0,
							alert_v: _.filter(_.map(dati, 'alert_v')).length > 0,
							alert_h: _.filter(_.map(dati, 'alert_h')).length > 0
						}
					}

					function buildVelocityByFase(fase, dati, ds = null) {

						var duration = buildDurationByFase(dati, ds);

						var total_distance = _.sumBy(dati, 'total_distance')/_.size(dati);
						var velocity_0_8 =_.sum([
							_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
							])/_.size(dati);
						var velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_distance')/_.size(dati);
						var velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_distance')/_.size(dati);
						var velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_distance')/_.size(dati);
						var velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_distance')/_.size(dati);
						var velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_distance')/_.size(dati);
						var velocity__15 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							])/_.size(dati);
						var velocity__20 = _.sum([
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							])/_.size(dati);
						var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count')/_.size(dati);
						var velocity__15_p = 100 * (_.sum([
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
							]) / _.sum([
							_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band4_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band5_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity_band8_total_distance')
							]));

						var velocity__15_p = 100 * _.sum(_.map(dati, function(a) { return _.sum([
							a.catapult_velocity_band6_total_distance,
							a.catapult_velocity_band7_total_distance,
							a.catapult_velocity_band8_total_distance,
							]) / _.sum([
							a.catapult_velocity_band1_total_distance,
							a.catapult_velocity_band2_total_distance,
							a.catapult_velocity_band3_total_distance,
							a.catapult_velocity_band4_total_distance,
							a.catapult_velocity_band5_total_distance,
							a.catapult_velocity_band6_total_distance,
							a.catapult_velocity_band7_total_distance,
							a.catapult_velocity_band8_total_distance
							]); }))/_.size(dati);


						var vel_max_perc_0_60 =_.sum([
							_.sumBy(dati, 'catapult_velocity2_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band3_total_distance'),
							])/_.size(dati);
						var vel_max_perc_60_70 = _.sumBy(dati, 'catapult_velocity2_band4_total_distance')/_.size(dati);
						var vel_max_perc_70_80 = _.sumBy(dati, 'catapult_velocity2_band5_total_distance')/_.size(dati);
						var vel_max_perc_80_90 = _.sumBy(dati, 'catapult_velocity2_band6_total_distance')/_.size(dati);
						var vel_max_perc_90_100 = _.sumBy(dati, 'catapult_velocity2_band7_total_distance')/_.size(dati);
						var vel_max_perc__100 = _.sumBy(dati, 'catapult_velocity2_band8_total_distance')/_.size(dati);
						var vel_max_perc__80 = _.sum([
							_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
							])/_.size(dati);
						var vel_max_perc__90 = _.sum([
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
							])/_.size(dati);
						var vel_max_perc__80_p = 100 * (_.sum([
							_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
							]) / _.sum([
							_.sumBy(dati, 'catapult_velocity2_band1_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band2_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band3_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band4_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band5_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
							_.sumBy(dati, 'catapult_velocity2_band8_total_distance')
							]));

						var vel_max_perc__80_p = 100 * _.sum(_.map(dati, function(a) { return _.sum([
							a.catapult_velocity2_band6_total_distance,
							a.catapult_velocity2_band7_total_distance,
							a.catapult_velocity2_band8_total_distance,
							]) / _.sum([
							a.catapult_velocity2_band1_total_distance,
							a.catapult_velocity2_band2_total_distance,
							a.catapult_velocity2_band3_total_distance,
							a.catapult_velocity2_band4_total_distance,
							a.catapult_velocity2_band5_total_distance,
							a.catapult_velocity2_band6_total_distance,
							a.catapult_velocity2_band7_total_distance,
							a.catapult_velocity2_band8_total_distance
							]); }))/_.size(dati);



						return {
							fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : '',
							velocity_0_8: isValidNumber(velocity_0_8) ? _.round(velocity_0_8, 0) : '',
							velocity_8_13: isValidNumber(velocity_8_13) ? _.round(velocity_8_13, 0) : '',
							velocity_13_15: isValidNumber(velocity_13_15) ? _.round(velocity_13_15, 0) : '',
							velocity_15_20: isValidNumber(velocity_15_20) ? _.round(velocity_15_20, 0) : '',
							velocity_20_25: isValidNumber(velocity_20_25) ? _.round(velocity_20_25, 0) : '',
							velocity__25: isValidNumber(velocity__25) ? _.round(velocity__25, 0) : '',
							velocity__15: isValidNumber(velocity__15) ? _.round(velocity__15, 0) : '',
							velocity__20: isValidNumber(velocity__20) ? _.round(velocity__20, 0) : '',
							sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : '',
							velocity__15_p: isValidNumber(velocity__15_p) ? _.round(velocity__15_p, 1) : '',

							vel_max_perc_0_60: isValidNumber(vel_max_perc_0_60) ? _.round(vel_max_perc_0_60, 0) : '',
							vel_max_perc_60_70: isValidNumber(vel_max_perc_60_70) ? _.round(vel_max_perc_60_70, 0) : '',
							vel_max_perc_70_80: isValidNumber(vel_max_perc_70_80) ? _.round(vel_max_perc_70_80, 0) : '',
							vel_max_perc_80_90: isValidNumber(vel_max_perc_80_90) ? _.round(vel_max_perc_80_90, 0) : '',
							vel_max_perc_90_100: isValidNumber(vel_max_perc_90_100) ? _.round(vel_max_perc_90_100, 0) : '',
							vel_max_perc__100: isValidNumber(vel_max_perc__100) ? _.round(vel_max_perc__100, 0) : '',
							vel_max_perc__80: isValidNumber(vel_max_perc__80) ? _.round(vel_max_perc__80, 0) : '',
							vel_max_perc__90: isValidNumber(vel_max_perc__90) ? _.round(vel_max_perc__90, 0) : '',
							vel_max_perc__80_p: isValidNumber(vel_max_perc__80_p) ? _.round(vel_max_perc__80_p, 1) : '',
						}
					}

					function buildHeartRate(persona, dati, ds = null, date = null) {

						var duration = buildDuration(dati, ds);

						var ruolo = _.find(persona.last_carriera_sportiva.ruoli, function(a) {
							return a.pivot.ideale == true || a.pivot.ideale == 1;
						});

						if(!ruolo && persona.last_carriera_sportiva.ruoli.length > 0)
							ruolo = persona.last_carriera_sportiva.ruoli[0];
						else if(!ruolo)
							ruolo = { sigla_tools: ' ' };

						var find_max_hr = dati.find(item => item.firstbeat_max_hb_absolute);
						var max_hb_absolute = find_max_hr ? find_max_hr.firstbeat_max_hb_absolute :  null;

						var avg_hr = _.sum(_.filter(_.map(dati, 'firstbeat_avg_hb')))/_.filter(_.map(dati, 'firstbeat_avg_hb')).length;
						var avg_hr_p = 100 * (_.sum(_.filter(_.map(dati, 'firstbeat_avg_hb')))/_.filter(_.map(dati, 'firstbeat_avg_hb')).length/max_hb_absolute);
						var max_hr = _.maxBy(dati, 'firstbeat_max_hb') ? _.maxBy(dati, 'firstbeat_max_hb').firstbeat_max_hb : null;
						var max_hr_p = 100 * (_.maxBy(dati, 'firstbeat_max_hb') ? _.maxBy(dati, 'firstbeat_max_hb').firstbeat_max_hb/max_hb_absolute : null);
						var hr_0_55 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_55')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
						var hr_55_65 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_55_65')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
						var hr_65_75 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_65_75')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
						var hr_75_85 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_75_85')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
						var hr_85_95 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_85_95')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
						var hr_95 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_95')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
						var hr_85 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_85')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
						var hr_85_raw = _.sumBy(dati, 'firstbeat_heart_rate_85')/12;
						var edward = _.sum([
							_.sumBy(dati, 'firstbeat_heart_rate_50_60'),
							_.sumBy(dati, 'firstbeat_heart_rate_60_70') * 2,
							_.sumBy(dati, 'firstbeat_heart_rate_70_80') * 3,
							_.sumBy(dati, 'firstbeat_heart_rate_80_90') * 4,
							_.sumBy(dati, 'firstbeat_heart_rate_90') * 5,
							])/12;
						var sessione_tl = buildSessionTl(dati);

						var dati_data = _.uniq(_.map(dati, 'date'));
						var time = null;
						if(dati_data.length == 1)
							time = moment(_.min(_.map(dati, 'start_time')), 'X').format('HH:mm');
						
						dati_data = _.map(dati_data, function(p) {
							return moment(p).format('DD/MM/YYYY');
						});

						return {
							cognome: persona.cognome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							ruolo: ruolo.sigla_tools.split(',')[1],
							ruolo_order: parseInt(ruolo.sigla_tools.split(',')[0]),
							date: date || _.first(dati_data),
							time: time,
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							avg_hr: isValidNumber(avg_hr) ? _.round(avg_hr, 2) : '',
							avg_hr_p: isValidNumber(avg_hr_p) ? _.round(avg_hr_p, 1) : '',
							max_hr: isValidNumber(max_hr) ? _.round(max_hr, 0) : '',
							max_hr_p: isValidNumber(max_hr_p) ? _.round(max_hr_p, 0) : '',
							hr_0_55: isValidNumber(hr_0_55) ? _.round(hr_0_55, 2) : '',
							hr_55_65: isValidNumber(hr_55_65) ? _.round(hr_55_65, 2) : '',
							hr_65_75: isValidNumber(hr_65_75) ? _.round(hr_65_75, 2) : '',
							hr_75_85: isValidNumber(hr_75_85) ? _.round(hr_75_85, 2) : '',
							hr_85_95: isValidNumber(hr_85_95) ? _.round(hr_85_95, 2) : '',
							hr_95: isValidNumber(hr_95) ? _.round(hr_95, 2) : '',
							hr_85: isValidNumber(hr_85) ? _.round(hr_85, 2) : '',
							hr_85_raw: isValidNumber(hr_85_raw) ? _.round(hr_85_raw, 0) : '',
							edward: isValidNumber(edward) ? _.round(edward, 0) : '',
							sessione_tl: isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : '',
							alert_d: _.filter(_.map(dati, 'alert_d')).length > 0,
							alert_v: _.filter(_.map(dati, 'alert_v')).length > 0,
							alert_h: _.filter(_.map(dati, 'alert_h')).length > 0
						}
					}

					function buildHeartRateByFase(fase, dati, ds = null) {

						var duration = buildDurationByFase(dati, ds);

						var avg_hr = _.sum(_.map(dati, function(a) { return a.firstbeat_avg_hb; }))/_.size(dati);
						var avg_hr_p = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_max_hb_absolute ? a.firstbeat_avg_hb/a.firstbeat_max_hb_absolute : 0; }))/_.size(dati);
						var max_hr = _.sum(_.map(dati, function(a) { return a.firstbeat_max_hb ? a.firstbeat_max_hb : null; }))/_.size(dati);
						var max_hr_p = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_max_hb && a.firstbeat_max_hb_absolute ? a.firstbeat_max_hb/a.firstbeat_max_hb_absolute : null; }))/_.size(dati);
						var hr_0_55 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_55/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
						var hr_55_65 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_55_65/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
						var hr_65_75 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_65_75/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
						var hr_75_85 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_75_85/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
						var hr_85_95 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_85_95/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
						var hr_95 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_95/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
						var hr_85 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_85/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
						var hr_85_raw = _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_85/12; }))/_.size(dati);

						var edward = _.sum(_.map(dati, function(a) { 
							return _.sum([
								a.firstbeat_heart_rate_50_60, 
								a.firstbeat_heart_rate_60_70 * 2,
								a.firstbeat_heart_rate_70_80 * 3,
								a.firstbeat_heart_rate_80_90 * 4,
								a.firstbeat_heart_rate_90 * 5,
								])/12;}))/_.size(dati);
						var session_minutes = _.sum(_.map(dati, function(a) { return a.fase_durata; }))/_.size(dati);
						var rpe = _.sum(_.map(dati, function(a) { return a.fase_rpe; }))/_.size(dati);
						var sessione_tl = session_minutes * rpe;

						return {
							fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
							gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
							avg_hr: isValidNumber(avg_hr) ? _.round(avg_hr, 2) : '',
							avg_hr_p: isValidNumber(avg_hr_p) ? _.round(avg_hr_p, 1) : '',
							max_hr: isValidNumber(max_hr) ? _.round(max_hr, 0) : '',
							max_hr_p: isValidNumber(max_hr_p) ? _.round(max_hr_p, 0) : '',
							hr_0_55: isValidNumber(hr_0_55) ? _.round(hr_0_55, 2) : '',
							hr_55_65: isValidNumber(hr_55_65) ? _.round(hr_55_65, 2) : '',
							hr_65_75: isValidNumber(hr_65_75) ? _.round(hr_65_75, 2) : '',
							hr_75_85: isValidNumber(hr_75_85) ? _.round(hr_75_85, 2) : '',
							hr_85_95: isValidNumber(hr_85_95) ? _.round(hr_85_95, 2) : '',
							hr_95: isValidNumber(hr_95) ? _.round(hr_95, 2) : '',
							hr_85: isValidNumber(hr_85) ? _.round(hr_85, 2) : '',
							hr_85_raw: isValidNumber(hr_85_raw) ? _.round(hr_85_raw, 0) : '',
							edward: isValidNumber(edward) ? _.round(edward, 0) : '',
							sessione_tl: isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : '',
						}
					}

					function heading_of_hr() {

						return [{
							cognome: { name: "P" },
							ruolo: { name: "R" },
							gps_minutes: { name: "GPS Minutes" },
							avg_hr: { name: "AVG HR" },
							avg_hr_p: { name: "AVG HR", "t": "%" },
							max_hr: { name: "Max HR" },
							max_hr_p: { name: "MAX HR", "t": "%" },
							hr_0_55: { name: "HR 0-55", "t": "%" },
							hr_55_65: { name: "HR 55-65", "t": "%" },
							hr_65_75: { name: "HR 65-75", "t": "%" },
							hr_75_85: { name: "HR 75-85", "t": "%" },
							hr_85_95: { name: "HR 85-95", "t": "%" },
							hr_95: { name: "HR 95", "t": "%" },
							hr_85: { name: "HR 85", "t": "%" },
							edward: { name: "Edward" },
							sessione_tl: { name: "Session TL" }
						}]

					}

					function heading_of_drill() {

						return [
						{
							a: { name: '' },
							cinematico: { name: 'Cinematico', span: 4 },
							metabolico: { name: 'Metabolico', span: 2 },
							neuromuscolare: { name: 'Neuromuscolare', span: 4 },
							interno: { name: 'Interno', span: 4 },
						},
						{
							cognome: { name: "P" },
							ruolo: { name: "R" },
							gps_minutes: { name: "GPS Minutes" },
							total_distance: { name: "Tot. Dist." },
							relative_distance: { name: "D. Rel" },
							cc_dist_15: { name: "Dist > 15" },
							max_vel: { name: "Vel Max" },
							mp: { name: "MP" },
							edi: { name: "EDI" },
							pow_ev: { name: "Pow. Ev." },
							num_acc: { name: "Acc" },
							num_dec: { name: "Dec" },
							sp_ev: { name: "Sp. Ev." },
							edward: { name: "Edwards TL" },
							session_minutes: { name: "Session Minutes" },
							rpe: { name: "RPE" },
							sessione_tl: { name: "Session TL" }
						}
						]

					}

					function isValidNumber(a) {
						if(!_.isNumber(a) || _.isNaN(a) || a == 0 || !_.isFinite(a)) return false;
						return true;
					}


					import axios from 'axios';
					import moment from 'moment'
					import { mapState } from 'vuex';
					import sessionMixin from '@/mixins/sessione.js';
					import * as types from '@/store/types';
					import csvDownload from 'json-to-csv-export';
					var Auth = require('@/auth.js');

					export default {
						props: [ 'collapsable', 'sessione', 'isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
						filters: {
							r: function(a, b) {
								if(!_.isNumber(a) || _.isNaN(a) || a == 0) return '-';
								return _.round(a, b);
							},
							date: function(e, format) {
								return moment(e).format(format || 'DD/MM/YYYY')
							}
						},
						components: {
							ToolsTimeline: require('@/components/layout/Sessioni/Generica/Tools_Timeline.vue').default,
							ToolsVelocityChart: require('@/components/layout/Sessioni/Generica/Tools_Velocity_Chart.vue').default,
							ToolsVelocity2Chart: require('@/components/layout/Sessioni/Generica/Tools_Velocity2_Chart.vue').default,
							ToolsNeuromChart: require('@/components/layout/Sessioni/Generica/Tools_Neurom_Chart.vue').default,
							ToolsChart: require('@/components/layout/Sessioni/Generica/Tools_Chart.vue').default
						},
						computed: {
							auth: function() {
								return Auth;
							},
							theMediaDataset: function () {
								var dati = _.groupBy(this.theResultsNoPeople, 'date');

								var ds = null;
								if(this.ux.allDataset)
									ds = this.results;

								var data = _.mapValues(dati, function(a) {

									var gp = _.groupBy(a, 'persona_id');

									var kpiBuilt = _.mapValues(gp, function(b) {
										var c = buildKpiValues(b, ds);
										return c;
									});


									var objToReturn = {};
									var keys = _.keys(_.first(_.values(kpiBuilt)));

									_.each(keys, function(key) {
										if(key !== 'week' && key !== 'date')
											objToReturn[key] = _.size(_.values(kpiBuilt).filter(item => item[key] != 0)) > 0 ? _.round(_.sumBy(_.values(kpiBuilt), key) / _.size(_.values(kpiBuilt).filter(item => item[key] != 0)), 1) : 0;
										else {
											objToReturn[key] = _.values(kpiBuilt)[0][key];
										}
									});
									return objToReturn;
								});


								var keys = _.keys(_.values(data)[0]);
								var medie = {};
								_.each(keys, function(k) {
									medie[k] = _.size(_.map(data).filter(item => item[k] != 0)) > 0 ? _.round(_.sum(_.map(data, k))/_.size(_.map(data).filter(item => item[k] != 0))) : 0;
								});
								return medie;
							},
							theMediaDatasetWPeople: function () {
								var dati = _.groupBy(this.theResults, 'date');

								var ds = null;
								if(this.ux.allDataset)
									ds = this.results;

								var data = _.mapValues(dati, function(a) {

									var gp = _.groupBy(a, 'persona_id');

									var kpiBuilt = _.mapValues(gp, function(b) {
										var c = buildKpiValues(b, ds);
										return c;
									});


									var objToReturn = {};
									var keys = _.keys(_.first(_.values(kpiBuilt)));

									_.each(keys, function(key) {
										if(key !== 'week' && key !== 'date')
											objToReturn[key] = _.size(_.values(kpiBuilt).filter(item => item[key] != 0)) > 0 ? _.round(_.sumBy(_.values(kpiBuilt), key) / _.size(_.values(kpiBuilt).filter(item => item[key] != 0)), 1) : 0;
										else {
											objToReturn[key] = _.values(kpiBuilt)[0][key];
										}
									});
									return objToReturn;
								});


								var keys = _.keys(_.values(data)[0]);
								var medie = {};
								_.each(keys, function(k) {
									medie[k] = _.size(_.map(data).filter(item => item[k] != 0)) > 0 ? _.round(_.sum(_.map(data, k))/_.size(_.map(data).filter(item => item[k] != 0))) : 0;
								});
								return medie;
							},
							daysDataset: function() {
								if(this.results && this.results.raw_results) {
									return _.map(_.orderBy(_.keys(_.groupBy(this.results.raw_results, 'date')), function(a) {
										return moment(a).format('X')
									}), function(p) {
										var f = _.find(this.results.sessioni, function(a) {
					//	console.log(a.inizio.split(' ')[0], p);
											if(p != a.inizio.split(' ')[0]) return false;
											return 15 == a.sessione_tipo_glo_id;
										}.bind(this))
										return p + ( f ? ' (Match)' : '');
									}.bind(this));
								}
							},
							filteredDaysDataset: function() {

								if(this.results && this.results.raw_results) {
									var dati = this.results.raw_results;
									if(this.start_filter_from_player) {
										if(this.filters.sessioni.length > 0) {
											dati = _.filter(dati, function(a) {
												return this.filters.sessioni.includes(a.sessione_id);
											}.bind(this));
										}
										if(this.filters.fasi.length > 0) {
											dati = _.filter(dati, function(a) {
												return this.filters.fasi.includes(a.fase_originale_id);
											}.bind(this));
										}
										if(this.filters.players.length > 0) {
											dati = _.filter(dati, function(a) {
												return this.filters.players.includes(a.persona_id);
											}.bind(this));
										}
									}
									return _.map(_.orderBy(_.keys(_.groupBy(dati, 'date')), function(a) {
										return moment(a).format('X')
									}), function(p) {
										var f = _.find(this.results.sessioni, function(a) {
					//	console.log(a.inizio.split(' ')[0], p);
											if(p != a.inizio.split(' ')[0]) return false;
											return 15 == a.sessione_tipo_glo_id;
										}.bind(this))
										return p + ( f ? ' (Match)' : '');
									}.bind(this));
								}

							},

							daysOff: function() {

								var dates = { catapult: [], hr: [], rpe: [], durata: [], tqr: []};
								var startDate = moment(this.dateFrom);
								var endDate = moment(this.dateTo);
								var diff = endDate.diff(startDate, 'days');

								if(!startDate.isValid() || !endDate.isValid() || diff < 0) {
									return;
								}
								for(var i = 0; i <= diff; i++) {
									dates['catapult'].push(startDate.format('YYYY-MM-DD'));
									dates['hr'].push(startDate.format('YYYY-MM-DD'));
									dates['rpe'].push(startDate.format('YYYY-MM-DD'));
									dates['durata'].push(startDate.format('YYYY-MM-DD'));
									dates['tqr'].push(startDate.format('YYYY-MM-DD'));
									startDate = startDate.add(1, 'd');
								}

								if(this.results && this.results.raw_results) {
									
									var dati = this.results.raw_results;

									if(this.filters.players.length > 0) {
										dati = _.filter(dati, function(a) {
											return this.filters.players.includes(a.persona_id);
										}.bind(this));
									}

									var  dati_catapult = _.filter(dati, function(a) {
										return a.total_distance;
									});
									var dateWithTools = _.uniq(_.map(dati_catapult, 'date'));
									dates['catapult'] = _.filter(dates['catapult'], function(a) {
										return !dateWithTools.includes(a);
									});
									dates['catapult'] = _.map(dates['catapult'], function (value) {
										var dayVal = this.matchCalendar[value];
										return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
									}.bind(this));

									var  dati_hr = _.filter(dati, function(a) {
										return a.firstbeat_heart_rate_check;
									});
									var dateWithTools = _.uniq(_.map(dati_hr, 'date'));
									dates['hr'] = _.filter(dates['hr'], function(a) {
										return !dateWithTools.includes(a);
									});
									dates['hr'] = _.map(dates['hr'], function (value) {
										var dayVal = this.matchCalendar[value];
										return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
									}.bind(this));

									var  dati_rpe = _.filter(dati, function(a) {
										return a.fase_rpe;
									});
									var dateWithTools = _.uniq(_.map(dati_rpe, 'date'));
									dates['rpe'] = _.filter(dates['rpe'], function(a) {
										return !dateWithTools.includes(a);
									});
									dates['rpe'] = _.map(dates['rpe'], function (value) {
										var dayVal = this.matchCalendar[value];
										return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
									}.bind(this));

									var  dati_durata = _.filter(dati, function(a) {
										return a.fase_durata;
									});
									var dateWithTools = _.uniq(_.map(dati_durata, 'date'));
									dates['durata'] = _.filter(dates['durata'], function(a) {
										return !dateWithTools.includes(a);
									});
									dates['durata'] = _.map(dates['durata'], function (value) {
										var dayVal = this.matchCalendar[value];
										return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
									}.bind(this));

									var  dati_tqr = _.filter(dati, function(a) {
										return a.fase_tqr;
									});
									var dateWithTools = _.uniq(_.map(dati_tqr, 'date'));
									dates['tqr'] = _.filter(dates['tqr'], function(a) {
										return !dateWithTools.includes(a);
									});
									dates['tqr'] = _.map(dates['tqr'], function (value) {
										var dayVal = this.matchCalendar[value];
										return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
									}.bind(this));
								}
								return dates;
							},

							daysOffByPerson: function() {
								var dates = { catapult: [], hr: [], rpe: [], durata: [], tqr: []};
								var startDate = moment(this.dateFrom);
								var endDate = moment(this.dateTo);
								var diff = endDate.diff(startDate, 'days');

								if(!startDate.isValid() || !endDate.isValid() || diff < 0) {
									return;
								}
								for(var i = 0; i <= diff; i++) {
									dates['catapult'].push(startDate.format('YYYY-MM-DD'));
									dates['hr'].push(startDate.format('YYYY-MM-DD'));
									dates['rpe'].push(startDate.format('YYYY-MM-DD'));
									dates['durata'].push(startDate.format('YYYY-MM-DD'));
									dates['tqr'].push(startDate.format('YYYY-MM-DD'));
									startDate = startDate.add(1, 'd');
								}

								if(this.results && this.results.raw_results) {
									
									var dati = this.results.raw_results;

									if(this.filters.players.length > 0) {
										dati = _.filter(dati, function(a) {
											return this.filters.players.includes(a.persona_id);
										}.bind(this));
									}

									var grouped_by_person = _.groupBy(dati, 'persona_id');

									var data_person = _.mapValues(grouped_by_person, function(dati_persona) {

										var dates_persona = _.clone(dates);

										var dati_catapult = _.filter(dati_persona, function(a) {
											return a.total_distance;
										});
										var dateWithTools = _.uniq(_.map(dati_catapult, 'date'));
										dates_persona['catapult'] = _.filter(dates_persona['catapult'], function(a) {
											return !dateWithTools.includes(a);
										});
										dates_persona['catapult'] = _.map(dates_persona['catapult'], function (value) {
											var dayVal = this.matchCalendar[value];
											return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
										}.bind(this));

										var dati_hr = _.filter(dati_persona, function(a) {
											return a.firstbeat_heart_rate_check;
										});
										var dateWithTools = _.uniq(_.map(dati_hr, 'date'));
										dates_persona['hr'] = _.filter(dates_persona['hr'], function(a) {
											return !dateWithTools.includes(a);
										});
										dates_persona['hr'] = _.map(dates_persona['hr'], function (value) {
											var dayVal = this.matchCalendar[value];
											return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
										}.bind(this));

										var dati_rpe = _.filter(dati_persona, function(a) {
											return a.fase_rpe;
										});
										var dateWithTools = _.uniq(_.map(dati_rpe, 'date'));
										dates_persona['rpe'] = _.filter(dates_persona['rpe'], function(a) {
											return !dateWithTools.includes(a);
										});
										dates_persona['rpe'] = _.map(dates_persona['rpe'], function (value) {
											var dayVal = this.matchCalendar[value];
											return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
										}.bind(this));

										var dati_durata = _.filter(dati_persona, function(a) {
											return a.fase_durata;
										});
										var dateWithTools = _.uniq(_.map(dati_durata, 'date'));
										dates_persona['durata'] = _.filter(dates_persona['durata'], function(a) {
											return !dateWithTools.includes(a);
										});
										dates_persona['durata'] = _.map(dates_persona['durata'], function (value) {
											var dayVal = this.matchCalendar[value];
											return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
										}.bind(this));

										var dati_tqr = _.filter(dati_persona, function(a) {
											return a.fase_tqr;
										});
										var dateWithTools = _.uniq(_.map(dati_tqr, 'date'));
										dates_persona['tqr'] = _.filter(dates_persona['tqr'], function(a) {
											return !dateWithTools.includes(a);
										});
										dates_persona['tqr'] = _.map(dates_persona['tqr'], function (value) {
											var dayVal = this.matchCalendar[value];
											return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
										}.bind(this));

										return dates_persona;
									}.bind(this));
									return data_person;
								}
								return dates;
							},

							daysOffByPersonRaw: function() {
								var dates = { catapult: [], hr: [], rpe: [], durata: [], tqr: []};
								var startDate = moment(this.dateFrom);
								var endDate = moment(this.dateTo);
								var diff = endDate.diff(startDate, 'days');

								if(!startDate.isValid() || !endDate.isValid() || diff < 0) {
									return;
								}
								for(var i = 0; i <= diff; i++) {
									dates['catapult'].push(startDate.format('YYYY-MM-DD'));
									dates['hr'].push(startDate.format('YYYY-MM-DD'));
									dates['rpe'].push(startDate.format('YYYY-MM-DD'));
									dates['durata'].push(startDate.format('YYYY-MM-DD'));
									dates['tqr'].push(startDate.format('YYYY-MM-DD'));
									startDate = startDate.add(1, 'd');
								}

								if(this.results && this.results.raw_results) {
									
									var dati = this.results.raw_results;

									if(this.filters.players.length > 0) {
										dati = _.filter(dati, function(a) {
											return this.filters.players.includes(a.persona_id);
										}.bind(this));
									}

									var grouped_by_person = _.groupBy(dati, 'persona_id');

									var data_person = _.mapValues(grouped_by_person, function(dati_persona) {

										var dates_persona = _.clone(dates);

										var dati_catapult = _.filter(dati_persona, function(a) {
											return a.total_distance;
										});
										var dateWithTools = _.uniq(_.map(dati_catapult, 'date'));
										dates_persona['catapult'] = _.filter(dates_persona['catapult'], function(a) {
											return !dateWithTools.includes(a);
										});

										var dati_hr = _.filter(dati_persona, function(a) {
											return a.firstbeat_heart_rate_check;
										});
										var dateWithTools = _.uniq(_.map(dati_hr, 'date'));
										dates_persona['hr'] = _.filter(dates_persona['hr'], function(a) {
											return !dateWithTools.includes(a);
										});

										var dati_rpe = _.filter(dati_persona, function(a) {
											return a.fase_rpe;
										});
										var dateWithTools = _.uniq(_.map(dati_rpe, 'date'));
										dates_persona['rpe'] = _.filter(dates_persona['rpe'], function(a) {
											return !dateWithTools.includes(a);
										});

										var dati_durata = _.filter(dati_persona, function(a) {
											return a.fase_durata;
										});
										var dateWithTools = _.uniq(_.map(dati_durata, 'date'));
										dates_persona['durata'] = _.filter(dates_persona['durata'], function(a) {
											return !dateWithTools.includes(a);
										});

										var dati_tqr = _.filter(dati_persona, function(a) {
											return a.fase_tqr;
										});
										var dateWithTools = _.uniq(_.map(dati_tqr, 'date'));
										dates_persona['tqr'] = _.filter(dates_persona['tqr'], function(a) {
											return !dateWithTools.includes(a);
										});
										return dates_persona;
									}.bind(this));
									return data_person;
								}
								return dates;
							},

							sessioni_catapult: function() {

								if(!this.results) return [];

								if(!this.start_filter_from_player) {

									var dataset_sessioni = _.orderBy(this.results.sessioni, function(a) {
										return moment(a.inizio).format('X');
									});


									if(this.filters.days.length == 0) return dataset_sessioni;

									return _.filter(dataset_sessioni, function(a) {

										return this.filters.days.indexOf( moment(a.inizio).format('YYYY-MM-DD') ) !== -1;

									}.bind(this));
								} else {

									var dataset_sessioni = _.orderBy(this.results.sessioni, function(a) {
										return moment(a.inizio).format('X');
									});

									if(this.filters.players.length == 0) return dataset_sessioni;

									var sessione_ids = _.uniq(_.map(_.filter(this.results.raw_results, function(a) {
										return this.filters.players.includes(a.persona_id);
									}.bind(this)), function(b) {
										return b.sessione_id;
									}));

									return _.filter(dataset_sessioni, function(a) {
										return sessione_ids.indexOf(a.id) !== -1;
									}.bind(this));

								}

							},
							players_catapult: function() {

								if(!this.results) return [];

								var ds = this.results.raw_results;

								if(!this.start_filter_from_player) {

									if(this.filters.sessioni.length > 0) {
										ds = _.filter(ds, function(a) {
											if(this.filters.sessioni.indexOf(a.sessione_id) !== -1) return true;
											return false;
										}.bind(this));
									}

									if(this.filters.fasi.length > 0) {
										ds = _.filter(ds, function(a) {
											if(this.filters.fasi.indexOf(a.fase_originale_id) !== -1) return true;
											return false;
										}.bind(this));
									}

									if(this.filters.days.length > 0) {
										ds = _.filter(ds, function(a) {
											if(this.filters.days.indexOf(a.date) !== -1) return true;
											return false;
										}.bind(this));
									}
								}

								var persona_ids = _.uniq(_.map(ds, 'persona_id'));

								return _.orderBy(this.results.raw_persone.filter(item => persona_ids.includes(item.id)), function(a) {
									return a.cognome;
								}.bind(this));
							},
							fasi_catapult: function() {

								if(!this.results)
									return [];

								var ds = this.results.raw_results;
								if(this.filters.sessioni.length > 0) {
									ds = _.filter(ds, function(a) {
										if(this.filters.sessioni.indexOf(a.sessione_id) !== -1) return true;
										return false;
									}.bind(this));
								}

								if(!this.start_filter_from_player && this.filters.days.length > 0) {
									ds = _.filter(ds, function(a) {
										if(this.filters.days.indexOf(a.date) !== -1) return true;
										return false;
									}.bind(this));
								}

								if(this.start_filter_from_player && this.filters.players.length > 0) {
									ds = _.filter(ds, function(a) {
										if(this.filters.players.includes(a.persona_id)) return true;
										return false;
									}.bind(this));
								}

								return _.filter(this.results.fasi, function(a) {
									return _.find(ds, { fase_originale_id: a.id });
								}.bind(this));

								var es = [];
								_.map(this.results.fasi, function(a) {

									var already = _.find(es, { id: a.oggetto.exercise_id });
									if(!already) {
										var already = {
											id: a.oggetto.exercise_id,
											fasi: [],
											esercizioNome: []
										};
										es.push(already);
									}

									already.fasi.push(a.id);
									already.esercizioNome.push(a.oggetto);

								});

								return es;

							},
							theResultsNoPeople: function() {

								if(!this.results)
									return [];

								var ds = this.results.raw_results;

								if(this.filters.sessioni.length > 0) {
									ds = _.filter(ds, function(a) {
										if(this.filters.sessioni.indexOf(a.sessione_id) !== -1) return true;
										return false;
									}.bind(this));
								}

								if(this.filters.fasi.length > 0) {
									ds = _.filter(ds, function(a) {
										if(this.filters.fasi.indexOf(a.fase_originale_id) !== -1) return true;
										return false;
									}.bind(this));
								}

								if(this.filters.days.length > 0) {
									ds = _.filter(ds, function(a) {
										if(this.filters.days.indexOf(a.date) !== -1) return true;
										return false;
									}.bind(this));
								}

								return ds;

							},
							theResults: function() {

								if(!this.results)
									return [];

								var ds = this.theResultsNoPeople;

								if(this.filters.players.length > 0) {
									ds = _.filter(ds, function(a) {
										if(this.filters.players.indexOf(a.persona_id) !== -1) return true;
										return false;
									}.bind(this));
								}

								return ds;
							},
							theInternaTable: function() {

								var tabs = _.filter(this.sessione.fasi, function(fase) {

									return fase.entity.entita == 'Fase_Dato_Durata' || fase.entity.entita == 'Fase_Dato_Rpe';

								});

								var tables = _.map(tabs, 'entity.risultati.table');
								var output = {};

								_.each(tables, function(single) {

									_.each(single, function(u, k) {

										if(!output[k]) {
											output[k] = {};
											console.log('New');
										} else 
											console.log('Old');

										_.each(u, function(uu, uk) {
											_.each(uu, function(uuu, uuk) {
												output[k][uuk] = uuu;
											})
										});

										output[k].tl = 0;
										if(output[k].durata && output[k].rpe) {
											output[k].tl = output[k].durata * output[k].rpe;
										}
										
										// console.log(k, this.sessione.persone, _.find(this.sessione.persone, { id: parseInt(k) }));

										output[k].persona = _.find(this.sessione.persone, { id: parseInt(k) });

									}.bind(this))

								}.bind(this));

								return _.orderBy(output, ['durata'], ['desc']);

							},
							theTables: function() {

								var dataset = this.theResults;

								var grouped = _.groupBy(dataset, 'persona_id');

								var table = _.map(grouped, function(dati, personaId) {

									var persona = this.results.raw_persone.find(p => p.id == personaId);

									var ds = null;

									if(this.ux.allDataset)
										ds = this.results;

									if(this.ux.splitByDay) {
										var grouped_by_date = _.groupBy(dati, 'date');

										var data_person = _.mapValues(grouped_by_date, function(dati_day, date) {
											return {
												persona_id: personaId,
												persona: persona,
												date: date,
												tables: {
													neurom: buildNeurom(persona, dati_day, ds, date),
													velocity: buildVelocity(persona, dati_day, ds, date),
													velocity2: buildVelocity(persona, dati_day, ds, date),
													drill: buildDrill(persona, dati_day, ds, date),
													hr: buildHeartRate(persona, dati_day, ds, date),
												},
												table: _.merge(buildNeurom(persona, dati_day, ds, date), buildVelocity(persona, dati_day, ds, date), buildDrill(persona, dati_day, ds, date), buildHeartRate(persona, dati_day, ds, date))
											};
										}.bind(this));

										if(this.ux.daysOffShow) {
											var daysOffByPerson = this.daysOffByPerson[personaId];
											var daysOff = _.uniq(daysOffByPerson['catapult'].concat(daysOffByPerson['hr'], daysOffByPerson['rpe'], daysOffByPerson['durata'], daysOffByPerson['tqr'])); 
											var keys = Object.keys(data_person);

											for(var i in daysOff) {
												var date = daysOff[i].split(' ')[0];
												if(!keys.includes(date)) {
													data_person[date] = {
														persona_id: personaId,
														persona: persona,
														date: date,
														day_off: true,
														tables: {
															neurom: buildNeurom(persona, [], ds, date),
															velocity: buildVelocity(persona, [], ds, date),
															velocity2: buildVelocity(persona, [], ds, date),
															drill: buildDrill(persona, [], ds, date),
															hr: buildHeartRate(persona, [], ds, date),
														},
														table: _.merge(buildNeurom(persona, [], ds, date), buildVelocity(persona, [], ds, date), buildDrill(persona, [], ds, date), buildHeartRate(persona, [], ds, date))
													};
												}

											}
										}

										data_person = _.orderBy(data_person, [function(a) {
											return moment(a.date).format('X');
										}], [ 'asc' ]);


										return data_person;

									} else {
										return {
											persona_id: personaId,
											persona: persona,
											data: 1,
											tables: {
												neurom: buildNeurom(persona, dati, ds),
												velocity: buildVelocity(persona, dati, ds),
												velocity2: buildVelocity(persona, dati, ds),
												drill: buildDrill(persona, dati, ds),
												hr: buildHeartRate(persona, dati, ds),
											},
											table: _.merge(buildNeurom(persona, dati, ds), buildVelocity(persona, dati, ds), buildDrill(persona, dati, ds), buildHeartRate(persona, dati, ds))
										};
									}
								}.bind(this));

								if(this.ux.splitByDay) {
									table = _.flatten(_.map(table));
								}

								if(this.ux.table == 'chart') {
									return table;
								}

								table = _.orderBy(table, function(a) {
									return a.persona.cognome.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
								});

								return _.orderBy(table, function(a) {
									var value = a.tables[this.ux.table][this.orderings[this.ux.table].key];
									if(value == '-') {
										value = 0;
									}
									return value;
								}.bind(this), [ this.orderings[this.ux.table].order == -1 ? 'asc' : 'desc' ]);

							},
							theTablesByFase: function() {

								var dataset = this.theResults.filter(item => !item.is_sessione);

								var grouped = _.groupBy(dataset, 'fase_originale_id');

								var table = _.map(grouped, function(dati, faseId) {

									var ds = null;

									if(this.ux.allDataset)
										ds = this.results;

									return {
										fase_id: faseId,
										fase: this.results.fasi.find(item => item.id == faseId),
										tables: {
											neurom_by_fase: buildNeuromByFase(this.results.fasi.find(item => item.id == faseId), dati, ds),
											velocity_by_fase: buildVelocityByFase(this.results.fasi.find(item => item.id == faseId), dati, ds),
											velocity2_by_fase: buildVelocityByFase(this.results.fasi.find(item => item.id == faseId), dati, ds),
											drill_by_fase: buildDrillByFase(this.results.fasi.find(item => item.id == faseId), dati, ds),
											hr_by_fase: buildHeartRateByFase(this.results.fasi.find(item => item.id == faseId), dati, ds),
										}
									};

								}.bind(this));

								table = _.orderBy(table, function(a) {
									return a.fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
								});

								return _.orderBy(table, function(a) {
									var value = a.tables[this.ux.table][this.orderings[this.ux.table].key];
									if(value == '-') {
										value = 0;
									}
									return value;
								}.bind(this), [ this.orderings[this.ux.table].order == -1 ? 'asc' : 'desc' ]);
							},
							isAlreadyImported: function() {
								if(this.results) return true;
								if(!this.sessione || !this.sessione.forms || !this.sessione.forms.fasi_form ) return null;
								return _.filter(this.sessione.forms.fasi_form, function(i) { return i.entity.entita == 'Fase_Dato_Tools' }).length;
							},
							params: function() {

								var otherCategory = this.ux.otherCategory;
								var category_id = this.categoriaId;
								if(this.sessione)
									category_id = this.sessione.categoria_id;

								var dateFrom = null;
								var dateTo = null;
								if(this.sessione) {
									dateFrom = this.sessione.inizio.split(' ')[0];
									dateTo = this.sessione.inizio.split(' ')[0];
								} else {
									dateFrom = this.dateFrom;
									dateTo = this.dateTo;
								}

								var sessioni = [];

								if(this.sessione)
									sessioni.push(this.sessione.id);

								return {
									category_id: category_id,
									dateFrom: dateFrom,
									dateTo: dateTo,
									sessione_id: sessioni,
									otherCategory: otherCategory,
									players: []
								}

							},
							...mapState(['categoriaId']),
						},
						watch: {
							'ux.otherCategory': function() {
								this.fetchResults();
							},
							'ux.allDataset': function() {
								this.filters.fasi = [];
							},
							dateFrom: function() {

								if(moment(this.dateTo).format('X') < moment(this.dateFrom).format('X'))
									this.dateTo = _.clone(this.dateFrom);

							},
							dateTo: function() {

								if(moment(this.dateTo).format('X') < moment(this.dateFrom).format('X'))
									this.dateFrom = _.clone(this.dateTo);

							},
							categoriaId() {
								this.fetchResults();
							},
							sessione: {
			// deep: true,
								immediate: true,
								handler: function(a,b) {
									if(this.sessione && this.isAlreadyImported)
										this.fetchResults();
								}
							},
							'ux.table': function () {
								if(this.isWidget) {
									this.$emit('updateConfigWidget', this.isWidget, 'activeTab', this.ux.table);
								}	
							},
							'refresh': {
								immediate: true,
								handler: function() {
									if(this.refresh && this.refresh > 0) {
										_.delay(this.refreshData, this.refresh * 60000);
									}
								}
							},
							fasi_catapult() {
								if(this.fasi_catapult.length == 0) {
									this.filters.fasi = [];
								}
							},
							'filters': {
								deep: true,
								handler: function() {
									if(this.filters.days.length == 0 && this.filters.sessioni.length == 0 && this.filters.fasi.length == 0 && this.filters.players.length > 0) {
										this.start_filter_from_player = true;
									} else if(this.filters.players.length == 0 && (this.filters.days.length > 0 || this.filters.sessioni.length > 0 || this.filters.fasi.length > 0)) {
										this.start_filter_from_player = false;
									}
								}
							},
							'ux.showGeneralChart': function() {
								if(this.ux.showGeneralChart && this.dateFrom == this.dateTo) {
									this.filters.players = _.map(this.players_catapult, 'id');
								}
							},
						},
						data: function(){
							return {
								dateFrom: null,
								dateTo: null,
								ux: {
									waiting: false,
									collapsed: this.collapsable ? true : false,
									table: this.configWidget && this.configWidget.activeTab ? this.configWidget.activeTab : 'drill',
									allDataset: false,
									otherCategory: false,
									tab_sel: [],
									showVelocityChart: false,
									showNeuromChart: false,
									showGeneralChart: false,
									showVelocity2Tab: false,
									showVelocity2ByExeTab: false,
									splitByDay: false,
									daysOffShow: false
								},
								filters: {
									days: [],
									sessioni: [],
									players: [],
									fasi: [],
								},
								orderings: {
									interno: { key: 'durata', order: -1 },
									neurom: { key: 'cognome', order: -1 },
									velocity: { key: 'cognome', order: -1 },
									velocity2: { key: 'cognome', order: -1 },
									drill: { key: 'cognome', order: -1 },
									hr: { key: 'cognome', order: -1 },
									neurom_by_fase: { key: 'fase', order: -1 },
									velocity_by_fase: { key: 'fase', order: -1 },
									velocity2_by_fase: { key: 'fase', order: -1 },
									drill_by_fase: { key: 'fase', order: -1 },
									hr_by_fase: { key: 'fase', order: -1 },
								},
								results: null,
								waiting: false,
								start_filter_from_player: false,
								matchCalendar: null,
								daysOffKpi: {
									catapult: ['gps_minutes', 'total_distance', 'relative_distance', 'cc_dist_15', 'max_vel', 'mp', 'edi', 'pow_ev', 'num_acc', 'num_dec', 'sp_ev', 'velocity_0_8', 'velocity_8_13', 'velocity_13_15', 'velocity_15_20', 'velocity_20_25', 'velocity__25', 'velocity__15', 'velocity__20', 'velocity__15_p',  'vel_max_perc_0_60', 'vel_max_perc_60_70', 'vel_max_perc_70_80', 'vel_max_perc_80_90', 'vel_max_perc_90_100', 'vel_max_perc__100', 'vel_max_perc__80', 'vel_max_perc__90', 'vel_max_perc__80_p', 'acc1', 'acc2', 'acc3', 'dec3', 'dec2', 'dec1'],
									hr: ['edward', 'avg_hr', 'avg_hr_p',  'max_hr', 'max_hr_p', 'hr_0_55', 'hr_55_65', 'hr_65_75', 'hr_75_85', 'hr_85_95', 'hr_95', 'hr_85', 'hr_85_raw'],
									rpe: ['rpe'],
									durata: ['session_minutes', 'sessione_tl'],
									tqr: ['tqr'],
								}
							}
						},
						created: function() {
							console.log(this.sessione);
							if(!this.sessione) {
								this.dateFrom = moment().format('YYYY-MM-DD');
								this.dateTo = moment().format('YYYY-MM-DD');
								this.fetchResults();
							} else {


								this.$root.$on('refresh-tools-notes', function() {
									this.fetchResults();
								}.bind(this));

								if(!this.sessione.setup) {
									this.sessione.setup = {};
								}

								if(!this.sessione.setup.note_tools)
									this.$set(this.sessione.setup, 'note_tools', '');

								console.log('Calling.');
								this.fetchResults();

							}
						},
						methods: {
							printToolsPlayerGraph: function() {

								var printUrl = 'https://api.asromasystem.com/tools/pdf/print-graph/' + Auth.user.id + "/" + Auth.user.api_token + '?id=' + this.sessione.id + '&cat=1';
								
								if(this.filters.players.length > 0)
									printUrl += '&players=' + this.filters.players.join(',');

								window.open(printUrl, "_blank");

							},
							selectSessions: function(id) {
								var selected = _.map(_.filter(this.sessioni_catapult, function(a) {
									return a.sessione_tipo_glo_id === id;
								}), 'id');
								this.filters.sessioni = _.uniq(_.union(this.filters.sessioni, selected));
							},
							meanOf: function(table, key) {

								return this.theMediaDatasetWPeople[key];

								var ds = _.map(_.map(this.theTables, function(a) {
									return a.tables[table];
								}), function(i) {
									return isValidNumber(i[key]) ? i[key] : 0;
								});

								if(ds.length)
									return _.round(_.sum(ds)/ds.length, 0);
								return ''
							},

							meanOfByDay: function(key) {
								return this.theMediaDataset[key];
							},

							sumOf: function(table, key) {
								var ds = _.map(_.map(this.theTables, function(a) {
									return a.tables[table];
								}), function(i) {
									return isValidNumber(i[key]) ? i[key] : 0;
								});
								return _.round(_.sum(ds), 0);	
							},

							standardDeviation: function(table, key) {

								var ds = _.map(_.map(this.theTables, function(a) {
									return a.tables[table];
								}), function(i) {
									return isValidNumber(i[key]) ? i[key] : 0;
								});

								if(!ds.length)
									return '';

								var avg = _.sum(ds) / ds.length;
								var ii = _.round(Math.sqrt(_.sum(_.map(ds, (i) => Math.pow((i - avg), 2))) / ds.length), 0);

								if(ii !== 0)
									return ii;
								return '';
							},

							meanOfByFase: function(table, key) {

								var ds = _.map(_.map(this.theTablesByFase, function(a) {
									return a.tables[table];
								}), function(i) {
									return isValidNumber(i[key]) ? i[key] : 0;
								});

								if(ds.length)
									return _.round(_.sum(ds)/ds.length, 0);
								return ''
							},

							sumOfByFase: function(table, key) {

								var ds = _.map(_.map(this.theTablesByFase, function(a) {
									return a.tables[table];
								}), function(i) {
									return isValidNumber(i[key]) ? i[key] : 0;
								});
								return _.round(_.sum(ds), 0);
							},

							standardDeviationByFase: function(table, key) {

								var ds = _.map(_.map(this.theTablesByFase, function(a) {
									return a.tables[table];
								}), function(i) {
									return isValidNumber(i[key]) ? i[key] : 0;
								});

								if(!ds.length)
									return '';

								var avg = _.sum(ds) / ds.length;
								var ii = _.round(Math.sqrt(_.sum(_.map(ds, (i) => Math.pow((i - avg), 2))) / ds.length), 0);

								if(ii !== 0)
									return ii;
								return '';
							},

							filteringFase: function(a) {
								this.filters.fasi = [a];
		//	console.log('Selected', a);
							},
							setOrdering: function(table, key) {
								if(this.orderings[table].key != key) {
									this.orderings[table].key = key;
									this.orderings[table].order = -1;
								} else {
									this.orderings[table].order *= -1;
								}
							},
							fetchResults: function() {
								this.ux.waiting = true;
								this.resetFilters();
								this.$http.post(this.$store.state.apiEndPoint + '/tools/table-lite', this.params).then(function(data) {
									this.ux.waiting = false;
									this.results = data.data;
								});
								this.$http.get(this.$store.state.apiEndPoint + '/tools/calendario/partite', {params: {categoria: this.params.category_id, from: this.params.dateFrom, to: this.params.dateTo }}).then(function(data) {
									this.matchCalendar = data.data;
								});
							},
							exportSummaryCsv: function() {
								var link = this.$store.state.apiEndPoint + '/tools/export/summary/csv';
								var i = 0;
								Object.keys(this.params).forEach(key => {
									if(this.params[key] != '') {
										if(i == 0) {
											link += '?';
										}
										if(i > 0) {
											link += '&';
										}
										link += key + '=' + this.params[key];
										i++;
									}
								});
								window.open(link, "_blank");
							},
							resetFilters: function() {
								this.filters.days = [];
								this.filters.sessioni = [];
								this.filters.fasi = [];
								this.filters.players = [];	
							},
							refreshData: function() {
								this.fetchResults();
								if(this.refresh && this.refresh > 0) {
									_.delay(this.refreshData, this.refresh * 60000);
								}
							},
							sendToMonitor: function(tab) {

								var b = false;
								var title = null;
								var rows = [];
								if(tab == 'drill') {
									title = 'DRILL Analysis';
								} else if(tab == 'velocity') {
									title = 'Velocity Analysis';
								} else if(tab == 'velocity2') {
									title = 'Velocity Analysis';
								} else if(tab == 'neurom') {
									title = 'Neurom';	
								} else if(tab == 'hr') {
									title = 'Heart Rate';
								}

								else if(tab == 'hr_by_fase') {
									title = 'Heart Rate by Exercise';
									b = true;
								}
								else if(tab == 'neurom_by_fase') {
									title = 'Neurom by Exercise';
									b = true;
								}
								else if(tab == 'velocity_by_fase') {
									title = 'Velocity Analysis by Exercise';
									b = true;
								}
								else if(tab == 'velocity2_by_fase') {
									title = 'Velocity Analysis by Exercise';
									b = true;
								}
								else if(tab == 'drill_by_fase') {
									title = 'Drill Analysis by Exercise';
									b = true;
								}

								if(!b) {
									this.theTables.forEach(item => {
										rows.push(item.tables[tab]);
									});
								} else {
									this.theTablesByFase.forEach(item => {
										rows.push(item.tables[tab]);
									});
								}

								var dataToConvert = {
									sessione_id: this.sessione ? this.sessione.id : null,
									filters: this.filters,
									orderings: this.orderings,
									allDataset: this.ux.allDataset,
									table: this.ux.tab_sel.length > 0 ? this.ux.tab_sel : [tab],
									dateFrom: this.dateFrom,
									dateTo: this.dateTo,
									category_id: this.categoriaId,
									otherCategory: this.ux.otherCategory
								}

								if(this.sessione) {

									if(moment(this.sessione.inizio).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') || confirm('La data dei tools è diversa dalla data di oggi, inviare ugualmente?') === true) {
										this.$http.post(this.$store.state.apiEndPoint + '/whiteboard/receive-tools/' + this.categoriaId, dataToConvert).then(function(a) {
											this.$store.commit(types.ALERTS_PUSH, { msg: 'Tools inviati al monitor!', status: 1 });
										});
									}

								} else {

									this.$http.post(this.$store.state.apiEndPoint + '/whiteboard/receive-tools/' + this.categoriaId, dataToConvert).then(function(a) {
										this.$store.commit(types.ALERTS_PUSH, { msg: 'Tools inviati al monitor!', status: 1 });
									});

								}


							},
							stampaTabella: function(tab) {
		//	console.log(tab);
								var b = false;
								var title = null;
								var rows = [];
								if(tab == 'drill') {
									title = 'DRILL Analysis';
								} else if(tab == 'velocity') {
									title = 'Velocity Analysis';
								} else if(tab == 'velocity2') {
									title = 'Velocity Analysis';
								} else if(tab == 'neurom') {
									title = 'Neurom';	
								} else if(tab == 'hr') {
									title = 'Heart Rate';
								} else if(tab == 'interno') {
									var c = true;
									title = 'Interno';
								}

								else if(tab == 'hr_by_fase') {
									title = 'Heart Rate by Exercise';
									b = true;
								}
								else if(tab == 'neurom_by_fase') {
									title = 'Neurom by Exercise';
									b = true;
								}
								else if(tab == 'velocity_by_fase') {
									title = 'Velocity Analysis by Exercise';
									b = true;
								}
								else if(tab == 'velocity2_by_fase') {
									title = 'Velocity Analysis by Exercise';
									b = true;
								}
								else if(tab == 'drill_by_fase') {
									title = 'Drill Analysis by Exercise';
									b = true;
								}

								if(!b && !c) {
									this.theTables.forEach(item => {
										rows.push(item.tables[tab]);
									});
								} else if(!c) {
									this.theTablesByFase.forEach(item => {
										rows.push(item.tables[tab]);
									});
								}

								var dataToConvert = {
									sessione_id: this.sessione ? this.sessione.id : null,
									filters: this.filters,
									orderings: this.orderings,
									allDataset: this.ux.allDataset,
									table: this.ux.tab_sel.length > 0 ? this.ux.tab_sel : [tab],
									dateFrom: this.dateFrom,
									dateTo: this.dateTo,
									category_id: this.categoriaId,
									otherCategory: this.ux.otherCategory
								}


								if(this.ux.splitByDay) {
									var printUrl = 'https://api.asromasystem.com/tools/pdf/print-people/' + Auth.user.id + "/" + Auth.user.api_token + '?table=' + encodeURI(btoa(JSON.stringify(dataToConvert)));
									console.log('http://localhost:8080/plain/monitor/#/tools-table-people?table=' + encodeURI(btoa(JSON.stringify(dataToConvert))));
								} else {
									var printUrl = 'https://api.asromasystem.com/tools/pdf/print/' + Auth.user.id + "/" + Auth.user.api_token + '?table=' + encodeURI(btoa(JSON.stringify(dataToConvert)));
									console.log('http://localhost:8080/plain/monitor/#/tools-table?table=' + encodeURI(btoa(JSON.stringify(dataToConvert))));
								}

								window.open(printUrl, "_blank");		

							},
							esportaCsv: function(tab) {
								var title = null;
								var rows = [];
								var table = null;
								if(tab == 'drill') {
									title = 'DRILL Analysis';
									table = this.theTables;
								} else if(tab == 'velocity') {
									title = 'Velocity Analysis';
									table = this.theTables;
								}  else if(tab == 'velocity2') {
									title = 'Velocity Analysis';
									table = this.theTables;
								} else if(tab == 'neurom') {
									title = 'Neurom';	
									table = this.theTables;
								} else if(tab == 'hr') {
									title = 'Heart Rate';
									table = this.theTables;		
								} if(tab == 'drill_by_fase') {
									title = 'DRILL Analysis by Exercise';
									table = this.theTablesByFase;
								} else if(tab == 'velocity_by_fase') {
									title = 'Velocity Analysis by Exercise';
									table = this.theTablesByFase;
								} else if(tab == 'velocity2_by_fase') {
									title = 'Velocity Analysis by Exercise';
									table = this.theTablesByFase;
								} else if(tab == 'neurom_by_fase') {
									title = 'Neurom by Exercise';
									table = this.theTablesByFase;	
								} else if(tab == 'hr_by_fase') {
									title = 'Heart Rate by Exercise';
									table = this.theTablesByFase;		
								}
								table.forEach(item => {
									rows.push(_.omit(item.tables[tab], ['ruolo_order']));
								});

								console.log('the-table', table);
								var dataToConvert = {
									data: rows,
									filename: _.camelCase(title),
									delimiter: ',',
									headers: _.keys(_.first(rows))
								}
								return csvDownload(dataToConvert);
							},
						}
					}

					</script>
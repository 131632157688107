<template>
	<div>
		<b-card no-body :class="'p-2 mb-1' + (hasVal(player.persona_id) ? ' border-dark' : '')" v-for="(player, index) in theFormazione" :bg-variant="index % 2 == 0 ? 'light' : 'default'">
			<b-row align-v="center">
				<b-col :cols="3">
					<b-row align-v="center">
						<b-col cols="2">
							<strong>{{ player.maglia }}</strong>
						</b-col>
						<b-col cols="3">
							<b-img :src="player.persona.avatar_url" :height="60"/>
						</b-col>
						<b-col>
							<router-link :to="{ name: 'PlayerPersonaDashboard', params: { persona_id: player.persona_id }}" target="_blank">
								<div>{{ player.persona.nome.charAt(0) + '. ' + player.persona.cognome }}</div>
							</router-link>
							<em>{{ player.ruolo ? player.ruolo.sigla : '' }}</em>
						</b-col>
					</b-row>
				</b-col>
				<b-col :cols="1" class="text-center">
					<div style="font-weight: bold;font-size:1.8rem" v-if="hasVal(player.persona_id)"><b-badge :style="'width:90%;background-color:' + getBgColor(getVotoValutazione(hasVal(player.persona_id)))" class="p-1">{{ getVotoValutazione(hasVal(player.persona_id)) }}</b-badge></div>
					<div v-else> - </div>
				</b-col>
				<b-col style="font-size:0.85rem;text-align: justify;">
					<div v-if="hasVal(player.persona_id)">
						{{ getGiudizioValutazione(hasVal(player.persona_id)) }}
					</div>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

export default {
	props: [ 'match', 'valutazioni', 'teamId', 'ruoli' ],
	methods: {
		hasVal: function(persona_id) {
			return this.valutazioni.find(item => item.persona_valutata.entita_id == persona_id);
		},
		getBgColor(value) {
			var color = '#FFFFFF';
			var valore = _.ceil(value);
			if(valore == 5) {
				color = '#46bdc6';
			} else if(valore == 4) {
				color = '#34a853';
			} else if(valore == 3) {
				color = '#ff6d01';
			} else if(valore == 2) {
				color = '#ea4336';
			} else if(valore == 1) {
				color = '#674ea7';
			}
			return color;
		},
		getVotoValutazione(val) {
			return val.evaluation.conclusion.total.value;
		},
		getGiudizioValutazione(val) {
			return val.evaluation.conclusion.total.note;
		},
		getRuolo: function(posizione_id) {
			return this.ruoli.find(item => item.id == posizione_id);
		}
	},
	computed: {
		theFormazione: function() {
			var formazione = this.match.persona_rel.map(obj => (
				{ ...obj,
				 ruolo: this.getRuolo(obj.posizione_id)}
			));
			var titolari = _.sortBy(formazione.filter(item => item.team_id == this.teamId && item.partita_persona_tipo_glo_id == 1), ['ruolo.sigla_tools']);
			var subInIds = this.match.eventi.filter(item => item.team_id == this.teamId && item.partita_evento_tipo_glo_id == 7 && item.minuto > 0).map(item => item.persona_id);
			var sostituzioni =  _.sortBy(formazione.filter(item => item.team_id == this.teamId && subInIds.includes(item.persona_id)), ['ruolo.sigla_tools']);
			var formazione = titolari.concat(sostituzioni);
			return formazione;
		}
	}

}

</script>
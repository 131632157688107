<template>
	<div :key="random">
		<b-card no-body class="px-1 py-0 mb-1" v-for="(player, index) in formazione" :bg-variant="index % 2 == 0 ? 'light' : 'default'" :border-variant="errors[index].length ? 'danger':''">
			<b-card-body>
				<b-row align-v="center" class="text-center">
					<b-col>
						<b-row class="text-left">
							<b-col>
								Cognome
								<b-input-group>
									<b-form-input type="text" v-model="player.persona.cognome" :readonly="player.persona.id != 0"></b-form-input>
									<b-input-group-append v-if="player.wyId == null">
										<b-button variant="primary" @click="searchPlayer(player, index, 1)" title="Cerca giocatore"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
									<b-input-group-append v-else>
										<b-button variant="warning" @click="unlinkPlayer(player)" title="Scollega Giocatore WY Scout"><i class="fa fa-times" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-col>
						</b-row>
						<b-row class="text-left">
							<b-col>
								Nome
								<b-form-input :readonly="player.persona.id != 0" type="text" v-model="player.persona.nome"></b-form-input>
							</b-col>
						</b-row>
					</b-col>
					<b-col>
						<b-row class="text-left">
							<b-col>
								Numero
								<b-form-input type="number" min="1" v-model="player.maglia"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="text-left">
							<b-col>
								Data di nascita
								<b-form-input :readonly="player.persona.id != 0" type="date" v-model="player.persona.nascita_data"></b-form-input>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="4" class="text-center pt-2">
						<div style="position: relative; justify-self: end;">
							<campo style="width: 100%;" />
							<div><div v-for="role in filtered_ruoli" :class="['pos-div', role.sigla, (player.posizione_id == role.id ? 'selected' : '')]" v-on:click="selectPosizione(player, role.id)">{{ role.sigla }}</div></div>
						</div>
					</b-col>
					<b-col cols="1">
						<b-button class="mb-1" variant="secondary" @click="removePlayer(player, index)"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
					</b-col>
				</b-row>
			</b-card-body>
			<template #footer v-if="errors[index].length">
				<span class="text-danger">Devi inserire: <strong>{{errors[index].join(', ')}}</strong></span>
			</template>
		</b-card>

		<div class="text-right">
			<b-button class="mb-1" variant="primary" @click="addPlayer()"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
		</div>


		<b-modal ref="searchPlayerModal" title="Ricerca giocatore su wyscout">
			<div v-if="modalSearchPlayer.results == null"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
			<p v-else-if="!modalSearchPlayer.results.length">Nessun giocatore trovato!</p>
			<div v-else>
				<p>Trovati {{modalSearchPlayer.results.length}} giocatori!, su wyscout</p>
				<hr/>
				<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
					<div v-for="(player, index) in modalSearchPlayer.results" :key="index" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaGiocatore(player)">
						<div class="col-md-3 my-1 mr-1" style="text-align: center;">
							<img v-if="player.imageDataURL" :src="player.imageDataURL"/>
						</div>
						<div class="col-md-6 my-1">
							<h5 style="display: inline;">{{ player.lastName }} {{ player.firstName }}</h5><br />
							<span class="text-muted" v-if="player.birthArea"><flag v-if="player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="24"></flag> {{ player.birthArea.name }} </span><br />
							<span class="text-muted">{{ player.birthDate | formatBirthDate }}</span><br />
							<span class="text-muted">{{ player.role.name }}</span><br />
							<span class="text-muted" v-if="player.currentTeam"><flag v-if="player.birthArea.alpha2code" :country="player.currentTeam.area.alpha2code" :size="24"></flag> {{ player.currentTeam.officialName }}</span><br />     
						</div>
						<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
							<img v-if="player.currentTeam" :src="player.currentTeam.imageDataURL" width="100" />
						</div>
					</div>
				</div>
			</div>
		</b-modal>


	</div>
</template>

<script>

	import pageBase from '@/mixins/pageBase.js'

	export default {
		mixins: [ pageBase ],
		data: function() {
			return {
				random: Math.random(),
				form: {},
				modalSearchPlayer: {
					show: false,
					results: null,
					player: null
				}
			}
		},
		components: {
			campo: 	require('@/components/layout/campi/orizzontale_white.vue').default,
		},
		props: [ 'matchId', 'teamId', 'ruoli', 'formazione' ],
		watch: {
			form: {
				deep: true,
				handler: function() {
					this.$emit('change');
				}
			}
		},
		methods: {
			selectPosizione(player, pos_id) {
				player.posizione_id = pos_id;
				this.random = Math.random();
			},
			unlinkPlayer: function(player) {

				player.wyId = null;
				player.foot = null;
				player.height = null;

			},
			selezionaGiocatore: function(player) {

			// this.modalSearchPlayer.player ...

				this.modalSearchPlayer.player.wyId = player.wyId;
				this.modalSearchPlayer.player.cognome = player.lastName;
				this.modalSearchPlayer.player.nome = player.firstName;
				this.modalSearchPlayer.player.shortName = player.shortName;
				this.modalSearchPlayer.player.height = player.height;
				this.modalSearchPlayer.player.foot = player.foot;
				this.modalSearchPlayer.player.nascita = player.birthDate;

				this.modalSearchPlayer = { player: null, results: null, show: false };

				this.$refs.searchPlayerModal.hide();

			},
			searchPlayer: function(player, index, home) {

			//console.log(player, index, home, 'ishome' + this.home);

				this.isHome = home;
				this.player_index = index;
				var query = player.cognome.trim().replace(/ /g, "_");

				if(query.length > 0) {

					if(player.nome && player.nome.trim() != "") {
						query = query + "_" + player.nome.trim().replace(/ /g, "_");
					}

					this.$refs.searchPlayerModal.show();

					this.modalSearchPlayer.show = true;
					this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then(function(data) {
						this.modalSearchPlayer.player = player;
						this.modalSearchPlayer.results = data.data;
					});
				}
			},
			addPlayer: function() {
				var player = {
					persona: {
						id: 0,
						cognome: null,
						nome: null,
						nascita_data: null,
					},
					maglia: null,
					partita_id: this.matchId,
					team_id: this.teamId,
					posizione_id: null,
					persona_id: null,
					partita_persona_tipo_glo_id: this.formazione.length < 11 ? 1 : 2,
				}
				this.formazione.push(player);
				this.random = Math.random();
			},
			removePlayer: function(player, index) {
				this.formazione.splice(index, 1);
				this.random = Math.random();
			},
			getRuolo: function(posizione_id) {
				return this.ruoli.find(item => item.id == posizione_id);
			}
		},
		computed: {
			errors: function() {
				console.log(this.random);
				var needed = {
					persona: {
						cognome: 'Cognome',
						nome: 'Nome',
						nascita_data: 'Data di nascita'
					},
					posizione_id: 'Ruolo'
				};

				var errors = {};

				_.each(this.formazione, function(player, i) {

					var fields = [];
					_.each(needed, function(c, b) {
						if(player[b] == undefined || player[b] == '')
							fields.push(c);
					})

					errors[i] = fields;

				});
				return errors;
			},
			filtered_ruoli() {
				return this.ruoli.filter(ruolo => ruolo.statsbomb_id);
			},
		}

	}

</script>
<style>
.pos-div {
	line-height: 0.8vw;
	height: 0.8vw;
	width: 0.8vw;
	font-size: 0.4vw;
	background-color: grey;
	color: #FFFFFF;
	border-radius: 50%;
	display: inline-block;
	font-weight: bold;
	text-align: center;
	position: absolute;
	cursor: pointer;

	&.selected { background-color: red; }

	&.PO { top: 45%; left: 6%; }

	&.DC { top: 45%; left: 20%; }
	&.DCD { top: 62%; left: 20%; }
	&.DCS { top: 28%; left: 20%; }
	&.TD { top: 77%; left: 20%; }
	&.TS { top: 12%; left: 20%; }

	&.EBD { top: 77%; left: 33%; }
	&.EBS { top: 12%; left: 33%; }
	&.CM { top: 45%; left: 33%; }
	&.CDD { top: 62%; left: 33%; }
	&.CDS { top: 28%; left: 33%; }

	&.CED { top: 77%; left: 47%; }
	&.CES { top: 12%; left: 47%; }
	&.CC { top: 45%; left: 47%; }
	&.CCD { top: 62%; left: 47%; }
	&.CCS { top: 28%; left: 47%; }

	&.ED { top: 77%; left: 62%; }
	&.ES { top: 12%; left: 62%; }
	&.TQC { top: 45%; left: 62%; }
	&.TQD { top: 62%; left: 62%; }
	&.TQS { top: 28%; left: 62%; }

	&.SP { top: 45%; left: 73%; }

	&.PC { top: 45%; left: 83%; }
	&.AD { top: 62%; left: 83%; }
	&.AS { top: 28%; left: 83%; }
}

</style>
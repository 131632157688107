import { mapState } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import * as types from '@/store/types';

export default {
	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		LazyImage: require('@/components/layout/images/LazyImage.vue').default,
	},
	methods: {
		alertPush: function(message, status) {
			this.$store.commit(types.ALERTS_PUSH, { msg: message, status: status });
		},	
		openBlankUrl(url) {			
			window.open(url,'_blank')
		},			
	},
	computed: {
		apiPath: function() {
			return this.$store.state.apiEndPoint;
		},
		moment: function() {
			return moment
		},
		_: function() {
			return _;
		},
		auth: function() {
			return require('@/auth.js');
		},
		types: function() {
			return types;
		},
		...mapState(['rosaTeamId', 'categoriaId']),
	},
	filters: {
		
	}
};
<template>
	<div v-if="!sessione || isAlreadyImported">
		<b-overlay :show="ux.waiting">
			<b-card v-if="!ux.collapsed" :no-body="isWidget ? true : false" :style="(isWidget ? 'border:none;' : '') + (contentHeight && isFullscreen ? 'max-height:' + contentHeight + 'px;overflow-y:auto;overflow-x:hidden;' : '')">
				<template #header v-if="!isWidget">
					<b-row>
						<b-col>
							Tracking Analysis
						</b-col>
						<b-col v-if="collapsable" class="text-right">
							<a href="javascript:;" @click.prevent="ux.collapsed = true">Chiudi</a>
						</b-col>
					</b-row>
				</template>

				<b-row class="mb-2" v-if="!isWidget || isFullscreen">
					<b-col v-if="!sessione">
						
						<b-form-group label="Data Inizio">
							<b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="dateFrom"></b-form-datepicker>
						</b-form-group>
						<b-form-group label="Data Fine">
							<b-form-datepicker size="sm" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="dateTo"></b-form-datepicker>
						</b-form-group>

						<div class="d-flex">
							<b-button @click.prevent="fetchResults">Cerca</b-button>
							<b-button size="sm" variant="outline-success" v-if="theResultsTable" @click.prevent="printTrackingPeriod">Stampa</b-button>
						</div>
						<div class="mt-1">
							<b-checkbox style="margin: 0;" type="checkbox" switch v-model="ux.wholeSessione"> Allenamento Completo</b-checkbox>
							<b-checkbox style="margin: 0;" type="checkbox" switch v-model="ux.showperc"> Modello Prestativo</b-checkbox>
						</div>

					</b-col>
					<b-col cols="3">
						<b-row>
							<b-col>
								Giorni
							</b-col>
							<b-col class="text-right">
								<a href="javascript:;" class="small text-muted" v-if="filters.days.length" @click.prevent="filters.days = []">Svuota</a>
							</b-col>
						</b-row>
						<b-form-select v-model="filters.days" multiple :select-size="7">
							<b-form-select-option v-for="day in daysDataset" :value="day">{{ day | date }} ({{daysDatasetFiltered[day]}})</b-form-select-option>
						</b-form-select>
						
						<b-row>
							<b-col><b-checkbox small v-model="ux.splitByDay" switch size="sm"> Dividi per giorno</b-checkbox></b-col>
							<b-col><b-checkbox small :disabled="!ux.splitByDay" v-model="ux.dayoff" switch size="sm"> Day off</b-checkbox></b-col>
						</b-row>
						
						
					</b-col>
					<b-col cols="3">

						<b-row>
							<b-col cols="10">
								<b-checkbox switch v-model="ux.otherCategory" :value="true"> <strong>Includi sessioni di altre categorie</strong></b-checkbox>
							</b-col>
							<b-col class="text-right">
								<a href="javascript:;" class="small text-muted" v-if="filters.sessioni.length" @click.prevent="filters.sessioni = []">Svuota</a>
							</b-col>
						</b-row>
						
						<b-form-select multiple :select-size="7" v-model="filters.sessioni">
							<b-form-select-option v-for="sess in sessioniDataset" :value="sess.id">{{ ux.otherCategory && sess.categoria_id != categoriaId ? sess.categoria.name + ' - ' : ''}} {{ sess.nome }} {{ sess.inizio | date('DD/MM/YYYY HH:mm') }} ({{sessioniDatasetFiltered[sess.id]}})</b-form-select-option>
						</b-form-select>

						<b-button-group class="mt-1">
							<b-button size="sm" variant="outline-secondary" @click.prevent="selectType([15])">Match</b-button>
							<b-button size="sm" variant="outline-secondary" @click.prevent="selectType([9, 10, 13])">Standard</b-button>
						</b-button-group>

					</b-col>
					<b-col cols="3">
						
						<b-row>
							<b-col>
								<b-col cols="6"><strong>Giocatori</strong></b-col>
							</b-col>
							<b-col class="text-right">
								<a href="javascript:;" class="small text-muted" v-if="filters.players.length" @click.prevent="filters.players = []">Svuota</a>
							</b-col>
						</b-row>

						<b-form-select multiple :select-size="9" v-model="filters.players">
							<b-form-select-option v-for="player in personeDataset" :value="player.id">{{ player.cognome }} ({{personeDatasetFiltered[player.id]}})</b-form-select-option>
						</b-form-select>

					</b-col>
				</b-row>

				<div v-if="results">

					<b-table-simple v-if="startingDataset.length > 0 && ux.splitByDay == false" small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
						<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
							<b-tr>
								<b-th width="16%"></b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Tempo</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Distanza</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D. Rel</div>
									<div class="text-center small text-muted" style="line-height: 1">metri/min</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>15km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>20km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D20-25km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>25km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Power Events</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">N ACC</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">N DEC</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Vel Max</div>
									<div class="text-center small text-muted" style="line-height: 1">km/h</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">%ED</div>
									<div class="text-center small text-muted" style="line-height: 1">%</div>
								</b-th>
								<b-th width="12%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1"></b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="item in startingDataset">
								<b-td v-html="item.match"></b-td>
								<b-td class="text-center">{{ item.tracking.total_duration | formatTime }}</b-td>
								<b-td class="text-center">{{ item.tracking.total_distance }}</b-td>
								<b-td class="text-center">{{ item.tracking.relative_distance }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity__15 }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity__20 }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity_20_25 }}</b-td>
								<b-td class="text-center">{{ item.tracking.velocity__25 }}</b-td>
								<b-td class="text-center">{{ item.tracking.pow_ev }}</b-td>
								<b-td class="text-center">{{ item.tracking.num_acc }}</b-td>
								<b-td class="text-center">{{ item.tracking.num_dec }}</b-td>
								<b-td class="text-center">{{ item.tracking.max_vel }}</b-td>
								<b-td class="text-center">{{ item.tracking.edi }}</b-td>
								<b-td colspan="2"></b-td>
							</b-tr>
						</b-tbody>
						<b-tfoot v-if="startingDataset.length >= 2">
							<tr>
								<td colspan="2"></td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.total_distance, startingDataset[startingDataset.length - 1].tracking.total_distance))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.total_distance, startingDataset[startingDataset.length - 1].tracking.total_distance) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.relative_distance, startingDataset[startingDataset.length - 1].tracking.relative_distance))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.relative_distance, startingDataset[startingDataset.length - 1].tracking.relative_distance) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.velocity__15, startingDataset[startingDataset.length - 1].tracking.velocity__15))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.velocity__15, startingDataset[startingDataset.length - 1].tracking.velocity__15) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.velocity__20, startingDataset[startingDataset.length - 1].tracking.velocity__20))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.velocity__20, startingDataset[startingDataset.length - 1].tracking.velocity__20) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.velocity_20_25, startingDataset[startingDataset.length - 1].tracking.velocity_20_25))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.velocity_20_25, startingDataset[startingDataset.length - 1].tracking.velocity_20_25) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.velocity__25, startingDataset[startingDataset.length - 1].tracking.velocity__25))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.velocity__25, startingDataset[startingDataset.length - 1].tracking.velocity__25) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.pow_ev, startingDataset[startingDataset.length - 1].tracking.pow_ev))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.pow_ev, startingDataset[startingDataset.length - 1].tracking.pow_ev) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.num_acc, startingDataset[startingDataset.length - 1].tracking.num_acc))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.num_acc, startingDataset[startingDataset.length - 1].tracking.num_acc) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.num_dec, startingDataset[startingDataset.length - 1].tracking.num_dec))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.num_dec, startingDataset[startingDataset.length - 1].tracking.num_dec) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.max_vel, startingDataset[startingDataset.length - 1].tracking.max_vel))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.max_vel, startingDataset[startingDataset.length - 1].tracking.max_vel) }} %</td>
								<td class="text-center" :style="'background-color:' + getBgRatio(ratio(startingDataset[startingDataset.length - 2].tracking.edi, startingDataset[startingDataset.length - 1].tracking.edi))">{{ ratio(startingDataset[startingDataset.length - 2].tracking.edi, startingDataset[startingDataset.length - 1].tracking.edi) }} %</td>
							</tr>
						</b-tfoot>
					</b-table-simple>

					<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
						<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
							<b-tr>
								<b-th width="16%"style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'persona.cognome' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('persona.cognome')">Player</a></b-th>
								<b-th v-if="ux.splitByDay" width="6%">Data</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'total_duration' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('total_duration')">Tempo</a></div>
								</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'total_distance' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('total_distance')">Distanza</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'relative_distance' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('relative_distance')">D. Rel</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri/min</div>
								</b-th>

								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__15' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__15')">D>15km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__20' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__20')">D>20km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity_20_25' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity_20_25')">D20-25km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__25' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__25')">D>25km/h</a></div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'pow_ev' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('pow_ev')">Power Events</a></div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'num_acc' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('num_acc')">N ACC</a></div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>
								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'num_dec' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('num_dec')">N DEC</a></div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'max_vel' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('max_vel')">Vel Max</a></div>
									<div class="text-center small text-muted" style="line-height: 1">km/h</div>
								</b-th>

								<b-th width="6%" :colspan="ux.showperc && !ux.splitByDay ? 2: 1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'edi' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('edi')">%ED</a></div>
									<div class="text-center small text-muted" style="line-height: 1">%</div>
								</b-th>
								<b-th width="6%" colspan="1">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'tl___' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('tl___')">Session</a></div>
									<div class="text-center small text-muted" style="line-height: 1">min</div>
								</b-th>
								<b-th :colspan="ux.showperc && !ux.splitByDay ? 2: 1" class="text-center" width="6%">
									<a href="javascript:;" :style="ux.orderBy == 'rpe' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('rpe')">RPE</a>
									<div class="text-center small text-muted" style="line-height: 1">ua</div>
								</b-th>
								<b-th :colspan="ux.showperc && !ux.splitByDay ? 2: 1" class="text-center" width="6%">
									<a href="javascript:;" :style="ux.orderBy == 'tl' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('tl')">TL</a>
									<div class="text-center small text-muted" style="line-height: 1">ua</div>
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="item in theResultsTable" v-if="item.tt.total_duration > 0" :variant="item.persona.team ? 'info' : ''">
								<b-td class="text-right">

									<span v-if="ux.splitByDay">
										<img style="width: 25px; height: 25px; display: inline-block; margin-left: -15px" v-for="a in item.tt.typeOfSession" :src="'/assets/sessioni/'+a+'.png'" />
									</span>
									<span style="text-transform: uppercase">{{ item.persona.cognome }}</span>

									<svg v-if="!ux.splitByDay && isComputed(item.persona.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 12px; height: 12px; margin-left: 3px">
									  <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
									</svg>

									<svg v-else-if="!ux.splitByDay && isVoid(item.persona.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" style="width: 12px; height: 12px; margin-left: 3px">
									  <path fill-rule="evenodd" d="M3.05 3.05a7 7 0 1 1 9.9 9.9 7 7 0 0 1-9.9-9.9Zm1.627.566 7.707 7.707a5.501 5.501 0 0 0-7.707-7.707Zm6.646 8.768L3.616 4.677a5.501 5.501 0 0 0 7.707 7.707Z" clip-rule="evenodd" />
									</svg>

								</b-td>
								<b-td v-if="ux.splitByDay">
									{{ item.tt.date | date }}
								</b-td>

								<b-td class="text-center">
									<span v-if="!ux.wholeSessione"><span v-if="item.tt.total_duration > 0.01">{{ item.tt.total_duration | formatTime }}</span><span v-else></span></span>
									<span v-else>{{ item.tt.tl___ | formatTime }}</span>
								</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('total_duration', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('total_duration', item.tt, item.persona.id) }}</b-td>

								<b-td class="text-center" :variant="isBest(item.tt.total_distance, 'total_distance') ? 'success' : (isWorst(item.tt.total_distance, 'total_distance') ? 'danger' : '')">{{ item.tt.total_distance }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('total_distance', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('total_distance', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.relative_distance, 'relative_distance') ? 'success' : (isWorst(item.tt.relative_distance, 'relative_distance') ? 'danger' : '')">{{ item.tt.relative_distance }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('relative_distance', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('relative_distance', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.velocity__15, 'velocity__15') ? 'success' : (isWorst(item.tt.velocity__15, 'velocity__15') ? 'danger' : '')">{{ item.tt.velocity__15 }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('velocity__15', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('velocity__15', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.velocity__20, 'velocity__20') ? 'success' : (isWorst(item.tt.velocity__20, 'velocity__20') ? 'danger' : '')">{{ item.tt.velocity__20 }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('velocity__20', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('velocity__20', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.velocity_20_25, 'velocity_20_25') ? 'success' : (isWorst(item.tt.velocity_20_25, 'velocity_20_25') ? 'danger' : '')">{{ item.tt.velocity_20_25 }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('velocity_20_25', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('velocity_20_25', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.velocity__25, 'velocity__25') ? 'success' : (isWorst(item.tt.velocity__25, 'velocity__25') ? 'danger' : '')">{{ item.tt.velocity__25 }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('velocity__25', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('velocity__25', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.pow_ev, 'pow_ev') ? 'success' : (isWorst(item.tt.pow_ev, 'pow_ev') ? 'danger' : '')">{{ item.tt.pow_ev }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('pow_ev', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('pow_ev', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.num_acc, 'num_acc') ? 'success' : (isWorst(item.tt.num_acc, 'num_acc') ? 'danger' : '')">{{ item.tt.num_acc }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('num_acc', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('num_acc', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.num_dec, 'num_dec') ? 'success' : (isWorst(item.tt.num_dec, 'num_dec') ? 'danger' : '')">{{ item.tt.num_dec }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('num_dec', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('num_dec', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.max_vel, 'max_vel') ? 'success' : (isWorst(item.tt.max_vel, 'max_vel') ? 'danger' : '')">{{ item.tt.max_vel }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('max_vel', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('max_vel', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.edi, 'edi') ? 'success' : (isWorst(item.tt.edi, 'edi') ? 'danger' : '')">{{ item.tt.edi }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('edi', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('edi', item.tt, item.persona.id) }}</b-td>
								<b-td class="text-center"><span v-if="item.tt.total_duration > 0.01">{{ item.tt.tl___ | formatTime }}</span></b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.rpe, 'rpe') ? 'success_' : (isWorst(item.tt.rpe, 'rpe') ? 'danger_' : '')">{{ item.tt.rpe }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('rpe', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('rpe', item.tt, item.persona.id) }}</b-td>
								
								<b-td class="text-center" :variant="isBest(item.tt.tl, 'tl') ? 'success_' : (isWorst(item.tt.tl, 'tl') ? 'danger_' : '')">{{ item.tt.tl }}</b-td>
								<b-td v-if="ux.showperc && !ux.splitByDay" :style="playerRatioStyle('tl', item.tt, item.persona.id)" class="text-center small">{{ playerRatio('tl', item.tt, item.persona.id) }}</b-td>
							</b-tr>
						</b-tbody>
						<b-tfoot>
						</b-tfoot>
					</b-table-simple>

				</div>
			</b-card>
		</b-overlay>
	</div>
</template>

<script>

var fields = [
	'total_duration',
	'total_distance',
	'relative_distance',
	'velocity__15',
	'velocity__20',
	'velocity_20_25',
	'velocity__25',
	'pow_ev',
	'num_acc',
	'num_dec',
	'max_vel',
	'edi',
	'rpe',
	'tl',
	'tl___'
];

function calculateMeans(dataset, isMatch) {

	var u = _.cloneDeep(dataset);
	//console.log('Was', _.map(dataset.tracking, 'persona_id'));

	var hasPortieri = _.map(_.pickBy(u.persone, function(c) {
		return _.get(c, 'last_carriera_sportiva.ruolo_ideale[0].sigla') === 'PO';
	}), 'id');

	var tracking = _.filter(u.tracking, function(p) {
		if(!p.total_duration || p.total_duration == 0) return false;
		return hasPortieri.indexOf(p.persona_id) === -1;
	});
	//console.log('Is',  _.size(tracking));
	u.tracking = calculateMeanOriginal(tracking, isMatch);
	return u;

}

function calculateMeanOriginal(dataset, isMatch) {

	if(isMatch)
		var amounts = 10;//_.size(dataset);
	else
		var amounts = _.size(dataset);

	var out = {};

	_.each(fields, function(u) {

		//console.log(dataset);
		var p = _.filter(_.map(dataset, u));

		//console.log('Amount of dataset: ' + u + ' ' + _.size(p) + ' - The divider for the view is: ' + amounts);

		if(u == 'max_vel' || u == 'edi')
			out[u] = _.round(_.sumBy(dataset, u)/_.size(p), (u == 'max_vel' || u == 'edi' ? 1 : 0));
		else if(u == 'rpe')
			out[u] = _.round(_.sumBy(dataset, u)/_.filter(dataset).length, 2);
		else
			out[u] = _.round(_.sumBy(dataset, u)/10, (u == 'max_vel' || u == 'edi' ? 1 : 0));

	});

	out.relative_distance = _.round(out.total_distance/(out.total_duration/60),0)

	return out;

}

function calculateMean(results, dataset, isMatch) {

	if(!results) return false;

	var u = _.cloneDeep(results);
	var hasPortieri = _.map(_.pickBy(u.persone, function(c) {
		return _.get(c, 'last_carriera_sportiva.ruoli[0].sigla') === 'PO';
	}), 'id');

	var tracking = _.filter(dataset, function(p) {
		return hasPortieri.indexOf(parseInt(p.persona_id)) === -1;
	});

	dataset = tracking;


	if(_.size(dataset) > 10 && isMatch)
		var amounts = 10;
	else
		var amounts = _.size(dataset);
	
	var out = {};


	_.each(fields, function(u) {

		var values = _.filter(_.map(dataset, 'tt.' + u));
		var p = _.filter(_.map(dataset, 'tt.' + u));


		if(u == 'max_vel' || u == 'edi' || u == 'tl' || u == 'tl___')
			out[u] = _.round(_.sumBy(dataset, 'tt.' + u)/_.size(p), (u == 'max_vel' || u == 'edi' ? 1 : 0));
		else if(u == 'rpe')
			out[u] = _.round(_.sumBy(dataset, 'tt.' + u)/_.size(p), 2);
		else if(u == 'total_duration')
			out[u] = _.round(_.sumBy(dataset, 'tt.' + u)/amounts, 2);
		else
			out[u] = _.round(_.sumBy(dataset, 'tt.' + u)/amounts, (u == 'max_vel' || u == 'edi' ? 1 : 0));

	});

	out.relative_distance = _.round(out.total_distance/(out.total_duration/60),0)

	return out;

}

	import toolsHelper from '@/tools_helper.js'

	import axios from 'axios';
	import moment from 'moment'
	import { mapState } from 'vuex';
	import sessionMixin from '@/mixins/sessione.js';
	import * as types from '@/store/types';
	import csvDownload from 'json-to-csv-export';
	var Auth = require('@/auth.js');

	export default {
		props: [ 'collapsable', 'sessione', 'isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
		filters: {
			r: function(a, b) {
				if(!_.isNumber(a) || _.isNaN(a) || a == 0) return '-';
				return _.round(a, b);
			},
			date: function(e, format) {
				return moment(e).format(format || 'DD/MM/YYYY')
			},
			formatTime: function(seconds) {

				var max = 60*60*24;

				if(seconds > max) {

					var hours = _.floor(seconds/(60*60));
					return hours + ':' + moment.utc(moment.duration(seconds,'seconds').as('milliseconds')).format('mm:ss');
				}

				return moment.utc(moment.duration(seconds,'seconds').as('milliseconds')).format('HH:mm:ss');
			}
		},
		components: {
			// ToolsChart: require('@/components/layout/Sessioni/Generica/Tools_Chart.vue').default
		},
		computed: {
			isDatasetOnlyMatches: function() {

				if(!this.results || this.results.sessioni.length == 0) return false;

				var ids = _.values(_.uniq(_.filter(_.map(this.theResults, '__sessione_id'))));
				var sessioniSelected = _.filter(this.results.sessioni, function(a) {
					return ids.indexOf(a.id) !== -1;
				});

				//console.log('Is that what im saying', ids, sessioniSelected);
				var datasetType = _.uniq(_.filter(_.map(sessioniSelected, 'sessione_tipo_glo_id')));

				if(datasetType.length == 1 && _.first(datasetType) == 15) {
					return true;
				} else 
					return false;

			},
			printString: function() {

				var base = _.cloneDeep(this.params);
				base.filters = _.cloneDeep(this.filters);

				var url = btoa(JSON.stringify(base));

				var printUrl = 'https://api.asromasystem.com/tools/pdf/print-period/' + Auth.user.id + "/" + Auth.user.api_token + '?table=' + url + ( this.ux.wholeSessione ? '&whole=1' : '' ) + ( this.ux.showperc == false ? '&hideperc=1' : '' ) + ( this.ux.splitByDay == true ? '&split=1' : '' ) + ( this.ux.dayoff == true ? '&dayoff=1' : '' );
				return printUrl;

			},
			startingDataset: function() {

				var o = [];

				var otherBlocks = _.map(this.results.matches_prev, function(a) {
					var c = _.cloneDeep(a);
					// console.log(a);
					// console.log('This');
					return calculateMeans(a, true);
				});

				_.each(_.reverse(otherBlocks), function(i) {
					o.push(i);
				});

				var mediaTeam = {
					match: 'Media Partite Precedenti',
					tracking: this.results.team_average
				};
				o.push(mediaTeam);

				var i = _.find(this.theResultsTable, function(p) { return p.persona.team == true });
				if(i && i.tt.total_duration) {
					var mediaTeam = {
						match: 'Media Comulativa',
						tracking: i.tt
					};
					o.push(mediaTeam);

				}


				return o;

				if(!this.results) return false;
				return this.results.matches_prev;
			},
			bests: function() {

				var ruoli = _.map(this.theResultsTable, 'persona.last_carriera_sportiva.ruolo_ideale[0].sigla');
				var realCurrent = _.filter(_.map(_.cloneDeep(this.theResultsTable), function(e, b) {
					if(ruoli[b] === 'PO') return false;
					if(e.persona && e.persona.team !== undefined) return false;
					if(e.tt.total_duration == 0 || e.tt.total_duration == null) return false;
					return e;
				}));

				var fields = _.keys(_.first(this.theResultsTable).tt);
				// console.log(fields);

				var a = {};
				_.each(fields, function(f) {
					a[f] = {
						worsts: _.chunk(_.orderBy(_.map(realCurrent, 'tt.' + f)), 3)[0],
						bests: _.chunk(_.reverse(_.orderBy(_.map(realCurrent, 'tt.' + f))), 3)[0],
					}
				}.bind(this));

				return a;

			},
			auth: function() {
				return Auth;
			},
			daysDatasetFiltered: function() {

				var days = _.cloneDeep(this.daysDataset);

				var u = {};
				_.each(days, function(p) {
					u[p] = _.size(_.groupBy(_.filter(this.theResults, function(a) {
						return a.date === p
					}), '__sessione_id'));
				}.bind(this));

				return u;

			},
			daysDataset: function() {

				if(!this.results) return false;

				return this.results.days;
				
			},
			sessioniDatasetFiltered: function() {

				if(!this.results) return false;

				var s = _.map(this.results.sessioni, 'id');
				// console.log('HHHHHHH', s);
				var o = {};

				_.each(s, function(a) {
					o[a] = _.size(_.groupBy(_.filter(this.theResults, { __sessione_id: a }), 'persona_id'));
				}.bind(this));

				// console.log('AAA', o);

				return o;

			},
			sessioniDataset: function() {

				if(!this.results) return false;

				var dataset_sessioni = _.orderBy(this.results.sessioni, function(a) {
					return moment(a.inizio).format('X');
				});

				if(this.filters.days.length == 0) return dataset_sessioni;

				return _.filter(dataset_sessioni, function(a) {

					return this.filters.days.indexOf( moment(a.inizio).format('YYYY-MM-DD') ) !== -1;

				}.bind(this));

			//	return this.results.sessioni;

			},
			personeDatasetFiltered: function() {

				if(!this.results) return false;

				var s = _.map(this.results.persone, 'id');
				var o = {};

				_.each(s, function(a) {
					o[a] = _.filter(this.theResults, { persona_id: a }).length > 0 ? 1 : 0;
				}.bind(this));

				// console.log('AAA', o);

				return o;


			},
			personeDataset: function() {

				if(!this.results) return false;

				return this.results.persone;

			},
			filteredDaysDataset: function() {

				if(this.results && this.results.raw_results) {
					var dati = this.results.raw_results;
					if(this.start_filter_from_player) {
						if(this.filters.sessioni.length > 0) {
							dati = _.filter(dati, function(a) {
								return this.filters.sessioni.includes(a.sessione_id);
							}.bind(this));
						}
						if(this.filters.fasi.length > 0) {
							dati = _.filter(dati, function(a) {
								return this.filters.fasi.includes(a.fase_originale_id);
							}.bind(this));
						}
						if(this.filters.players.length > 0) {
							dati = _.filter(dati, function(a) {
								return this.filters.players.includes(a.persona_id);
							}.bind(this));
						}
					}
					return _.map(_.orderBy(_.keys(_.groupBy(dati, 'date')), function(a) {
						return moment(a).format('X')
					}), function(p) {
						var f = _.find(this.results.sessioni, function(a) {
					//	console.log(a.inizio.split(' ')[0], p);
							if(p != a.inizio.split(' ')[0]) return false;
							return 15 == a.sessione_tipo_glo_id;
						}.bind(this))
						return p + ( f ? ' (Match)' : '');
					}.bind(this));
				}

			},

			daysOff: function() {

				var dates = { catapult: [], hr: [], rpe: [], durata: [], tqr: []};
				var startDate = moment(this.dateFrom);
				var endDate = moment(this.dateTo);
				var diff = endDate.diff(startDate, 'days');

				if(!startDate.isValid() || !endDate.isValid() || diff < 0) {
					return;
				}
				for(var i = 0; i <= diff; i++) {
					dates['catapult'].push(startDate.format('YYYY-MM-DD'));
					dates['hr'].push(startDate.format('YYYY-MM-DD'));
					dates['rpe'].push(startDate.format('YYYY-MM-DD'));
					dates['durata'].push(startDate.format('YYYY-MM-DD'));
					dates['tqr'].push(startDate.format('YYYY-MM-DD'));
					startDate = startDate.add(1, 'd');
				}

				if(this.results && this.results.raw_results) {

					var dati = this.results.raw_results;

					if(this.filters.players.length > 0) {
						dati = _.filter(dati, function(a) {
							return this.filters.players.includes(a.persona_id);
						}.bind(this));
					}

					var  dati_catapult = _.filter(dati, function(a) {
						return a.total_distance;
					});
					var dateWithTools = _.uniq(_.map(dati_catapult, 'date'));
					dates['catapult'] = _.filter(dates['catapult'], function(a) {
						return !dateWithTools.includes(a);
					});
					dates['catapult'] = _.map(dates['catapult'], function (value) {
						var dayVal = this.matchCalendar[value];
						return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
					}.bind(this));

					var  dati_hr = _.filter(dati, function(a) {
						return a.firstbeat_heart_rate_check;
					});
					var dateWithTools = _.uniq(_.map(dati_hr, 'date'));
					dates['hr'] = _.filter(dates['hr'], function(a) {
						return !dateWithTools.includes(a);
					});
					dates['hr'] = _.map(dates['hr'], function (value) {
						var dayVal = this.matchCalendar[value];
						return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
					}.bind(this));

					var  dati_rpe = _.filter(dati, function(a) {
						return a.fase_rpe;
					});
					var dateWithTools = _.uniq(_.map(dati_rpe, 'date'));
					dates['rpe'] = _.filter(dates['rpe'], function(a) {
						return !dateWithTools.includes(a);
					});
					dates['rpe'] = _.map(dates['rpe'], function (value) {
						var dayVal = this.matchCalendar[value];
						return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
					}.bind(this));

					var  dati_durata = _.filter(dati, function(a) {
						return a.fase_durata;
					});
					var dateWithTools = _.uniq(_.map(dati_durata, 'date'));
					dates['durata'] = _.filter(dates['durata'], function(a) {
						return !dateWithTools.includes(a);
					});
					dates['durata'] = _.map(dates['durata'], function (value) {
						var dayVal = this.matchCalendar[value];
						return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
					}.bind(this));

					var  dati_tqr = _.filter(dati, function(a) {
						return a.fase_tqr;
					});
					var dateWithTools = _.uniq(_.map(dati_tqr, 'date'));
					dates['tqr'] = _.filter(dates['tqr'], function(a) {
						return !dateWithTools.includes(a);
					});
					dates['tqr'] = _.map(dates['tqr'], function (value) {
						var dayVal = this.matchCalendar[value];
						return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
					}.bind(this));
				}
				return dates;
			},

			daysOffByPerson: function() {
				var dates = { catapult: [], hr: [], rpe: [], durata: [], tqr: []};
				var startDate = moment(this.dateFrom);
				var endDate = moment(this.dateTo);
				var diff = endDate.diff(startDate, 'days');

				if(!startDate.isValid() || !endDate.isValid() || diff < 0) {
					return;
				}
				for(var i = 0; i <= diff; i++) {
					dates['catapult'].push(startDate.format('YYYY-MM-DD'));
					dates['hr'].push(startDate.format('YYYY-MM-DD'));
					dates['rpe'].push(startDate.format('YYYY-MM-DD'));
					dates['durata'].push(startDate.format('YYYY-MM-DD'));
					dates['tqr'].push(startDate.format('YYYY-MM-DD'));
					startDate = startDate.add(1, 'd');
				}

				if(this.results && this.results.raw_results) {

					var dati = this.results.raw_results;

					if(this.filters.players.length > 0) {
						dati = _.filter(dati, function(a) {
							return this.filters.players.includes(a.persona_id);
						}.bind(this));
					}

					var grouped_by_person = _.groupBy(dati, 'persona_id');

					var data_person = _.mapValues(grouped_by_person, function(dati_persona) {

						var dates_persona = _.clone(dates);

						var dati_catapult = _.filter(dati_persona, function(a) {
							return a.total_distance;
						});
						var dateWithTools = _.uniq(_.map(dati_catapult, 'date'));
						dates_persona['catapult'] = _.filter(dates_persona['catapult'], function(a) {
							return !dateWithTools.includes(a);
						});
						dates_persona['catapult'] = _.map(dates_persona['catapult'], function (value) {
							var dayVal = this.matchCalendar[value];
							return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
						}.bind(this));

						var dati_hr = _.filter(dati_persona, function(a) {
							return a.firstbeat_heart_rate_check;
						});
						var dateWithTools = _.uniq(_.map(dati_hr, 'date'));
						dates_persona['hr'] = _.filter(dates_persona['hr'], function(a) {
							return !dateWithTools.includes(a);
						});
						dates_persona['hr'] = _.map(dates_persona['hr'], function (value) {
							var dayVal = this.matchCalendar[value];
							return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
						}.bind(this));

						var dati_rpe = _.filter(dati_persona, function(a) {
							return a.fase_rpe;
						});
						var dateWithTools = _.uniq(_.map(dati_rpe, 'date'));
						dates_persona['rpe'] = _.filter(dates_persona['rpe'], function(a) {
							return !dateWithTools.includes(a);
						});
						dates_persona['rpe'] = _.map(dates_persona['rpe'], function (value) {
							var dayVal = this.matchCalendar[value];
							return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
						}.bind(this));

						var dati_durata = _.filter(dati_persona, function(a) {
							return a.fase_durata;
						});
						var dateWithTools = _.uniq(_.map(dati_durata, 'date'));
						dates_persona['durata'] = _.filter(dates_persona['durata'], function(a) {
							return !dateWithTools.includes(a);
						});
						dates_persona['durata'] = _.map(dates_persona['durata'], function (value) {
							var dayVal = this.matchCalendar[value];
							return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
						}.bind(this));

						var dati_tqr = _.filter(dati_persona, function(a) {
							return a.fase_tqr;
						});
						var dateWithTools = _.uniq(_.map(dati_tqr, 'date'));
						dates_persona['tqr'] = _.filter(dates_persona['tqr'], function(a) {
							return !dateWithTools.includes(a);
						});
						dates_persona['tqr'] = _.map(dates_persona['tqr'], function (value) {
							var dayVal = this.matchCalendar[value];
							return value + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
						}.bind(this));

						return dates_persona;
					}.bind(this));
					return data_person;
				}
				return dates;
			},

			daysOffByPersonRaw: function() {
				var dates = { catapult: [], hr: [], rpe: [], durata: [], tqr: []};
				var startDate = moment(this.dateFrom);
				var endDate = moment(this.dateTo);
				var diff = endDate.diff(startDate, 'days');

				if(!startDate.isValid() || !endDate.isValid() || diff < 0) {
					return;
				}
				for(var i = 0; i <= diff; i++) {
					dates['catapult'].push(startDate.format('YYYY-MM-DD'));
					dates['hr'].push(startDate.format('YYYY-MM-DD'));
					dates['rpe'].push(startDate.format('YYYY-MM-DD'));
					dates['durata'].push(startDate.format('YYYY-MM-DD'));
					dates['tqr'].push(startDate.format('YYYY-MM-DD'));
					startDate = startDate.add(1, 'd');
				}

				if(this.results && this.results.raw_results) {

					var dati = this.results.raw_results;

					if(this.filters.players.length > 0) {
						dati = _.filter(dati, function(a) {
							return this.filters.players.includes(a.persona_id);
						}.bind(this));
					}

					var grouped_by_person = _.groupBy(dati, 'persona_id');

					var data_person = _.mapValues(grouped_by_person, function(dati_persona) {

						var dates_persona = _.clone(dates);

						var dati_catapult = _.filter(dati_persona, function(a) {
							return a.total_distance;
						});
						var dateWithTools = _.uniq(_.map(dati_catapult, 'date'));
						dates_persona['catapult'] = _.filter(dates_persona['catapult'], function(a) {
							return !dateWithTools.includes(a);
						});

						var dati_hr = _.filter(dati_persona, function(a) {
							return a.firstbeat_heart_rate_check;
						});
						var dateWithTools = _.uniq(_.map(dati_hr, 'date'));
						dates_persona['hr'] = _.filter(dates_persona['hr'], function(a) {
							return !dateWithTools.includes(a);
						});

						var dati_rpe = _.filter(dati_persona, function(a) {
							return a.fase_rpe;
						});
						var dateWithTools = _.uniq(_.map(dati_rpe, 'date'));
						dates_persona['rpe'] = _.filter(dates_persona['rpe'], function(a) {
							return !dateWithTools.includes(a);
						});

						var dati_durata = _.filter(dati_persona, function(a) {
							return a.fase_durata;
						});
						var dateWithTools = _.uniq(_.map(dati_durata, 'date'));
						dates_persona['durata'] = _.filter(dates_persona['durata'], function(a) {
							return !dateWithTools.includes(a);
						});

						var dati_tqr = _.filter(dati_persona, function(a) {
							return a.fase_tqr;
						});
						var dateWithTools = _.uniq(_.map(dati_tqr, 'date'));
						dates_persona['tqr'] = _.filter(dates_persona['tqr'], function(a) {
							return !dateWithTools.includes(a);
						});
						return dates_persona;
					}.bind(this));
					return data_person;
				}
				return dates;
			},

			sessioni_catapult: function() {

				if(!this.results) return [];

				if(!this.start_filter_from_player) {

					var dataset_sessioni = _.orderBy(this.results.sessioni, function(a) {
						return moment(a.inizio).format('X');
					});


					if(this.filters.days.length == 0) return dataset_sessioni;

					return _.filter(dataset_sessioni, function(a) {

						return this.filters.days.indexOf( moment(a.inizio).format('YYYY-MM-DD') ) !== -1;

					}.bind(this));
				} else {

					var dataset_sessioni = _.orderBy(this.results.sessioni, function(a) {
						return moment(a.inizio).format('X');
					});

					if(this.filters.players.length == 0) return dataset_sessioni;

					var sessione_ids = _.uniq(_.map(_.filter(this.results.raw_results, function(a) {
						return this.filters.players.includes(a.persona_id);
					}.bind(this)), function(b) {
						return b.sessione_id;
					}));

					return _.filter(dataset_sessioni, function(a) {
						return sessione_ids.indexOf(a.id) !== -1;
					}.bind(this));

				}

			},
			players_catapult: function() {

				if(!this.results) return [];

				var ds = this.results.raw_results;

				if(!this.start_filter_from_player) {

					if(this.filters.sessioni.length > 0) {
						ds = _.filter(ds, function(a) {
							if(this.filters.sessioni.indexOf(a.sessione_id) !== -1) return true;
							return false;
						}.bind(this));
					}

					if(this.filters.fasi.length > 0) {
						ds = _.filter(ds, function(a) {
							if(this.filters.fasi.indexOf(a.fase_originale_id) !== -1) return true;
							return false;
						}.bind(this));
					}

					if(this.filters.days.length > 0) {
						ds = _.filter(ds, function(a) {
							if(this.filters.days.indexOf(a.date) !== -1) return true;
							return false;
						}.bind(this));
					}
				}

				var persona_ids = _.uniq(_.map(ds, 'persona_id'));

				return _.orderBy(this.results.raw_persone.filter(item => persona_ids.includes(item.id)), function(a) {
					return a.cognome;
				}.bind(this));
			},
			fasi_catapult: function() {

				if(!this.results)
					return [];

				var ds = this.results.raw_results;
				if(this.filters.sessioni.length > 0) {
					ds = _.filter(ds, function(a) {
						if(this.filters.sessioni.indexOf(a.sessione_id) !== -1) return true;
						return false;
					}.bind(this));
				}

				if(!this.start_filter_from_player && this.filters.days.length > 0) {
					ds = _.filter(ds, function(a) {
						if(this.filters.days.indexOf(a.date) !== -1) return true;
						return false;
					}.bind(this));
				}

				if(this.start_filter_from_player && this.filters.players.length > 0) {
					ds = _.filter(ds, function(a) {
						if(this.filters.players.includes(a.persona_id)) return true;
						return false;
					}.bind(this));
				}

				return _.filter(this.results.fasi, function(a) {
					return _.find(ds, { fase_originale_id: a.id });
				}.bind(this));

				var es = [];
				_.map(this.results.fasi, function(a) {

					var already = _.find(es, { id: a.oggetto.exercise_id });
					if(!already) {
						var already = {
							id: a.oggetto.exercise_id,
							fasi: [],
							esercizioNome: []
						};
						es.push(already);
					}

					already.fasi.push(a.id);
					already.esercizioNome.push(a.oggetto);

				});

				return es;

			},
			theResultsNoPeople: function() {

				if(!this.results)
					return [];

				var ds = this.results.raw_results;

				if(this.filters.sessioni.length > 0) {
					ds = _.filter(ds, function(a) {
						if(this.filters.sessioni.indexOf(a.sessione_id) !== -1) return true;
						return false;
					}.bind(this));
				}

				if(this.filters.fasi.length > 0) {
					ds = _.filter(ds, function(a) {
						if(this.filters.fasi.indexOf(a.fase_originale_id) !== -1) return true;
						return false;
					}.bind(this));
				}

				if(this.filters.days.length > 0) {
					ds = _.filter(ds, function(a) {
						if(this.filters.days.indexOf(a.date) !== -1) return true;
						return false;
					}.bind(this));
				}

				return ds;

			},
			theResultsTable: function() {

				var u = _.cloneDeep(this.theResults);

				u = _.groupBy(this.theResults, function(a) {
					if(this.ux.splitByDay) {
						return a.persona_id + '_' + a.date;
					} else {
						return a.persona_id;
					}
				}.bind(this));

				var useless = ["type", "persona_id", "fase_id", "__data", "__sessione_id"];

				var table = [];

				_.each(u, function(a, b) {

					var bb = [];

					var bySessione = _.groupBy(a, '__sessione_id');
					console.log('Dataset of ' + b, bySessione, this.results.sessioni);

					_.each(bySessione, function(c, d) {

						var RealSession = _.find(this.results.sessioni, { id: parseInt(d) });
						console.log(c, RealSession.sessione_tipo_glo_id);

						var tt = {};
						tt.typeOfSession = RealSession.sessione_tipo_glo_id;

						_.each(_.keys(c[0]), function(bb) {

							if(useless.indexOf(bb) === -1) {

								if(tt[bb] == undefined)
									tt[bb] = 0;

								if(bb == 'edi') {

									var dati = _.filter(_.map(c, function(i) {
										return i.edi > 0 ? i.edi : null;
									}));

									// console.log('dati: ', _.sumBy(c, 'eq_distance'), _.sumBy(c, 'total_distance'));

									// var edi = ((_.sumBy(dati, 'catapult_equivalent_distance') / _.sumBy(dati, 'total_distance'))-1) * 100;

									tt[bb] = ((_.sumBy(c, 'eq_distance') / _.sumBy(c, 'total_distance'))-1)*100;

								} else if(bb == 'max_vel') {
									tt[bb] = _.round(_.maxBy(c, 'max_vel').max_vel, 1);
								} else if(bb == 'date') {
									tt[bb] = _.first(c);
								} else if(bb == 'rpe')
									tt[bb] = _.round(_.sumBy(c, bb)/_.size(c), 2);
								else if(bb == 'total_duration') {
									tt[bb] = _.sumBy(c,bb);
								}else if(bb == 'tl___') {
									tt[bb] = _.first(c)[bb] * 60;
								} else
									tt[bb] = _.round(_.sumBy(c, bb), 2);
							}

						});

						//tt.total_duration = (60 * _.first(c).tl___);
						tt.tl = c[0].tl

						bb.push(tt);

					}.bind(this));

					//console.log('Will Mean btw: ' + _.sumBy(bb, 'total_duration'))
					var edi_dati = _.filter(_.map(bb, 'edi'), function(a) { return a > 0; });

					var tt = {
						typeOfSession: _.uniq(_.map(bb, 'typeOfSession')),
						date: _.first(_.map(bb, 'date')).date,
						edi: edi_dati.length > 0 ? _.round(_.sum(edi_dati)/edi_dati.length,1) : 0,
						eq_distance: _.sumBy(bb, 'eq_distance'),
						max_vel: _.maxBy(bb, 'max_vel').max_vel,
						num_acc: _.sumBy(bb, 'num_acc'),
						num_dec: _.sumBy(bb, 'num_dec'),
						pow_ev: _.sumBy(bb, 'pow_ev'),
						rpe: _.round(_.sumBy(bb, 'rpe')/_.size(bySessione), 1),
						tl: _.round(_.sumBy(bb, 'tl'), 0),
						tl___: _.sumBy(bb, 'tl___'),
						total_distance: _.round(_.sumBy(bb, 'total_distance'), 0),
						total_duration: _.sumBy(bb, 'total_duration'),
						total_duration_whole: _.sumBy(bb, 'total_duration_whole'),
						velocity_20_25: _.round(_.sumBy(bb, 'velocity_20_25'), 0),
						velocity__15: _.round(_.sumBy(bb, 'velocity__15'), 0),
						velocity__20: _.round(_.sumBy(bb, 'velocity__20'), 0),
						velocity__25: _.round(_.sumBy(bb, 'velocity__25'), 0),
					}

					if(this.ux.wholeSessione) {
						tt.relative_distance = _.round(tt.total_distance/(tt.tl___/60),0);
					} else {
						tt.relative_distance = _.round(tt.total_distance/(tt.total_duration/60),0)
					}

					table.push({ persona_id: b, persona: _.find(this.personeDataset, { id: parseInt(b) }),  tt });

				}.bind(this));

				if(!this.ux.splitByDay) {

					var meanBase = calculateMean(this.results, table, this.isDatasetOnlyMatches);

					table.push({
						persona: { team: true, cognome: 'Media', nome: '' },
						tt: meanBase
					});

				}

				if(this.ux.dayoff && this.ux.splitByDay) {

					var allPersone = _.uniq(_.map(table, 'persona.id'));
					console.log('table', table);
					console.log('persone: ', allPersone);

					var startDate = moment(this.dateFrom);
					var endDate = moment(this.dateTo);
					var diff = endDate.diff(startDate, 'days');

					for(var i = 0; i <= diff; i++) {

						_.each(allPersone, function(a) {

							var already = _.find(table, function(p) {
								return p.persona.id == a && p.tt.date == startDate.format('YYYY-MM-DD');
							});

							console.log('Is Already: ', already);

							if(!already) {
								var u = _.cloneDeep(_.find(table, function(d) { return d.persona.id == a; }));
								u.id = a + '_' + startDate.format('YYYY-MM-DD');
								u.tt.date = startDate.format('YYYY-MM-DD');
								u.tt.typeOfSession = [];
								u.tt.edi = null;
								u.tt.eq_distance = null;
								u.tt.max_vel = null;
								u.tt.num_acc = null;
								u.tt.num_dec = null;
								u.tt.pow_ev = null;
								u.tt.relative_distance = null;
								u.tt.rpe = null;
								u.tt.tl = null;
								u.tt.tl___ = null;
								u.tt.total_distance = null;
								u.tt.total_duration = 0.001;
								u.tt.velocity_20_25 = null;
								u.tt.velocity__15 = null;
								u.tt.velocity__20 = null;
								u.tt.velocity__25 = null;
								table.push(u);
							}

						}.bind(this))

						console.log(startDate.format('YYYY-MM-DD'));
						startDate = startDate.add(1, 'd');
					}

				}

				if(this.ux.splitByDay) {

					table = _.orderBy(table, function(u) {
						return moment(u.tt.date).format('X');
					});

					_.map(table, function(i) {
						console.log(i);
						if(i.tt.total_duration == 0.01) {
							i.tt.total_duration = 60 * i.tt.total_duration_whole;
							i.tt.tl___ = 60 * i.tt.total_duration_whole;
						} else if(i.tt.total_duration == 0.001) {
							i.tt.total_duration = 0.01;
						}
						return i;
					});
					//console.log('??', table);

					return _.orderBy(table, [ 'persona.cognome' ], [ 'asc' ]);	
				} 

				return _.orderBy(table, [ this.ux.orderBy.indexOf('.') === -1 ? 'tt.' + this.ux.orderBy : this.ux.orderBy ], [ this.ux.orderDir ]);


				return table;

				return this.theResults;

			},
			theResults: function() {

				if(!this.results)
					return [];

				var ds = this.results.dataset;

				var ds = _.filter(ds, function(u) {

					if(this.filters.players.length > 0)
						if(this.filters.players.indexOf(u.persona_id) === -1) return false;

					if(this.filters.sessioni.length > 0)
						if(this.filters.sessioni.indexOf(u.__sessione_id) === -1) return false;

					if(this.filters.days.length > 0)
						if(this.filters.days.indexOf(u.date) === -1) return false;


					return true;

				}.bind(this));

				return _.map(ds, function(p) {

					//p.__data_data = p.__data.split(' ')[0];
					return p;

				});

				console.log(ds);

				return ds;

			},
			isAlreadyImported: function() {
				if(this.results) return true;
				if(!this.sessione || !this.sessione.forms || !this.sessione.forms.fasi_form ) return null;
				return _.filter(this.sessione.forms.fasi_form, function(i) { return i.entity.entita == 'Fase_Dato_Tools' }).length;
			},
			params: function() {

				var otherCategory = this.ux.otherCategory;
				var category_id = this.categoriaId;
				if(this.sessione)
					category_id = this.sessione.categoria_id;

				var dateFrom = null;
				var dateTo = null;
				if(this.sessione) {
					dateFrom = this.sessione.inizio.split(' ')[0];
					dateTo = this.sessione.inizio.split(' ')[0];
				} else {
					dateFrom = this.dateFrom;
					dateTo = this.dateTo;
				}

				var sessioni = [];

				if(this.sessione)
					sessioni.push(this.sessione.id);

				return {
					category_id: category_id,
					dateFrom: dateFrom,
					dateTo: dateTo,
					sessione_id: sessioni,
					otherCategory: otherCategory,
					players: []
				}

			},
			...mapState(['categoriaId']),
		},
		watch: {
			'ux.otherCategory': function() {
				this.fetchResults();
			},
			'ux.allDataset': function() {
				this.filters.fasi = [];
			},
			dateFrom: function() {
				if(moment(this.dateTo).format('X') < moment(this.dateFrom).format('X'))
					this.dateTo = _.clone(this.dateFrom);
			},
			dateTo: function() {
				if(moment(this.dateTo).format('X') < moment(this.dateFrom).format('X'))
					this.dateFrom = _.clone(this.dateTo);
			},
			categoriaId() {
				this.fetchResults();
			},
			sessione: {
			// deep: true,
				immediate: true,
				handler: function(a,b) {
					if(this.sessione && this.isAlreadyImported)
						this.fetchResults();
				}
			},
			'ux.table': function () {
				if(this.isWidget) {
					this.$emit('updateConfigWidget', this.isWidget, 'activeTab', this.ux.table);
				}	
			},
			'refresh': {
				immediate: true,
				handler: function() {
					if(this.refresh && this.refresh > 0) {
						_.delay(this.refreshData, this.refresh * 60000);
					}
				}
			},
			fasi_catapult() {
				if(this.fasi_catapult.length == 0) {
					this.filters.fasi = [];
				}
			},
			'filters': {
				deep: true,
				handler: function() {
					if(this.filters.days.length == 0 && this.filters.sessioni.length == 0 && this.filters.fasi.length == 0 && this.filters.players.length > 0) {
						this.start_filter_from_player = true;
					} else if(this.filters.players.length == 0 && (this.filters.days.length > 0 || this.filters.sessioni.length > 0 || this.filters.fasi.length > 0)) {
						this.start_filter_from_player = false;
					}
				}
			},
			'ux.showGeneralChart': function() {
				if(this.ux.showGeneralChart && this.dateFrom == this.dateTo) {
					this.filters.players = _.map(this.players_catapult, 'id');
				}
			},
		},
		data: function(){
			return {
				dateFrom: null,
				dateTo: null,
				ux: {
					dayoff: false,
					showperc: true,
					wholeSessione: false,
					orderDir: 'desc',
					orderBy: 'total_distance',
					waiting: false,
					collapsed: this.collapsable ? true : false,
					table: this.configWidget && this.configWidget.activeTab ? this.configWidget.activeTab : 'drill',
					allDataset: false,
					otherCategory: false,
					tab_sel: [],
					showVelocityChart: false,
					showNeuromChart: false,
					showGeneralChart: false,
					showVelocity2Tab: false,
					showVelocity2ByExeTab: false,
					splitByDay: false,
					daysOffShow: false
				},
				filters: {
					days: [],
					sessioni: [],
					players: [],
					fasi: [],
				},
				orderings: {
					neurom: { key: 'cognome', order: -1 },
					velocity: { key: 'cognome', order: -1 },
					velocity2: { key: 'cognome', order: -1 },
					drill: { key: 'cognome', order: -1 },
					hr: { key: 'cognome', order: -1 },
					neurom_by_fase: { key: 'fase', order: -1 },
					velocity_by_fase: { key: 'fase', order: -1 },
					velocity2_by_fase: { key: 'fase', order: -1 },
					drill_by_fase: { key: 'fase', order: -1 },
					hr_by_fase: { key: 'fase', order: -1 },
				},
				results: null,
				waiting: false,
				start_filter_from_player: false,
				matchCalendar: null,
				daysOffKpi: {
					catapult: ['gps_minutes', 'total_distance', 'relative_distance', 'cc_dist_15', 'max_vel', 'mp', 'edi', 'pow_ev', 'num_acc', 'num_dec', 'sp_ev', 'velocity_0_8', 'velocity_8_13', 'velocity_13_15', 'velocity_15_20', 'velocity_20_25', 'velocity__25', 'velocity__15', 'velocity__20', 'velocity__15_p',  'vel_max_perc_0_60', 'vel_max_perc_60_70', 'vel_max_perc_70_80', 'vel_max_perc_80_90', 'vel_max_perc_90_100', 'vel_max_perc__100', 'vel_max_perc__80', 'vel_max_perc__90', 'vel_max_perc__80_p', 'acc1', 'acc2', 'acc3', 'dec3', 'dec2', 'dec1'],
					hr: ['edward', 'avg_hr', 'avg_hr_p',  'max_hr', 'max_hr_p', 'hr_0_55', 'hr_55_65', 'hr_65_75', 'hr_75_85', 'hr_85_95', 'hr_95', 'hr_85', 'hr_85_raw'],
					rpe: ['rpe'],
					durata: ['session_minutes', 'sessione_tl'],
					tqr: ['tqr'],
				}
			}
		},
		created: function() {
			//console.log(this.sessione);
			if(!this.sessione) {
				this.dateFrom = moment().format('YYYY-MM-DD');
				this.dateTo = moment().format('YYYY-MM-DD');
				this.fetchResults();
			} else {

				this.$root.$on('refresh-tools-notes', function() {
					this.fetchResults();
				}.bind(this));

				if(!this.sessione.setup) {
					this.sessione.setup = {};
				}

				if(!this.sessione.setup.note_tools)
					this.$set(this.sessione.setup, 'note_tools', '');

				//console.log('Calling.');
				this.fetchResults();

			}
		},
		methods: {
			selectType: function(idOfTypes) {

				console.log(this.filters.sessioni);

				var ids = _.filter(this.results.sessioni, function(a) {

					if(idOfTypes.indexOf(a.sessione_tipo_glo_id) !== -1 && this.filters.sessioni.indexOf(a.id) === -1)
						return true;

				}.bind(this));

				_.each(ids, function(a) {
					this.filters.sessioni.push(a.id);
				}.bind(this));

				//console.log(ids, this.filters.sessioni);
				//console.log(this.results.dataset);

			},
			isComputed: function(playerId) {
				if(this.results.best[playerId] == undefined) return false;
				if(this.results.best[playerId].total_distance)
					return this.results.best[playerId].computed;
			},
			isVoid: function(playerId) {
				if(this.results.best[playerId] == undefined) return false;
				if(!this.results.best[playerId].total_distance) return true;
			},
			playerRatioStyle: function(label, dataset, userId) {

				var a = this.playerRatio(label, dataset, userId);
				if(a == '--') return '';

				var value = parseFloat(a.replace('%', ''));

				if(value > 100) {
					return 'box-shadow: inset 0px 0px 0px 1px #12a14b;';
				} else if(value > 66 && value <= 100) {
					return 'box-shadow: inset 0px 0px 0px 1px #ffff00;';
				} else if(value > 33 && value <= 66) {
					return 'box-shadow: inset 0px 0px 0px 1px #ff930e;';
				} else if(value <= 33) {
					return 'box-shadow: inset 0px 0px 0px 1px #fb0102;';
				}

			},
			playerRatio: function(label, dataset, userId) {

				if(this.results.best[userId] !== undefined) {

					var u = (dataset[label]/this.results.best[userId][label]);
					if(_.isFinite(u) && !_.isNull(u))
						return _.round(100 * u, 0) + '%';
				}
				
				return '--';

			},
			changeOrder: function(key) {
				if(this.ux.orderBy === key) {
					if(this.ux.orderDir == 'asc')
						this.ux.orderDir = 'desc';
					else
						this.ux.orderDir = 'asc';
				} else {
					this.ux.orderBy = key;
					this.ux.orderDir = 'asc';
				}
			},
			ratio: function(a, b) {
				return _.round(100*b/a);
			},
			getBgRatio: function (value) {
				if(value > 100) {
					return '#12a14b';
				} else if(value > 66 && value <= 100) {
					return '#ffff00';
				} else if(value > 33 && value <= 66) {
					return '#ff930e';
				} else if(value <= 33) {
					return '#fb0102';
				}
			},
			isBest: function(value, k) {
				if(this.ux.splitByDay) return false;
				if(this.bests[k].bests.indexOf(value) !== -1)
					return true;
			},
			isWorst: function(value, k) {
				if(this.ux.splitByDay) return false;
				if(this.bests[k].worsts.indexOf(value) !== -1)
					return true;
			},
			printTrackingPeriod: function() {
				window.open(this.printString, "_blank");
			},
			printToolsPlayerGraph: function() {

				var printUrl = 'https://api.asromasystem.com/tools/pdf/print-graph/' + Auth.user.id + "/" + Auth.user.api_token + '?id=' + this.sessione.id + '&cat=1';

				if(this.filters.players.length > 0)
					printUrl += '&players=' + this.filters.players.join(',');

				window.open(printUrl, "_blank");

			},
			selectSessions: function(id) {
				var selected = _.map(_.filter(this.sessioni_catapult, function(a) {
					return a.sessione_tipo_glo_id === id;
				}), 'id');
				this.filters.sessioni = _.uniq(_.union(this.filters.sessioni, selected));
			},
			filteringFase: function(a) {
				this.filters.fasi = [a];
			},
			fetchResults: function() {
				this.ux.waiting = true;
				this.resetFilters();
				this.$http.post(this.$store.state.apiEndPoint + '/tracking/table-lite', this.params).then(function(data) {
					this.ux.waiting = false;
					this.results = data.data;
				});
				this.$http.get(this.$store.state.apiEndPoint + '/tools/calendario/partite', {params: {categoria: this.params.category_id, from: this.params.dateFrom, to: this.params.dateTo }}).then(function(data) {
					this.matchCalendar = data.data;
				});
			},
			resetFilters: function() {
				this.filters.days = [];
				this.filters.sessioni = [];
				this.filters.fasi = [];
				this.filters.players = [];	
			},
			refreshData: function() {
				this.fetchResults();
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}

		}
	}

</script>
<template>
	<div>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="content">
					<form @submit.stop.prevent="form.partita.enabled ? handleSubmit() : ''">
						<b-card>
							<div class="row">
								<div class="col">

									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Data della partita</label>
												<date-picker :date="form.partita.date" :option="option" :limit="limit" v-if="form.partita.enabled"></date-picker>
												<b-form-input v-else v-model="form.partita.date.time" :disabled="true"></b-form-input>
											</fieldset>
										</div>
										<!--div class="col">
											<fieldset class="form-group">
												<label>Dove si gioca la partita</label>
												<b-form-select v-model="form.partita.home" :disabled="!form.partita.enabled">
													<option :value="null">Seleziona</option>
													<option value="0">casa</option>
													<option value="1">trasferta</option>
												</b-form-select>
											</fieldset>
										</div-->
										<div class="col">
											<fieldset class="form-group">
												<label>Modulo casa</label>
												<b-form-select v-model="form.partita.formation" :disabled="!form.partita.enabled">
													<option :value="null">--modulo--</option>
													<option v-for="mod in opt_modules" :value="mod.id">{{ mod.name }}</option>
												</b-form-select>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Modulo ospite</label>
												<b-form-select v-model="form.partita.formation_opponent" :disabled="!form.partita.enabled">
													<option :value="null">--modulo--</option>
													<option v-for="mod in opt_modules" :value="mod.id">{{ mod.name }}</option>
												</b-form-select>
											</fieldset>
										</div>
									</div>	
								</div>
								<div class="col">
									<div class="row">
										<div class="col">
											<fieldset class="form-group">
												<label>Durata partita</label>
												<input class="form-control" type="number" min="1" 
												v-model="form.partita.duration" 
												:disabled="!form.partita.enabled"
												@keyup="updateDuration('from_total')"
												>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>D.1°tempo</label>
												<input class="form-control" type="number" min="0" 
												v-model="form.partita.duration_first_half" 
												:disabled="!form.partita.enabled"
												@keyup="updateDuration('from_partial')"
												>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>D.2°tempo</label>
												<input class="form-control" type="number" min="0" 
												v-model="form.partita.duration_second_half" 
												:disabled="!form.partita.enabled"
												@keyup="updateDuration('from_partial')"
												>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Rec. 1° tempo</label>
												<b-form-input type="number" min="0" v-model="form.partita.recovery_first_half" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group">
												<label>Rec. 2° tempo</label>
												<b-form-input type="number" min="0" v-model="form.partita.recovery_second_half" :disabled="!form.partita.enabled"></b-form-input>
											</fieldset>	
										</div>
									</div>
									<div class="row">
										<div class="col">
											<fieldset class="form-group" v-if="form.partita.has_supplementari">
												<label>D.supplementari</label>
												<input class="form-control" type="number" min="1" 
												v-model="form.partita.duration_suppl" 
												:disabled="!form.partita.enabled"
												@keyup="updateDuration('from_total_suppl')"
												>
											</fieldset>	
										</div>
										<div class="col">
											<fieldset class="form-group" v-if="form.partita.has_supplementari">
												<label>D.1°tempo suppl.</label>
												<input class="form-control" type="number" min="1" 
												v-model="form.partita.duration_suppl_first_half" 
												:disabled="!form.partita.enabled"
												:readonly="!['restart','stop'].includes(form.partita.start_stop_status)"
												@keyup="updateDuration('from_partial_suppl')"
												>
											</fieldset>
										</div>
										<div class="col">
											<fieldset class="form-group" v-if="form.partita.has_supplementari">
												<label>D.2°tempo suppl.</label>
												<input class="form-control" type="number" min="1" 
												v-model="form.partita.duration_suppl_second_half" 
												:disabled="!form.partita.enabled"
												:readonly="!['restart','stop'].includes(form.partita.start_stop_status)"
												@keyup="updateDuration('from_partial_suppl')"
												>
											</fieldset>
										</div>
									</div>
								</div>
							</div>
						</b-card>

						<!-- campetto start -->
						<div>
							<b-row class="teamsSchemes mt-2">
								<b-col class="text-center" v-if="form.partita.formation">
									<span class="scheme">{{ modules.find(item => item.id == form.partita.formation).name }}</span>
								</b-col>
								<b-col class="text-center">
									<slot></slot>
								</b-col>
								<b-col class="text-center" v-if="form.partita.formation_opponent">
									<span class="scheme">{{ modules.find(item => item.id == form.partita.formation_opponent).name }}</span>
								</b-col>
							</b-row>
							<div class="teamSubstitutions clearfix" v-if="formation_home_no_position || formation_away_no_position">

								<ul class="dotsTeam teamA">
									<li v-for="item in formation_home_no_position" :key="'no_pos_A_' + item.id">
										{{ item.maglia }}
										<span v-if="item.yellowCards" class="player-amm"></span>
										<span v-if="item.redCards" class="player-esp"></span>
										<span v-if="item.goals" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

										<div class="player-tooltip">
											<div style="min-width:150px">
												<div class="player_thumb">
													<img width="30" :src="item.avatar" />
												</div>
												<div class="player_info">
													<h1>{{ item.short_name }}</h1>
													<h2>
														<span v-if="item.playerOut"><img src="/assets/wyscout/out.png" />{{ item.playerOut }}'</span>
														<span v-if="item.yellowCards"><span v-for="yellow in item.yellowCards"><img src="/assets/wyscout/yellow.png" :key="'amnfh_' + yellow"/>{{ yellow }}'</span></span>
														<span v-if="item.redCards"><img src="/assets/wyscout/red.png" />{{ item.redCards }}'</span>
														<span v-if="item.goals"><span v-for="goal in item.goals"><img src="/assets/wyscout/ball.png" :key="'ggnfh_' + goal"/>{{ goal }}'</span></span>
													</h2>
												</div>
											</div>
										</div>
									</li>
								</ul>

								<ul class="dotsTeam teamB">
									<li v-for="item in formation_away_no_position" :key="'no_pos_B_' + item.id">
										{{ item.maglia }}
										<span v-if="item.yellowCards" class="player-amm"></span>
										<span v-if="item.redCards" class="player-esp"></span>
										<span v-if="item.goals" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>
										<div class="player-tooltip">
											<div style="min-width:150px">
												<div class="player_thumb">
													<img width="30" :src="item.avatar" />
												</div>
												<div class="player_info">
													<h1>{{ item.short_name }}</h1>
													<h2>
														<span v-if="item.playerOut"><img src="/assets/wyscout/out.png" />{{ item.playerOut }}'</span>
														<span v-if="item.yellowCards"><span v-for="yellow in item.yellowCards"><img src="/assets/wyscout/yellow.png" :key="'amnfa_' + yellow"/>{{ yellow }}'</span></span>
														<span v-if="item.redCards"><img src="/assets/wyscout/red.png" />{{ item.redCards }}'</span>
														<span v-if="item.goals"><span v-for="goal in item.goals"><img src="/assets/wyscout/ball.png" :key="'hhnfa_' + goal"/>{{ goal }}'</span></span>
													</h2>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="teamSheetPitch" style="background-image: url(assets/wyscout/ws.png)">
								<ul class="gears-abs gears-fit dotsTeam teamA">
									<li v-for="item in formation_home" :key="item.id" :class=" 'teamA_' + item.posizione_id + '' + ( item.playerOut ? ' substituted' : '')">
										{{ item.maglia }}
										<span class="player-name">{{ item.short_name }}</span>
										<span v-if="item.yellowCards" class="player-amm"></span>
										<span v-if="item.redCards" class="player-esp"></span>
										<span v-if="item.goals" class="player-goal" ><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

										<div class="player-tooltip">
											<div style="min-width:150px">
												<div class="player_thumb">
													<img width="30" :src="item.avatar" />
												</div>
												<div class="player_info">
													<h1>{{ item.short_name }}</h1>
													<h2>
														<span v-if="item.playerOut"><img src="/assets/wyscout/out.png" />{{ item.playerOut }}'</span>
														<span v-if="item.yellowCards"><span v-for="yellow in item.yellowCards"><img src="/assets/wyscout/yellow.png" :key="'amh_' + yellow"/>{{ yellow }}'</span></span>
														<span v-if="item.redCards"><img src="/assets/wyscout/red.png" />{{ item.redCards }}'</span>
														<span v-if="item.goals"><span v-for="goal in item.goals"><img src="/assets/wyscout/ball.png" :key="'ggh_' + goal"/>{{ goal }}'</span></span>
													</h2>
												</div>
											</div>
										</div>
									</li>
								</ul>
								<ul class="gears-abs gears-fit dotsTeam teamB">
									<li v-for="item in formation_away" :key="item.id" :class="'teamB_' + item.posizione_id + '' + ( item.playerOut ? ' substituted': '')">
										{{ item.maglia }}
										<span class="player-name">{{ item.short_name }}</span>
										<span v-if="item.yellowCards" class="player-amm"></span>
										<span v-if="item.redCards" class="player-esp"></span>
										<span v-if="item.goals" class="player-goal" ><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

										<div class="player-tooltip">
											<div style="min-width:150px">
												<div class="player_thumb">
													<img width="30" :src="item.avatar" />
												</div>
												<div class="player_info">
													<h1>{{ item.short_name }}</h1>
													<h2>
														<span v-if="item.playerOut"><img src="/assets/wyscout/out.png" />{{ item.playerOut }}'</span>
														<span v-if="item.yellowCards"><span v-for="yellow in item.yellowCards"><img src="/assets/wyscout/yellow.png" :key="'ama_' + yellow"/>{{ yellow }}'</span></span>
														<span v-if="item.redCards"><img src="/assets/wyscout/red.png" />{{ item.redCards }}'</span>
														<span v-if="item.goals"><span v-for="goal in item.goals"><img src="/assets/wyscout/ball.png" :key="'gga_' + goal"/>{{ goal }}'</span></span>
													</h2>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>

							<div class="teamSubstitutions clearfix">

								<ul class="dotsTeam teamA">
									<li v-for="item in getSubstitutionsHome" :key="'subA_' + item.id">
										{{ item.maglia }}
										<span v-if="item.yellowCards" class="player-amm"></span>
										<span v-if="item.redCards" class="player-esp"></span>
										<span v-if="item.goals" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>

										<div class="player-tooltip">
											<div style="min-width:150px">
												<div class="player_thumb">
													<img width="30" :src="item.avatar" />
												</div>
												<div class="player_info">
													<h1>{{ item.short_name }}</h1>
													<h2>
														<span v-if="item.playerIn"><img src="/assets/wyscout/in.png" />{{ item.playerIn }}'</span>
														<span v-if="item.yellowCards"><span v-for="yellow in item.yellowCards"><img src="/assets/wyscout/yellow.png" :key="'samh_' + yellow"/>{{ yellow }}'</span></span>
														<span v-if="item.redCards"><img src="/assets/wyscout/red.png" />{{ item.redCards }}'</span>
														<span v-if="item.goals"><span v-for="goal in item.goals"><img src="/assets/wyscout/ball.png" :key="'sggh_' + goal"/>{{ goal }}'</span></span>
													</h2>
												</div>
											</div>
										</div>
									</li>
								</ul>

								<ul class="dotsTeam teamB">
									<li v-for="item in getSubstitutionsAway" :key="'subB_' + item.id">
										{{ item.maglia }}
										<span v-if="item.yellowCards" class="player-amm"></span>
										<span v-if="item.redCards" class="player-esp"></span>
										<span v-if="item.goals" class="player-goal"><img class="player-goal" src="/assets/wyscout/ball.png"/></span>
										<div class="player-tooltip">
											<div style="min-width:150px">
												<div class="player_thumb">
													<img width="30" :src="item.avatar" />
												</div>
												<div class="player_info">
													<h1>{{ item.short_name }}</h1>
													<h2>
														<span v-if="item.playerIn"><img src="/assets/wyscout/in.png" />{{ item.playerIn }}'</span>
														<span v-if="item.yellowCards"><span v-for="yellow in item.yellowCards"><img src="/assets/wyscout/yellow.png" :key="'sama_' + yellow"/>{{ yellow }}'</span></span>
														<span v-if="item.redCards"><img src="/assets/wyscout/red.png" />{{ item.redCards }}'</span>
														<span v-if="item.goals"><span v-for="goal in item.goals"><img src="/assets/wyscout/ball.png" :key="'sgga_' + goal"/>{{ goal }}'</span></span>
													</h2>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<!-- campetto end -->

						<div>
							<div class="row">
								<div class="col">
									<div class="card h-100">
										<div class="card-body">

											<div class="card-title">{{ form.partita.team_home ? form.partita.team_home : '&nbsp;' }}</div>

											<div :class="'form-group' + (n == num_players ? ' mb-5' : '')" v-for="n in n_players" :key="'p_' + n">
												<div class="row">
													<label class="col-1 text-sm-center col-form-label">
														{{ n }}
													</label>
													<div class="col-7">
														<multiselect v-model="form.partita.match_players[n-1]" :options="options_players" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false" @select="selectPlayer" :id="'plsel_' + (n - 1)" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-sm-2">
														<b-form-select v-model="form.partita.match_players_shirt[n-1]" :disabled="!form.partita.enabled">
															<option :value="null">maglia</option>
															<option v-for="s in 99" :value="s" :key="'s_' + s">{{s}}</option>
														</b-form-select>
													</div>
													<div class="col-sm-2">
														<b-form-select v-model="form.partita.match_players_role[n-1]" :disabled="!form.partita.enabled">
															<option :value="null">ruolo</option>
															<option v-for="r in filtered_ruoli" :value="r.id" :key="'sr_' + r.id">{{r.sigla}}</option>
														</b-form-select>
													</div>
												</div>
											</div>
											<b-button variant="outline-primary" @click="addPLayer" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>
											<b-button v-if="auth.isLastSeason(match.stagione_id) && match.home.societa.length == 0" variant="outline-primary" @click="addPlayerHome = !addPlayerHome" :disabled="!form.partita.enabled"><i class="fa fa-arrow-down" v-if="!addPlayerHome"></i><i class="fa fa-arrow-up" v-if="addPlayerHome"></i> Aggiungi nuovo giocatore alla squadra</b-button>
											
											<addGiocatore :teamId="match.home_id" @playerAdded="playerAdded" v-if="addPlayerHome" class="mt-1"/>

										</div>
									</div>
								</div>
								<div class="col">
									<div class="card h-100">
										<div class="card-body">

											<div class="card-title">{{ form.partita.team_away ? form.partita.team_away : '&nbsp;' }}</div>
											
											<div :class="'form-group' + (n == num_players ? ' mb-5' : '')" v-for="n in n_opponents" :key="'fo_' + n">
												<div class="row">
													<label class="col-1 text-sm-center col-form-label">
														{{ n }}
													</label>
													<div class="col-7">
														<multiselect v-model="form.partita.match_players_opponent[n - 1]" :options="options_opponents" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="id" :show-labels="false" :allow-empty="false"  @select="selectOpponent" :id="'oplsel_' + (n - 1)" :disabled="!form.partita.enabled"></multiselect>
													</div>
													<div class="col-sm-2">
														<b-form-select v-model="form.partita.match_players_opponent_shirt[n-1]" :disabled="!form.partita.enabled">
															<option :value="null">maglia</option>
															<option v-for="s in 99" :value="s" :key="'os_' + s">{{s}}</option>
														</b-form-select>
													</div>
													<div class="col-sm-2">
														<b-form-select v-model="form.partita.match_players_opponent_role[n-1]" :disabled="!form.partita.enabled">
															<option :value="null">ruolo</option>
															<option v-for="r in filtered_ruoli" :value="r.id" :key="'osr_' + r.id">{{r.sigla}}</option>
														</b-form-select>
													</div>
												</div>
											</div>
											<b-button variant="outline-primary" @click="addOpponent" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Giocatore</b-button>
											<b-button v-if="auth.isLastSeason(match.stagione_id) && match.away.societa.length == 0" variant="outline-primary" @click="addPlayerAway = !addPlayerAway" :disabled="!form.partita.enabled"><i class="fa fa-arrow-down" v-if="!addPlayerAway"></i><i class="fa fa-arrow-up" v-if="addPlayerAway"></i> Aggiungi nuovo giocatore alla squadra</b-button>
											<addGiocatore :teamId="match.away_id" @playerAdded="playerAdded" v-if="addPlayerAway" class="mt-1"/>
										</div>
									</div>
								</div>
							</div>

							<div>

								<div class="row mt-1">
									<div class="col">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title mt-0">Goal</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-5">
														<label>Giocatore</label>
													</div>
													<div class="col-2">
														<label>Rigore</label>
													</div>
													<div class="col-2">
														<label>Autogoal</label>
													</div>
												</div>
												<div class="row" v-for="(item, index) in form.partita.goals" :key="'goal_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'gm_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-5 form-group">

														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'gpl_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.penalty" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.autogoal" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeGoal(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addGoal()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Goal</b-button>

											</div>
										</div>
									</div>
									<div class="col">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title mt-0">Goal</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-5">
														<label>Giocatore</label>
													</div>
													<div class="col-2">
														<label>Rigore</label>
													</div>
													<div class="col-2">
														<label>Autogoal</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.goals_opponent" :key="'go_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'gmo_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-5 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<!--option :value="-1">Anonimo</option-->
																<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'gplo_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.penalty" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-2 form-group">
														<b-form-select v-model="item.autogoal" :disabled="!form.partita.enabled">
															<option :value="0">no</option>
															<option :value="1">si</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-primary" @click="removeGoalOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addGoalOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Goal</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div class="col">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Ammonizioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.amm" :key="'amm_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'mamm_' + index">{{ value.label }}</option>
															<option :value="900" key="mamm_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'ammpl_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeAmm(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addAmm()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Ammonizione</b-button>
												
											</div>
										</div>
									</div>
									<div class="col">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Ammonizioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.amm_opponent" :key="'ammo_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'ammom_' + index">{{ value.label }}</option>
															<option :value="900" key="ammom_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'ammplo_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeAmmOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addAmmOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Ammonizione</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div class="col">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Espulsioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.es" :key="'es_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'esm_' + index">{{ value.label }}</option>
															<option :value="900" key="esm_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'espl_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeEs(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addEs()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Espulsione</b-button>
												
											</div>
										</div>
									</div>
									<div class="col">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Espulsioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-10">
														<label>Giocatore</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.es_opponent" :key="'eso_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti" :value="value.minuto" :key="'esmo_' + index">{{ value.label }}</option>
															<option :value="900" key="esmo_900">Fuori gara</option>
														</b-form-select>
													</div>
													<div class="col-9 form-group">
														<div class="row">
															<b-form-select v-model="item.player" :disabled="!form.partita.enabled">
																<option :value="null">seleziona</option>
																<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'esplo_' + index">{{value.name}}</option>
															</b-form-select>
														</div>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeEsOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addEsOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Espulsione</b-button>

											</div>
										</div>
									</div>
								</div>

								<div class="row mt-1">
									<div class="col">
										<div class="card h-100">
											<div class="card-body">
												<div class="card-title">Sostituzioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-4">
														<label>Esce</label>
													</div>
													<div class="col-4">
														<label>Entra</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.sub" :key="'sost_' + index">
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti_with_intervallo" :value="value.minuto" :key="'smin_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_end" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'splout_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_start" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players" :value="value" :key="'splin_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeSub(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addSub()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Sostituzione</b-button>
												
											</div>
										</div>
									</div>
									<div class="col">
										<div class="card h-100">
											<div class="card-body">	
												<div class="card-title">Sostituzioni</div>
												<div class="row">
													<div class="col-2">
														<label>Minuto</label>
													</div>
													<div class="col-4">
														<label>Esce</label>
													</div>
													<div class="col-4">
														<label>Entra</label>
													</div>
												</div>
												<div class="row align-items-end" v-for="(item, index) in form.partita.sub_opponent" :key="'so_' + index" >
													<div class="col-2 form-group">
														<b-form-select v-model="item.time" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-for="(value, index) in minuti_with_intervallo" :value="value.minuto" :key="'smo_' + index">{{ value.label }}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_end" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'splouto_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-4 form-group">
														<b-form-select v-model="item.player_start" :disabled="!form.partita.enabled">
															<option :value="null">seleziona</option>
															<option v-if="value != null" v-for="(value, index) in form.partita.match_players_opponent" :value="value" :key="'splino_' + index">{{value.name}}</option>
														</b-form-select>
													</div>
													<div class="col-1 form-group">
														<b-button variant="outline-danger" @click="removeSubOpponent(item)" :block="true" :disabled="!form.partita.enabled"><i class="fa fa-trash fa-fw"></i></b-button>
													</div>
												</div>
												<b-button variant="outline-primary" @click="addSubOpponent()" :disabled="!form.partita.enabled"><i class="fa fa-plus fa-fw"></i> Aggiungi Sostituzione</b-button>

											</div>
										</div>
									</div>
								</div>

							</div>

						</div>
						<div class="row mt-3">
							<div class="col-sm-12 text-right">
								<!--b-button type="reset" variant="outline-primary" :disabled="!form.partita.enabled">Annulla</b-button-->
								<b-button type="submit" variant="primary" :disabled="!form.partita.enabled"><strong class="text-uppercase">Salva partita</strong></b-button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	.partiteaggiungi input[type="text"], .partiteaggiungi select {
		height: 40px !important;
	}
</style>

<script>
	import moment from 'moment'
	import Multiselect from 'vue-multiselect';
	import myDatepicker from 'vue-datepicker';
	import axios from 'axios';
	import { mapState } from 'vuex';
	import * as types from '@/store/types';
	var Auth = require('@/auth.js');
	import cache from '@/mixins/cache.js';

	export default {
		components: {
			Multiselect,
			'date-picker': 		myDatepicker,
			formazione: require('@/components/layout/formazione.vue').default,
			addGiocatore: require('@/components/layout/scout/add-giocatore.vue').default,
		},
		mixins: [ cache ],
		props: ['match', 'ruoli', 'team'],
		data () {
			return {
				loading: true,
				num_players: 11,
				label: '',
				n_players: 24,
				n_opponents: 24,
				opponents: [],
				players: [],
				options_players: [],
				selected_players: [],
				options_opponents: [],
				selected_opponents: [],		
				option: {
					type: 'min',
					week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
					month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
					format: 'DD-MM-YYYY HH:mm',
					color: {
						header: 	'#a80532',
						headerText: '#ffffff'
					},
					buttons: {
						ok: 'Ok',
						cancel: 'Cancel'
					},
				overlayOpacity: 0.5, // 0.5 as default
				dismissible: true // as true as default
			},
			limit: [
			],
			form: {
				partita: {
					enabled: null,
					players: {id:6, name:'11'},
					duration: 90,
					duration_first_half: 45,
					duration_second_half: 45,
					formation: 3,
					formation_opponent:	3,
					goals: [
						{time: '', player: [], autogoal: 0, penalty: 0}
					],
					goals_opponent:	[
						{time: '', player: [], autogoal: 0, penalty: 0}
					],
					amm: [
						{time: '', player: []}
					],
					amm_opponent: [
						{time: '', player: []}
					],
					es:	[
						{time: '', player: [], days: 1}
					],
					es_opponent: [
						{time: '', player: []}
					],
					sub: [
						{time: '', player_end: [], player_start: [], slot: null}
					],
					sub_opponent: [
						{time: '', player_end: [], player_start: [], slot: null}
					],
					home: null,
					match_players: [],
					match_players_opponent: [],
					match_players_opponent_shirt: [],
					match_players_role: [],
					match_players_opponent_role: [],
					match_players_shirt: [],			
					date: {
						time: ''
					},
					categoria: {
						id:'',
						name:''
					},
					recovery_first_half: null,
					recovery_second_half: null,
					team_home: null,
					team_away: null,
				},
			},
			modules: null,
			addPlayerHome: false,
			addPlayerAway: false,
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		formation_home: function() {
			var formations = [];
			var players = this.form.partita.match_players;
			if(players.length > 11) {
				players = players.slice(0, 11);
			}
			players.forEach(function(item, index) {
				if(this.form.partita.match_players_role[index]) {
					var player = {
						id: item.id,
						short_name: item.short_name,
						avatar: item.avatar,
						posizione_id: this.form.partita.match_players_role[index],
						maglia: this.form.partita.match_players_shirt[index],
					};
					var sub = this.form.partita.sub.find(el => el.player_end.id == item.id);
					if(sub) {
						player.playerOut = sub.time;
					}
					var amm = this.form.partita.amm.filter(el => el.player.id == item.id);
					if(amm.length) {
						var yellows = [];
						amm.forEach(el => {
							var yellow = el.time;
							yellows.push(yellow);

						});
						player.yellowCards = yellows;
					}
					var es = this.form.partita.es.find(el => el.player.id == item.id);
					if(es) {
						player.redCards = es.time;
					}
					var gg = this.form.partita.goals.filter(el => el.player.id == item.id);
					if(gg.length) {
						var goals = [];
						gg.forEach(el => {
							var goal = el.time;
							goals.push(goal);

						});
						player.goals = goals;
					}
					formations.push(player);
				}
			}.bind(this));
			return formations;
		},
		formation_away: function() {
			var formations = [];
			var players = this.form.partita.match_players_opponent;
			if(players.length > 11) {
				players = players.slice(0, 11);
			}
			players.forEach(function(item, index) {
				if(this.form.partita.match_players_opponent_role[index]) {
					var player = {
						id: item.id,
						short_name: item.short_name,
						avatar: item.avatar,
						posizione_id: this.form.partita.match_players_opponent_role[index],
						maglia: this.form.partita.match_players_opponent_shirt[index],
					};
					/** sub **/
					var sub = this.form.partita.sub_opponent.find(el => el.player_end.id == item.id);
					if(sub) {
						player.playerOut = sub.time;
					}
					var amm = this.form.partita.amm_opponent.filter(el => el.player.id == item.id);
					if(amm.length) {
						var yellows = [];
						amm.forEach(el => {
							var yellow = el.time;
							yellows.push(yellow);

						});
						player.yellowCards = yellows;
					}
					var es = this.form.partita.es_opponent.find(el => el.player.id == item.id);
					if(es) {
						player.redCards = es.time;
					}
					var gg = this.form.partita.goals_opponent.filter(el => el.player.id == item.id);
					if(gg.length) {
						var goals = [];
						gg.forEach(el => {
							var goal = el.time;
							goals.push(goal);

						});
						player.goals = goals;
					}
					formations.push(player);
				}
			}.bind(this));
			return formations;
		},
		formation_home_no_position: function() {
			var formations = [];
			var players = this.form.partita.match_players;
			if(players.length > 11) {
				players = players.slice(0, 11);
			}
			players.forEach(function(item, index) {
				if(!this.form.partita.match_players_role[index]) {
					var player = {
						id: item.id,
						short_name: item.short_name,
						avatar: item.avatar,
						posizione_id: this.form.partita.match_players_role[index],
						maglia: this.form.partita.match_players_shirt[index],
					};
					/** sub **/
					var sub = this.form.partita.sub.find(el => el.player_end.id == item.id);
					if(sub) {
						player.playerOut = sub.time;
					}
					var amm = this.form.partita.amm.filter(el => el.player.id == item.id);
					if(amm.length) {
						var yellows = [];
						amm.forEach(el => {
							var yellow = el.time;
							yellows.push(yellow);

						});
						player.yellowCards = yellows;
					}
					var es = this.form.partita.es.find(el => el.player.id == item.id);
					if(es) {
						player.redCards = es.time;
					}
					var gg = this.form.partita.goals.filter(el => el.player.id == item.id);
					if(gg.length) {
						var goals = [];
						gg.forEach(el => {
							var goal = el.time;
							goals.push(goal);

						});
						player.goals = goals;
					}
					formations.push(player);
				}
			}.bind(this));
			return formations;
		},
		formation_away_no_position: function() {
			var formations = [];
			var players = this.form.partita.match_players_opponent;
			if(players.length > 11) {
				players = players.slice(0, 11);
			}
			players.forEach(function(item, index) {
				if(!this.form.partita.match_players_opponent_role[index]) {
					var player = {
						id: item.id,
						short_name: item.short_name,
						avatar: item.avatar,
						posizione_id: this.form.partita.match_players_opponent_role[index],
						maglia: this.form.partita.match_players_opponent_shirt[index],
					};
					/** sub **/
					var sub = this.form.partita.sub_opponent.find(el => el.player_end.id == item.id);
					if(sub) {
						player.playerOut = sub.time;
					}
					var amm = this.form.partita.amm_opponent.filter(el => el.player.id == item.id);
					if(amm.length) {
						var yellows = [];
						amm.forEach(el => {
							var yellow = el.time;
							yellows.push(yellow);

						});
						player.yellowCards = yellows;
					}
					var es = this.form.partita.es_opponent.find(el => el.player.id == item.id);
					if(es) {
						player.redCards = es.time;
					}
					var gg = this.form.partita.goals_opponent.filter(el => el.player.id == item.id);
					if(gg.length) {
						var goals = [];
						gg.forEach(el => {
							var goal = el.time;
							goals.push(goal);

						});
						player.goals = goals;
					}
					formations.push(player);
				}
			}.bind(this));
			return formations;
		},
		getSubstitutionsHome() {
			var sub = [];
			this.form.partita.sub.forEach(function(item) {
				if(item.player_start && item.player_start.id) {
					var findIndex = this.form.partita.match_players.findIndex(el => el.id == item.player_start.id);
					if(findIndex) {
						var player = {
							id: this.form.partita.match_players[findIndex].id,
							short_name: this.form.partita.match_players[findIndex].short_name,
							avatar: this.form.partita.match_players[findIndex].avatar,
							posizione_id: this.form.partita.match_players_role[findIndex],
							maglia: this.form.partita.match_players_shirt[findIndex],
							playerIn: item.time
						};
						var amm = this.form.partita.amm.filter(el => el.player.id == item.player_start.id);
						if(amm.length) {
							var yellows = [];
							amm.forEach(el => {
								var yellow = el.time;
								yellows.push(yellow);

							});
							player.yellowCards = yellows;
						}
						var es = this.form.partita.es.find(el => el.player.id == item.player_start.id);
						if(es) {
							player.redCards = es.time;
						}
						var gg = this.form.partita.goals.filter(el => el.player.id == item.player_start.id);
						if(gg.length) {
							var goals = [];
							gg.forEach(el => {
								var goal = el.time;
								goals.push(goal);

							});
							player.goals = goals;
						}
						sub.push(player);
					}
				}
			}.bind(this));
			return sub;
		},
		getSubstitutionsAway() {
			var sub = [];
			this.form.partita.sub_opponent.forEach(function(item) {
				if(item.player_start && item.player_start.id) {
					var findIndex = this.form.partita.match_players_opponent.findIndex(el => el.id == item.player_start.id);
					if(findIndex) {
						var player = {
							id: this.form.partita.match_players_opponent[findIndex].id,
							short_name: this.form.partita.match_players_opponent[findIndex].short_name,
							avatar: this.form.partita.match_players_opponent[findIndex].avatar,
							posizione_id: this.form.partita.match_players_opponent_role[findIndex],
							maglia: this.form.partita.match_players_opponent_shirt[findIndex],
							playerIn: item.time
						};
						var amm = this.form.partita.amm_opponent.filter(el => el.player.id == item.player_start.id);
						if(amm.length) {
							var yellows = [];
							amm.forEach(el => {
								var yellow = el.time;
								yellows.push(yellow);

							});
							player.yellowCards = yellows;
						}
						var es = this.form.partita.es_opponent.find(el => el.player.id == item.player_start.id);
						if(es) {
							player.redCards = es.time;
						}
						var gg = this.form.partita.goals_opponent.filter(el => el.player.id == item.player_start.id);
						if(gg.length) {
							var goals = [];
							gg.forEach(el => {
								var goal = el.time;
								goals.push(goal);

							});
							player.goals = goals;
						}
						sub.push(player);
					}
				}
			}.bind(this));
			return sub;
		},

		filtered_ruoli() {
			return this.ruoli.filter(ruolo => ruolo.statsbomb_id);
		},
		opt_modules: function() {
			var array_opt = [];
			for(var i in this.modules) {
				var modulo = this.modules[i];
				if(modulo.players == this.num_players) {
					var obj = {
						name: modulo.name,
						id: modulo.id,
					}
					array_opt.push(obj);
				}
			}
			return array_opt;
		},
		minuti: function() {

			var minuti = [];
			var minuti_regolari_primo_tempo = parseInt(this.form.partita.duration_first_half);
			var minuto = 1;
			
			const startStopStatus   = this.form.partita.start_stop_status
			let i_1_tempo = 1
			let i_2_tempo = 1
			let i_1_tempo_suppl = 1
			let i_2_tempo_suppl = 1
			let restartFromMinute = null
			if( this.form.partita.start_stop_status == 'restart' ){
				
				restartFromMinute = +this.form.partita.restarted_from_minute
				
				if( restartFromMinute <= 45 ){
					i_1_tempo = restartFromMinute
				} 
				else if( restartFromMinute > 45 && restartFromMinute <= 90 ){
					i_2_tempo = restartFromMinute - 45
				} 
				else if( restartFromMinute > 90 && restartFromMinute <= 105 ){
					i_1_tempo_suppl = restartFromMinute - 90
				}
				else if( restartFromMinute > 105 && restartFromMinute <= 120 ){
					i_2_tempo_suppl = restartFromMinute - 105
				}
			}

			var primo_tempo = parseInt(minuti_regolari_primo_tempo) 
			+ (this.form.partita.recovery_first_half > 0 ? parseInt(this.form.partita.recovery_first_half) : 0)
			+ (( startStopStatus == 'restart' && restartFromMinute <= 45 ) ? i_1_tempo : 0 );
			for(var i = i_1_tempo; i <= primo_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 1T',
				}
				minuti.push(min);
				minuto ++;
			}
			var minuti_regolari_sec_tempo = parseInt(this.form.partita.duration_second_half);
			var sec_tempo = parseInt(minuti_regolari_sec_tempo) 
			+ (this.form.partita.recovery_second_half > 0 ? parseInt(this.form.partita.recovery_second_half) : 0)
			+ (( startStopStatus == 'restart' && restartFromMinute > 45 && restartFromMinute <= 90 ) ? i_2_tempo : 0 );
			for(var i = i_2_tempo; i <= sec_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 2T',
				}
				minuti.push(min);
				minuto ++;
			}
			if(this.form.partita.has_supplementari) {
				var minuti_regolari_primo_tempo_suppl = parseInt(this.form.partita.duration_suppl_first_half);
				var primo_tempo_suppl = parseInt(minuti_regolari_primo_tempo_suppl) 
				+ (this.form.partita.recovery_first_half_suppl > 0 ? parseInt(this.form.partita.recovery_first_half_suppl) : 0)
				+ (( startStopStatus == 'restart' && restartFromMinute > 90 && restartFromMinute <= 105 ) ? i_1_tempo_suppl : 0 );
				for(var i = i_1_tempo_suppl; i <= primo_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 1T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
				var minuti_regolari_sec_tempo_suppl = parseInt(this.form.partita.duration_suppl_second_half);
				var sec_tempo_suppl = parseInt(minuti_regolari_sec_tempo_suppl) 
				+ (this.form.partita.recovery_second_half_suppl > 0 ? parseInt(this.form.partita.recovery_second_half_suppl) : 0)
				+ (( startStopStatus == 'restart' && restartFromMinute > 105 && restartFromMinute <= 120 ) ? i_2_tempo_suppl : 0 );
				for(var i = i_2_tempo_suppl; i <= sec_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 2T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
			}
			return minuti;
		},

		minuti_with_intervallo: function() {
			var minuti = [];
			var minuti_regolari_primo_tempo = parseInt(this.form.partita.duration_first_half);
			var minuto = 1;

			const startStopStatus   = this.form.partita.start_stop_status
			let i_1_tempo = 1
			let i_2_tempo = 1
			let i_1_tempo_suppl = 1
			let i_2_tempo_suppl = 1
			let restartFromMinute = null
			if( this.form.partita.start_stop_status == 'restart' ){
				
				restartFromMinute = +this.form.partita.restarted_from_minute
				
				if( restartFromMinute <= 45 ){
					i_1_tempo = restartFromMinute
				} 
				else if( restartFromMinute > 45 && restartFromMinute <= 90 ){
					i_2_tempo = restartFromMinute - 45
				} 
				else if( restartFromMinute > 90 && restartFromMinute <= 105 ){
					i_1_tempo_suppl = restartFromMinute - 90
				}
				else if( restartFromMinute > 105 && restartFromMinute <= 120 ){
					i_2_tempo_suppl = restartFromMinute - 105
				}
			}

			var primo_tempo = parseInt(minuti_regolari_primo_tempo) 
			+ (this.form.partita.recovery_first_half > 0 ? parseInt(this.form.partita.recovery_first_half) : 0)
			+ (( startStopStatus == 'restart' && restartFromMinute <= 45 ) ? i_1_tempo : 0 );

			for(var i = i_1_tempo; i <= primo_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 1T',
				}
				minuti.push(min);
				minuto ++;
			}
			var min = {
				minuto: minuto - 0.5,
				label: 'Intervallo - 1T',
			}
			minuti.push(min);
			var minuti_regolari_sec_tempo = parseInt(this.form.partita.duration_second_half);
			var sec_tempo = parseInt(minuti_regolari_sec_tempo) 
			+ (this.form.partita.recovery_second_half > 0 ? parseInt(this.form.partita.recovery_second_half) : 0)
			+ (( startStopStatus == 'restart' && restartFromMinute > 45 && restartFromMinute <= 90 ) ? i_2_tempo : 0 );
			for(var i = i_2_tempo; i <= sec_tempo; i++) {
				var min = {
					minuto: minuto,
					label: i + ' - 2T',
				}
				minuti.push(min);
				minuto ++;
			}
			if(this.form.partita.has_supplementari) {
				
				var min = {
					minuto: minuto - 0.5,
					label: 'Intervallo - 2T',
				}
				minuti.push(min);

				var minuti_regolari_primo_tempo_suppl = parseInt(this.form.partita.duration_suppl_first_half);
				var primo_tempo_suppl = parseInt(minuti_regolari_primo_tempo_suppl) 
				+ (this.form.partita.recovery_first_half_suppl > 0 ? parseInt(this.form.partita.recovery_first_half_suppl) : 0)
				+ (( startStopStatus == 'restart' && restartFromMinute > 90 && restartFromMinute <= 105 ) ? i_1_tempo_suppl : 0 );
				for(var i = i_1_tempo_suppl; i <= primo_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 1T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
				var min = {
					minuto: minuto - 0.5,
					label: 'Intervallo - 1T suppl',
				}
				minuti.push(min);
				var minuti_regolari_sec_tempo_suppl = parseInt(this.form.partita.duration_suppl_second_half);
				var sec_tempo_suppl = parseInt(minuti_regolari_sec_tempo_suppl) 
				+ (this.form.partita.recovery_second_half_suppl > 0 ? parseInt(this.form.partita.recovery_second_half_suppl) : 0)
				+ (( startStopStatus == 'restart' && restartFromMinute > 105 && restartFromMinute <= 120 ) ? i_2_tempo_suppl : 0 );
				for(var i = i_2_tempo_suppl; i <= sec_tempo_suppl; i++) {
					var min = {
						minuto: minuto,
						label: i + ' - 2T suppl',
					}
					minuti.push(min);
					minuto ++;
				}
			}
			return minuti;
		},
	},

	created() {
		for(var i = 0; i < this.n_players; i++) {
			this.selected_players[i] = null;
		}
		for(var i = 0; i < this.n_opponents; i++) {
			this.selected_opponents[i] = null;
		}
		this.getPlayersHome();
		this.getPlayersAway();
		this.getFormations();
		this.get();
	},

	methods: {
		playerAdded(team_id) {
			if(team_id == this.match.home_id) {
				this.getPlayersHome();
				this.addPlayerHome = false;
			} else if(team_id == this.match.away_id) {
				this.getPlayersAway();
				this.addPlayerAway = false;
			}
		},
		getFormations: function() { 
			var formations = this.getCache('formations');
			if (formations) {
				this.modules = formations;
			} else {
				this.$http.get(this.$store.state.apiEndPoint + '/formations').then((response) => {
					this.modules = response.data;
					this.setCache('formations', this.modules);
				});
			}
		},

		updateDuration: function( type ) {

			switch ( type ) {
			case 'from_total':
				if( this.form.partita.duration !== '' && this.form.partita.duration !== null && this.form.partita.duration !== undefined && this.form.partita.duration != 0 ){
					this.form.partita.duration_first_half  = ( Math.floor(+this.form.partita.duration / 2) )
					this.form.partita.duration_second_half = ( Math.floor(+this.form.partita.duration / 2 )) + ( +this.form.partita.duration % 2 )
				}
				break;
			case 'from_total_suppl':
				if( this.form.partita.duration_suppl !== '' && this.form.partita.duration_suppl !== null && this.form.partita.duration_suppl !== undefined && this.form.partita.duration_suppl != 0 ){
					this.form.partita.duration_suppl_first_half  = ( Math.floor(+this.form.partita.duration_suppl / 2) )
					this.form.partita.duration_suppl_second_half = ( Math.floor(+this.form.partita.duration_suppl / 2 )) + ( +this.form.partita.duration_suppl % 2 )
				}
				break;
			case 'from_partial':
				let firstHalf = 0
				if( this.form.partita.duration_first_half !== '' && this.form.partita.duration_first_half !== null && this.form.partita.duration_first_half !== undefined && this.form.partita.duration_first_half != 0 ){
					firstHalf = +this.form.partita.duration_first_half
				}

				let secondHalf = 0
				if( this.form.partita.duration_second_half !== '' && this.form.partita.duration_second_half !== null && this.form.partita.duration_second_half !== undefined && this.form.partita.duration_second_half != 0 ){
					secondHalf = +this.form.partita.duration_second_half
				}

				this.form.partita.duration  = firstHalf + secondHalf

				break;
			case 'from_partial_suppl':
				let firstHalfSuppl = 0
				if( this.form.partita.duration_suppl_first_half !== '' && this.form.partita.duration_suppl_first_half !== null && this.form.partita.duration_suppl_first_half !== undefined && this.form.partita.duration_suppl_first_half != 0 ){
					firstHalfSuppl = +this.form.partita.duration_suppl_first_half
				}

				let secondHalfSuppl = 0
				if( this.form.partita.duration_suppl_second_half !== '' && this.form.partita.duration_suppl_second_half !== null && this.form.partita.duration_suppl_second_half !== undefined && this.form.partita.duration_suppl_second_half != 0 ){
					secondHalfSuppl = +this.form.partita.duration_suppl_second_half
				}

				this.form.partita.duration_suppl  = firstHalfSuppl + secondHalfSuppl
				break;
			}

		},

		handleSubmit: function () {
			this.$http.post(this.$store.state.apiEndPoint + '/scout/partita/formazione/update/' + this.match.id, this.form).then((response) => {

				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$emit('matchUpdated');
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});	
		},

		elimina() {
			if(confirm("Sei sicuro di voler eliminare la partita selezionata?")) {
				axios.post(`/football/partita/elimina`, { partita_id: this.match.id })
				.then((res) => {		
					this.$store.commit(types.ALERTS_PUSH, { msg: 'eliminazione avvenuta con successo', status: 1 });
					this.updateSqualificati();
					this.$router.push({ name: 'football_partite'});
				}).catch((err) => {
				});
			}
		},

		addGoal: function () {
			this.form.partita.goals.push({ time: '', player: [], autogoal: 0, penalty: 0 });
		},
		removeGoal: function (item) {
			var index = this.form.partita.goals.indexOf(item);
			this.form.partita.goals.splice(index, 1);
		},
		addGoalOpponent: function () {
			this.form.partita.goals_opponent.push({ time: '', player: [], autogoal: 0, penalty: 0 });
		},
		removeGoalOpponent: function (item) {
			var index = this.form.partita.goals_opponent.indexOf(item);
			this.form.partita.goals_opponent.splice(index, 1);
		},
		addAmm: function () {
			this.form.partita.amm.push({ time: '', player: [] });
		},
		removeAmm: function (item) {
			var index = this.form.partita.amm.indexOf(item);
			this.form.partita.amm.splice(index, 1);
		},
		addAmmOpponent: function () {
			this.form.partita.amm_opponent.push({ time: '', player: [] });
		},
		removeAmmOpponent: function (item) {
			var index = this.form.partita.amm_opponent.indexOf(item);
			this.form.partita.amm_opponent.splice(index, 1);
		},
		addEs: function () {
			this.form.partita.es.push({ time: '', player: [], days: 1 });
		},
		removeEs: function (item) {
			var index = this.form.partita.es.indexOf(item);
			this.form.partita.es.splice(index, 1);
		},
		addEsOpponent: function () {
			this.form.partita.es_opponent.push({ time: '', player: [] });
		},
		removeEsOpponent: function (item) {
			var index = this.form.partita.es_opponent.indexOf(item);
			this.form.partita.es_opponent.splice(index, 1);
		},
		addSub: function () {
			this.form.partita.sub.push({ time: '', player_end: [], player_start: [] });
		},
		removeSub: function (item) {
			var index = this.form.partita.sub.indexOf(item);
			this.form.partita.sub.splice(index, 1);
		},
		addSubOpponent: function () {
			this.form.partita.sub_opponent.push({ time: '', player_end: [], player_start: [] });
		},
		removeSubOpponent: function (item) {
			var index = this.form.partita.sub_opponent.indexOf(item);
			this.form.partita.sub_opponent.splice(index, 1);
		},
		getRuolo: function(posizione_id) {
			return this.ruoli.find(item => item.id == posizione_id);
		},
		get: function() {
			this.form.partita.enabled = this.match.enabled;
			this.form.partita.day = this.match.giornata;

			if(this.match.tempi && this.match.tempi.length) {
				this.form.partita.duration_first_half = this.match.tempi[0].durata_totale - this.match.tempi[0].recupero;
				this.form.partita.duration_second_half = this.match.tempi[1].durata_totale - this.match.tempi[1].recupero;
				this.form.partita.duration = this.form.partita.duration_first_half + this.form.partita.duration_second_half;
			}

			if(this.match.categoria) {
				this.form.partita.categoria = {
					name: this.match.categoria.nome,
					id: this.match.categoria_id,
				};
			}

			this.form.partita.date.time = moment(this.match.data).format("DD-MM-YYYY HH:mm:ss");
			this.form.partita.home = this.match.home_id == this.team.id ? 0 : 1;

			this.form.partita.competition_id = this.match.competizione_id;
			this.form.partita.recovery_first_half = this.match.tempi && this.match.tempi[0] ? this.match.tempi[0].recupero : 0;
			this.form.partita.recovery_second_half = this.match.tempi && this.match.tempi[1] ? this.match.tempi[1].recupero : 0;

			this.form.partita.team_home = this.match.home_team;
			this.form.partita.team_away = this.match.away_team;

			this.form.partita.formation = this.match.home_modulo_id;
			this.form.partita.formation_opponent = this.match.away_modulo_id;

			var formazione = this.match.persona_rel.map(obj => (
				{ ...obj,
					ruolo: this.getRuolo(obj.posizione_id)}
					));

			var players_home = _.sortBy(formazione.filter(item => item.team_id == this.match.home_id), ['ruolo.sigla_tools']);
			players_home = _.sortBy(players_home, ['partita_persona_tipo_glo_id']);
				/* player */
			var k = 0;					
			players_home.forEach((el, index) => {
				this.form.partita.match_players[k] = {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona.id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome }
				this.form.partita.match_players_shirt[k] = el.maglia;
				this.form.partita.match_players_role[k] = el.posizione_id;		
				k++;	
			});
			if(k > 24) {
				this.n_players = k;
			}

				/* player avversario */
			var players_away = _.sortBy(formazione.filter(item => item.team_id == this.match.away_id), ['ruolo.sigla_tools']);
			players_away = _.sortBy(players_away, ['partita_persona_tipo_glo_id']);
			k = 0;
			players_away.forEach((el, index) => {
				this.form.partita.match_players_opponent[k] = {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona.id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome }
				this.form.partita.match_players_opponent_shirt[k] = el.maglia;
				this.form.partita.match_players_opponent_role[k] = el.posizione_id;
				k++;
			});

		//	this.getPlayers();

			if(k > 24) {
				this.n_opponents = k;
			}

				/* espulsioni */
			k = 0;
			this.match.eventi.filter((item) => item.partita_evento_tipo_glo_id == 6 && item.team_id == this.match.home_id).forEach((el, index) => {
				this.form.partita.es[k] = { time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome }};
				k++;
			});

			k = 0;
			this.match.eventi.filter((item) => item.partita_evento_tipo_glo_id == 6 && item.team_id == this.match.away_id).forEach((el, index) => {
				this.form.partita.es_opponent[k] = {time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome }};
				k++;
			});

				/* ammonizioni */
			k = 0;
			this.match.eventi.filter((item) => item.partita_evento_tipo_glo_id == 5 && item.team_id == this.match.home_id).forEach((el, index) => {
				this.form.partita.amm[k] = { time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome }};
				k++;
			});

			k = 0;
			this.match.eventi.filter((item) => item.partita_evento_tipo_glo_id == 5 && item.team_id == this.match.away_id).forEach((el, index) => {
				this.form.partita.amm_opponent[k] = {time: el.minuto, player: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome }};
				k++;
			});

				/* goals */

			k = 0;						
			this.match.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 1 || item.partita_evento_tipo_glo_id == 3 || item.partita_evento_tipo_glo_id == 4 ) && item.team_id == this.match.home_id && item.minuto != 1000).forEach((el, index) => {							
				this.form.partita.goals[k] = {time: el.minuto, player: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome}, autogoal: el.partita_evento_tipo_glo_id == 4 ? 1 : 0, penalty: el.partita_evento_tipo_glo_id == 3 ? 1 : 0 };
				k++;
			});

			k = 0;
			this.match.eventi.filter((item) => (item.partita_evento_tipo_glo_id == 1 || item.partita_evento_tipo_glo_id == 3 || item.partita_evento_tipo_glo_id == 4 ) && item.team_id == this.match.away_id && item.minuto != 1000).forEach((el, index) => {	
				this.form.partita.goals_opponent[k] = {time: el.minuto, player: el.persona ? {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome } : -1, autogoal: el.partita_evento_tipo_glo_id == 4 ? 1 : 0, penalty: el.partita_evento_tipo_glo_id == 3 ? 1 : 0 };
				k++;
			});

				/* sostituzioni */
			k = 0;
			this.match.eventi.filter((item) => item.partita_evento_tipo_glo_id == 8 && item.team_id == this.match.home_id).forEach((el, index) => {
				var event_in = null;
				var id = el.info ? el.info.valore_numerico : null;
				if(id) {
					event_in = this.match.eventi.find((obj) => obj.id == id);
				}
				this.form.partita.sub[k] = {
					time: el.intervallo ? el.minuto + 0.5 : el.minuto,
					player_end: { name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome },
					player_start: { name: event_in ? event_in.persona.nome + ' ' + event_in.persona.cognome : null, id: event_in ? event_in.persona_id : null, avatar: event_in ? event_in.persona.avatar_url : null, short_name: event_in ? event_in.persona.nome.charAt(0) + '. ' + event_in.persona.cognome : null },
					slot: null,
				};
				k++;
			});

			k = 0;
			this.match.eventi.filter((item) => item.partita_evento_tipo_glo_id == 8 && item.team_id == this.match.away_id).forEach((el, index) => {
				var event_in = null;
				var id = el.info ? el.info.valore_numerico : null;
				if(id) {
					event_in = this.match.eventi.find((obj) => obj.id == id);
				}
				this.form.partita.sub_opponent[k] = {
					time: el.intervallo ? el.minuto + 0.5 : el.minuto,
					player_end: {name: el.persona.nome + ' ' + el.persona.cognome, id: el.persona_id, avatar: el.persona.avatar_url, short_name: el.persona.nome.charAt(0) + '. ' + el.persona.cognome },
					player_start: {name: event_in ? event_in.persona.nome + ' ' + event_in.persona.cognome : null, id:  event_in ? event_in.persona_id : null, avatar: event_in ? event_in.persona.avatar_url : null, short_name: event_in ? event_in.persona.nome.charAt(0) + '. ' + event_in.persona.cognome : null },
					slot: null,
				};
				k++;
			});	
		},

		getPlayersHome: function() {
			axios.get(`/football/partita/opponents/` + this.match.id + `/` + this.match.home_id)
			.then((res) => {	
				this.players = res.data.map(item => ({
					name: item.nome + ' ' + item.cognome,
					id: item.id,
					shirt: item.last_carriera_sportiva.maglia,
					avatar: item.avatar_url,
					short_name: item.nome.charAt(0) + '. ' + item.cognome
				//	persona: item,
				}));

				this.options_players = this.players.slice();

				var first = {
					id: null,
					name: 'Seleziona',
				}

				this.options_players.unshift(first);

				for(var k in this.form.partita.match_players) {
					var item = this.form.partita.match_players[k];
					for(var i in this.options_players) {
						var option = this.options_players[i];
						if(option.id == item.id) {
							this.options_players.splice(i, 1);
							break;
						}
					}
					this.selected_players[k] = item.id;
				}
			}).catch((err) => {
			});
		},

		getPlayersAway: function() {
			axios.get(`/football/partita/opponents/` + this.match.id + `/` + this.match.away_id)
			.then((res) => {	
				this.opponents = res.data.map(item => ({
					name: item.nome + ' ' + item.cognome,
					id: item.id,
					shirt: item.last_carriera_sportiva.maglia,
					avatar: item.avatar_url,
					short_name: item.nome.charAt(0) + '. ' + item.cognome
				//	persona: item,
				}));

				this.options_opponents = this.opponents.slice();

				var first = {
					id: null,
					name: 'Seleziona',
				}

				this.options_opponents.unshift(first);

				for(var k in this.form.partita.match_opponents) {
					var item = this.form.partita.match_opponents[k];
					for(var i in this.options_opponents) {
						var option = this.options_opponents[i];
						if(option.id == item.id) {
							this.options_opponents.splice(i, 1);
							break;
						}
					}
					this.selected_opponents[k] = item.id;
				}
			}).catch((err) => {
			});
		},

		selectPlayer(item, key) {
			var id = key.substr(6);
			var old = this.selected_players[id];
			if(old) {
				for(var i in this.players) {
					if(this.players[i].id == old) {
						this.options_players.push(this.players[i]);
					}
				}
			}
			if(item.id) {
				for(var i in this.options_players) {
					var option = this.options_players[i];
					if(option.id == item.id) {
						this.options_players.splice(i, 1);
						this.selected_players[id] = item.id;
						break;
					}
				}
				this.form.partita.match_players_shirt[id] = item.shirt;
				this.form.partita.match_players_role[id] = null;
			} else {
				this.selected_players[id] = null;
			}
		}, 

		selectOpponent(item, key) {
			var id = key.substr(7);
			var old = this.selected_opponents[id];
			if(old) {
				for(var i in this.opponents) {
					if(this.opponents[i].id == old) {
						this.options_opponents.push(this.opponents[i]);
					}
				}
			}
			if(item.id && item.id > 0) {
				for(var i in this.options_opponents) {
					var option = this.options_opponents[i];
					if(option.id == item.id) {
						this.options_opponents.splice(i, 1);
						this.selected_opponents[id] = item.id;
						break;
					}
				}
				this.form.partita.match_players_opponent_shirt[id] = item.shirt;
				this.form.partita.match_players_opponent_role[id] = null;
			} else {
				this.selected_opponents[id] = null;
			}
		},

		addPLayer() {
			this.n_players++;
			this.selected_players[this.n_players - 1] = null;
		}, 

		addOpponent() {
			this.n_opponents++;
			this.selected_opponents[this.n_opponents - 1] = null;
		},
	},
}
</script>

<template>
	<section class="xphy-rank">
		<b-overlay :show="loading" no-wrap></b-overlay>
		
		<b-select class="mb-2" v-model="activeRole" @change="selectRole">
			<b-select-option v-for="role in roles" :value="role.value">
				{{ role.label }}
			</b-select-option>
		</b-select>

		<b-card title="XPhy Rank" :no-body="isWidget ? true : false">
            <b-tabs v-model="activeIndex" @activate-tab="onTabActivate">
				<b-tab v-for="(tab, index) in tabs" :key="tab.id" :title="tab.label" @click="selectTab(index, true)">
					
                    <b-table-simple class="text-center mb-0" :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 100) + 'px' : '70vh'" striped responsive fixed>
						
						<b-thead>
							<b-tr>
								<b-th>
                                    Rank
                                </b-th>
								<b-th>
                                    Giocatore
                                </b-th>
								<b-th>
                                    XPhy
                                </b-th>
                            </b-tr>
					    </b-thead>
                        <b-tbody>
							<b-tr
								v-for="rank,key in rows"
								role="button"
								@click="openBlankUrl($router.resolve(`/v2/player/${rank.persona.id}`).href , '_blank' )"
								:key="rank.id"
							>
                                <b-td>
                                   {{ key + 1 }}°
                                </b-td>
                                <b-td class="d-flex justify-content-between align-items-center">
									<div class="px-2">
										<LazyImage :src="rank.persona.avatar_url" width="32" />
									</div>
									<div class="flex-grow-1">
										{{ rank.persona.nome }} {{ rank.persona.cognome }}
									</div>
                                </b-td>
                                <b-td>
                                    {{ rank.xPhy }}
                                </b-td>
                            </b-tr>
                        </b-tbody>

			        </b-table-simple>

		        </b-tab>

				<b-tab title="Aggiungi" @click="showCompetitionSelectModal"></b-tab>
	        </b-tabs>
        </b-card>

		<!-- Select tab competition modal  -->
		<b-modal
			ref="selectCompetition" 
			title="Seleziona competizioni da visualizzare" 
			@ok="updateTabs"
		>
			<div>
				<div class="mb-2"> Cerca competizione </div>

				<AutocompleteCompetition
					:suggestions="competitions" 
					ref="autocompleteCompetitions" 
					@select="selectCompetitionEvent"
				>
				</AutocompleteCompetition>

				<b-table-simple class="text-center mb-0" striped responsive fixed :sticky-header="'60vh'">
					<b-thead>
						<b-tr>
							<b-th> Competizione </b-th>
							<b-th> Azioni </b-th>
						</b-tr>
					</b-thead>
					
					<b-tbody>
						<b-tr v-for="competition,index in tabs">
							<b-td> {{ competition.label }} </b-td>
							<b-td> 
								<b-button
									v-if="index !== activeIndex"
									size="sm"
									@click="unsetCompetition(competition.id)"
								>
									<i class="fa fa-trash" aria-hidden="true"></i>
								</b-button>
							</b-td>
						</b-tr>
					</b-tbody>
					
				</b-table-simple>
			</div>
		</b-modal>
    </section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
import { mapState } from 'vuex';
var Auth = require('@/auth.js');

export default {
	mixins: [ pageBase ],
	components: {
		AutocompleteCompetition: require('@/components/layout/autocomplete_competition.vue').default,
	},
	props: ['config', 'isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function() {
		return {
			loading: false,
			tabs: [],
			rows: [],
			activeIndex: 0,
			activeRole: '',
			roles: [
				{ value: 'central_defender', label: 'Difensore Centrale' },
				{ value: 'right_and_left_back', label: 'Basso a Destra/Sinistra' },
				{ value: 'defensive_midfielder', label: 'Centrocampista Difensivo' },
				{ value: 'central_midfielder', label: 'Centrocampista Centrale' },
				{ value: 'offensive_midfielder', label: 'Centrocampista Offensivo' },
				{ value: 'right_and_left_winger', label: 'Ala Destra/Sinistra' },
				{ value: 'striker', label: 'Attaccante' },
			]
		}
	},
	mounted() {
		this.setTabs();
		this.fetchMainCompetitions();
	},
	computed: {
		user: function() {
			return Auth.user;
		},
		...mapState( 'scout', ['competitions'] ),
	},
	watch: {
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},
	},
	methods: {
		unsetCompetition( competitionId ) {
			/** Get active competition index before unset */
			const activeCompetition = this.tabs[this.activeIndex].id
			
			/** Unset removed tab competition */
			this.tabs = this.tabs.filter( ( tab ) => tab.id !== competitionId )
			
			/** Get newActiveIndex */
			const newActiveIndex = this.tabs.findIndex( ( tab ) => tab.id === activeCompetition )
	
			/** Update WidgetConfig */
			setTimeout( () => {
				this.updateTabs();
				this.selectTab(newActiveIndex, false)
			}, 100 );
		},
		selectCompetitionEvent(competition) {
			const competitionExists = this.tabs.find( ( tab ) => tab.id === competition.id );
			
			if( !!!competitionExists ){				
				this.tabs.push({
					id: competition.id,
					label: `${competition.nome} [${competition.paese.sigla}]`
				})
			}
		},
		onTabActivate(newIndex, oldIndex, event ) {
			/** Prevent change on last tab click ("Aggiungi") */
			if (newIndex === this.tabs.length) {
				event.preventDefault();
			}else{
				this.activeIndex = newIndex;
			}
		},
		showCompetitionSelectModal() {
			this.$refs.selectCompetition.show();
		},
		selectRole(role) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'activeRole', role);
				this.activeRole = role
				this.fetchXphyRank();
			}
		},
		selectTab(index, fetchXphy) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'activeTab', index);
				this.activeIndex = index
				
				if( fetchXphy ){
					this.fetchXphyRank();
				}
			}
		},
		updateTabs() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'tabs', this.tabs);	
			}
		},
		refreshData: function() {
			this.tabs = [];
			this.fetchMainCompetitions();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		fetchMainCompetitions: function() {
			this.activeIndex = 0;
			this.loading = true;

			if( this.tabs.length === 0 ){

				this.$http.get( this.$store.state.apiEndPoint + '/scout/competizioni_principali' )
					.then( ( { body: mainCompetitions } ) => {					

						this.tabs = mainCompetitions.map( ( mainCompetition ) => (({
							id: mainCompetition.id,
							label: mainCompetition.nome
						})))
						
						this.updateTabs();

						this.loading = false;

						_.delay( function() {
							this.setTabActive();
							this.setRoleActive();
							this.fetchXphyRank();
						}.bind(this), 100 );
					})

			}else{

				_.delay( function() {
					this.setTabActive();
					this.setRoleActive();
					this.fetchXphyRank();
				}.bind(this), 100 );

			}
			
		},
		fetchXphyRank: function() {
			this.rows = [];
			this.loading = true;
			
			const competitionId = this.tabs[this.activeIndex].id
			const roleId = this.activeRole

			this.$http.get( `${this.$store.state.apiEndPoint}/scout/xphy_rank?competition=${competitionId}&role=${roleId}`)
				.then( ( {body: xPhyRank} ) => {								
					this.rows = xPhyRank
					this.loading = false;
				})
		},
		setTabs() {
			this.tabs = this.configWidget && this.configWidget.tabs != undefined ? this.configWidget.tabs : [];
		},
		setTabActive() {
			this.activeIndex = this.configWidget && this.configWidget.activeTab != undefined ? this.configWidget.activeTab : 0;
		},
		setRoleActive() {
			this.activeRole = this.configWidget.activeRole ?? this.config.defaultRole
		},
	}
};
</script>

<style>

.xphy-rank .nav.nav-tabs{
	overflow-x: scroll;
	flex-wrap: nowrap;
	padding-bottom: 5px;
}

</style>
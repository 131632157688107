<template>
	<div style="min-height:80vh">
		<b-overlay :show="loadingPlayer || loadingTeam">
			<b-row align-h="center" class="my-3">
				<b-col cols="6">
					<b-input-group>
						<b-form-input v-model="form.name" placeholder="Giocatore o Squadra" v-on:keyup.enter="searchAll"></b-form-input>
						<b-input-group-append>
							<b-button type="button" variant="primary" @click.prevent="searchAll"><i class="fa fa-search" aria-hidden="true"></i></b-button>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
			<div class="text-center">
				<b-button v-b-toggle.collapse-1 variant="outline-primary" size="sm">Ricerca avanzata</b-button>
				<b-collapse id="collapse-1" class="mt-2" v-model="adv_shown">
					<b-card>
						<b-row align-h="center" align-v="end">
							<b-col sm="2" class="text-left">
								<b-form-group label="Valutati dal" class="mb-0">
									<b-form-input type="date" v-model="form.valutati_from" size="sm"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="2" class="text-left">
								<b-form-group label="Valutati al" class="mb-0">
									<b-form-input type="date" v-model="form.valutati_to" size="sm"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="1" class="text-left">
								<b-button type="button" @click.prevent="searchValutati" size="sm" variant="primary"><i class="fa fa-search" aria-hidden="true"></i></b-button>
							</b-col>
						</b-row>
					</b-card>
				</b-collapse>
			</div>
			<div v-if="squadre || giocatori || partite" class="mt-2">
				<b-card :title="label_partite" v-if="partite && partite.length > 0" class="mb-1">
					<b-row>
						<b-col sm="3" v-for="partita in partite" style="padding: 0.2rem">
							<b-card no-body class="p-2 h-100">
								<CardPartita :partita="partita"></CardPartita>	
							</b-card>
						</b-col>
					</b-row>
					<b-pagination v-if="match_pagination.total > match_pagination.count" size="sm" align="center" :total-rows="match_pagination.total" v-model="match_pagination.page" :per-page="match_pagination.count" class="mt-3"></b-pagination>
				</b-card>
				<b-card :title="label_giocatori" v-if="giocatori && giocatori.length > 0" class="mb-1">
					<b-row>
						<b-col sm="2" v-for="giocatore in giocatori" style="padding: 0.2rem">
							<b-card no-body class="p-2 h-100">
								<CardGiocatoreScout 
									:player="giocatore" 
									:team="giocatore.last_carriera_sportiva && giocatore.last_carriera_sportiva.squadra ? giocatore.last_carriera_sportiva.squadra : null" 
									:address="{ Persona: giocatore.id }" 
									entita="Persona"
									:valutazioniPlayer="valutazioniPlayer"
								>
								</CardGiocatoreScout>
							</b-card>
						</b-col>
					</b-row>
					<b-pagination v-if="player_pagination.total > player_pagination.count" size="sm" align="center" :total-rows="player_pagination.total" v-model="player_pagination.page" :per-page="player_pagination.count" class="mt-3"></b-pagination>
				</b-card>
				<b-card :title="label_squadre" v-if="squadre && squadre.length > 0" class="mb-1">
					<b-row>
						<b-col sm="1" v-for="squadra in squadre" style="padding: 0.2rem">
							<b-card class="small text-center" style="height:100%">
								<router-link :to="{ name: 'scout_squadra', params: { squadra_id: squadra.id } }" target="_blank">
									<b-img :src="squadra.logo_url" fluid :alt="squadra.officialName" :width="120"/>
								</router-link>
								<div><strong>{{squadra.officialName}}</strong></div>
								<div v-if="squadra.categoria" class="font-size-xs color-rosso">{{ squadra.categoria.name }}</div>
								<div v-if="squadra.paese" class="font-size-xs"><flag :country="squadra.paese ? squadra.paese.sigla : ' '" :size="16"></flag> {{ squadra.paese.paese }}</div>
							</b-card>
						</b-col>
					</b-row>
					<b-pagination v-if="team_pagination.total > team_pagination.count" size="sm" align="center" :total-rows="team_pagination.total" v-model="team_pagination.page" :per-page="team_pagination.count" class="mt-3"></b-pagination>
				</b-card>
			</div>
		</b-overlay>
	</div>
</template>

<script>

import moment from 'moment'
export default {

	data: function() {
		return {
			valutazioniPlayer: null,
			loadingPlayer: false,
			loadingTeam: false,
			loadingMatch: false,
			form: {
				name: null,
				valutati_from: null,
				valutati_to: null,
			},
			squadre: null,
			giocatori: null,
			partite: null,
			player_pagination: {
				total: 0,
				page: 1,
				count: 18,
			},
			team_pagination: {
				total: 0,
				page: 1,
				count: 24,
			},
			match_pagination: {
				total: 0,
				page: 1,
				count: 12,
			},
			adv_shown: false,
		}
	},
	components: {
		flag: require('@/components/layout/flag.vue').default,
		CardGiocatoreScout: require('@/components/layout/scouting/Card.vue').default,
		CardPartita: require('@/components/layout/scout/card-partita.vue').default,
	},
	watch: {
		'player_pagination.page': function() {
			this.search('Persona');
		},
		'team_pagination.page': function() {
			this.search('Squadre');
		},
		'match_pagination.page': function() {
			this.search('Partita');
		},
		adv_shown: function() {
			if(this.adv_shown) {
				if(!this.form.valutati_to && !this.form.valutati_from) {
					this.form.valutati_to = moment().format('YYYY-MM-DD');
					this.form.valutati_from = moment().subtract(6, 'days').format('YYYY-MM-DD');
				}
			} else {
				this.form.valutati_to = null;
				this.form.valutati_from = null;
			}
		}
	},
	computed: {
		label_giocatori: function() {
			return 'Giocatori (' + this.player_pagination.total + ')';
		},
		label_squadre: function() {
			return 'Squadre (' + this.team_pagination.total + ')';
		},
		label_partite: function() {
			return 'Partite (' + this.match_pagination.total + ')';
		}
	},
	methods: {
		reset() {
			this.player_pagination.total = 0;
			this.player_pagination.page = 1;
			this.giocatori = null;
			this.team_pagination.total = 0;
			this.team_pagination.page = 1;
			this.squadre = null;
			this.match_pagination.total = 0;
			this.match_pagination.page = 1;
			this.partite = null;
		},
		searchAll: function() {
			if((!this.form.name || this.form.name.trim() == '') && !this.form.valutati_from && !this.form.valutati_to) {
				alert("Devi compilare almeno un parametro di ricerca!");
				return;
			}
			this.reset();
			if(this.form.name && this.form.name.trim() != '') {
				this.search('Squadre');
			}
			this.search('Persona');
			this.search('Partita');
		},
		searchValutati() {
			this.search('Persona', true);
			this.search('Partita', false);
		},
		search: function(entita, reset = false) {
			var page = 1;
			var count = 0;
			if(reset) {
				this.reset();
			}
			if(entita == 'Persona') {
				page = this.player_pagination.page;
				count = this.player_pagination.count;
				this.loadingPlayer = true;
			} else if(entita == 'Squadre') {
				page = this.team_pagination.page;
				count = this.team_pagination.count;
				this.loadingTeam = true;
			} else if(entita == 'Partita') {
				page = this.match_pagination.page;
				count = this.match_pagination.count;
				this.loadingMatch = true;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/scout/search?page=' + page + '&count=' + count + '&entita=' + entita, this.form).then(function(res) {
				if(res.data.squadre) {
					if(this.team_pagination.page == 1) {
						this.team_pagination.total = res.data.squadre.total;
					} 
					this.squadre = res.data.squadre.data;
					this.loadingTeam = false;
				}
				if(res.data.giocatori) {
					if(this.player_pagination.page == 1) {
						this.player_pagination.total = res.data.giocatori.total;
					} 
					this.giocatori = res.data.giocatori.data;
					this.loadingPlayer = false;
				}
				if(res.data.partite) {
					if(this.match_pagination.page == 1) {
						this.match_pagination.total = res.data.partite.total;
					} 
					this.partite = res.data.partite.data;
					this.loadingMatch = false;
				}
			});
		},
		setPlayersScoutingGlossary: function() {
			this.$http.get( this.$store.state.apiEndPoint + `/fr-valutazione/glo?area=scouting&entity=Persona` )
				.then( function({data}){	
					this.valutazioniPlayer = data.map( ( glossary ) => glossary )
				});
		}
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YY'); 
			}
			return "";
		},
		age: function (date) {
			var birthday = moment(date);
			if(birthday.isValid()) {
				return moment().diff(birthday, 'years');
			}
			return "";
		},
	},
	created: function(){
		this.setPlayersScoutingGlossary()
	}
}

</script>
<template>
	<div class="scouting-page-stats">
        <b-overlay :show="isLoading">
            <card 
                v-if="this.osservato.wyscout || isLoading"
                class="carriera-stats-card"
                title="Carriera" 
                comp="gcarriera" 
                :params="{
                    'carriera': carriera,
                    'count_rows': 1000
                }"
            >
            </card>
            <div v-else>
                <div class="info-carriera-not-found">
                    Carriera non disponibile per questo giocatore
                </div>
            </div>
        </b-overlay>
	</div>
</template>

<script>

export default {
    components: {
        card: require('@/components/layout/card.vue').default,
    },
    props: [ 'player' ],
	data: function() {
		return {
			isLoading: true,
			osservato: [],
			carriera: []
		}
	},
    computed: {
        area: function() {
			return 'new'; // Area scouting new
		}
    },
	methods: {
        fetchOsservato: function() {            
            const osservatoUrl = this.$store.state.apiEndPoint + '/scouting/view/giocatore/' + this.player.persona.id + '/' + this.area
            this.$http.get( osservatoUrl ).then((response) => {
                this.osservato = response.data; 
                this.fetchCarrieraWs()
            }).catch((error) => {
                console.log(error)
                this.isLoading = false;
            });
        },
        fetchCarrieraWs: function() {
            if(this.osservato.wyscout) {
                this.isLoading = true;
                this.$http.get(this.$store.state.apiEndPoint + '/ws/get/career/player/' + this.osservato.wyscout.chiave).then((response) => {	
                    this.carriera = response.data;    
                }).finally(() => {
                    this.isLoading = false;
                });
            }else{
                this.isLoading = false;
            }
        },
	},
    created: function() {
        this.fetchOsservato();
    }
}

</script>

<style type="scss">

    .scouting-page-stats .carriera-stats-card{
        height: 60vh !important; 
        overflow: scroll;
    }
    
    .scouting-page-stats .carriera-stats-card thead{
        position: sticky;
        top: 0;
        background: white;
    }
    
    .scouting-page-stats .info-carriera-not-found{
        padding: 10px;
        border: 1px solid #A9021C;
        border-radius: 4px;
        color: #A9021C;
        font-size: 18px;
        text-align: center;
    }

</style>
<template>
	<div>
		<b-overlay :show="loading">
			<Intestazione :match="partita" :readonly="true" v-if="partita">
				<slot slot="right">
					<div v-if="osservatori && osservatori.length > 0" class="mr-2 text-right">
						{{ osservatori[page - 1].nomeCompleto }}<br/>{{ valutazioniSelezionate[0].created_at | date }}
					</div>
				</slot>
			</Intestazione>
			<b-row v-if="partita && valutazioniPartita">
				<b-col>
					<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
						{{ partita.home_team }}
					</b-card>
					<ListaValutazioni :valutazioni="valutazioniSelezionate" v-if="partita" :match="partita" :teamId="partita.home_id" :ruoli="ruoli"></ListaValutazioni>
				</b-col>
				<b-col>
					<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
						{{ partita.away_team }}
					</b-card>
					<ListaValutazioni :valutazioni="valutazioniSelezionate" v-if="partita" :match="partita" :teamId="partita.away_id" :ruoli="ruoli"></ListaValutazioni>
				</b-col>
			</b-row>
			<b-card no-body class="p-2 mb-1"> 
				<Campetto v-if="partita" :match="partita">		
				</Campetto>	
			</b-card>
			<div class="mt-2" v-if="pages > 1">
				<b-pagination size="sm" v-model="page" :total-rows="pages" :per-page="1" align="center"></b-pagination>
			</div>
		</b-overlay>
	</div>
</template>

<script>

import moment from 'moment';
export default {
	props: [ 'partitaId', 'match', 'matchWs', 'matchFormationsWs' ],
	data: function() {
		return {
			loadingMatch: false,
			loadingVal: false,

			partita: null,
			ruoli: null,
			
			valutazioniPartita: null,
			page: 1,
		}
	},
	components: {
		Campetto: require('@/components/layout/scout/campetto.vue').default,
		Intestazione: require('@/components/layout/scout/intestazione-partita.vue').default,
		ListaValutazioni: require('@/components/layout/scout/lista-valutazioni.vue').default,
	},

	created() {
		this.fetchMatch();
		this.fetchValutazioni();
		this.fetchRuoli();
	},
	methods: {
		fetchMatch: function() {
			if(this.match) {
				this.partita = this.match;
			} else {
				this.loadingMatch = true;
				this.$http.get(this.$store.state.apiEndPoint + '/football/partita/' +  this.partitaId).then(function(data) {
					this.partita = data.data;
					this.partita.label = this.partita.home_team + ' - ' + this.partita.away_team + ', ' + this.partita.home_score + '-' + this.partita.away_score;
					this.loadingMatch = false;
				});
			}
		},
		fetchValutazioni: function() {
			this.loadingVal = true;
			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/has', { address: {Partita: this.partitaId }} ).then(function(data) {
				this.valutazioniPartita = data.data;
				this.loadingVal = false;
			});
		},
		fetchRuoli: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then(function(data) {
				this.ruoli = data.data;
			});
		},
	},
	computed: {
		loading: function() {
			return this.loadingVal || this.loadingMatch;
		},
		osservatori: function() {
			return _.uniqBy(_.map(this.valutazioniPartita, 'persona'), 'id');
		},
		valutazioniByUtente: function() {
			return _.groupBy(this.valutazioniPartita, 'persona_utente_id');
		},
		valutazioniSelezionate: function() {
			return this.osservatori.length ? this.valutazioniByUtente[this.osservatori[this.page - 1].id] : [];
		},
		pages: function() {
			return _.size(this.osservatori);
		},
	},
	filters: {
		date: function(i) {
			return moment(i).format('DD/MM/YYYY')
		}
	},
}

</script>
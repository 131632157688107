<template>
	<span>
		<b-button :size="size || 'sm'" block @click.prevent="ux.show = !ux.show" variant="outline-success">{{ nm || 'Nuova Valutazione' }}</b-button>

		<b-sidebar @hidden="ux.show = false" ref="mod" :title="'Nuova Valutazione ' + player.surname" :visible="ux.show" width="80%" backdrop class="text-left">
			<b-form @submit.prevent="inviaValutazioni">
				<b-card no-body class="mx-2 mb-3">
					<b-row class="align-items-end">
						<b-col cols="1">
							<img :src="player.avatar" width="60">
						</b-col>
						<b-col class="pb-1 text-left" cols="8">
							<div style="text-transform: uppercase; line-height: 1">{{player.surname}}</div>
							<div class="small text-muted" style="line-height: 1">{{player.name}}<span style="font-weight: bold; text-transform: uppercase">, {{player.role}}</span></div>
						</b-col>
						<b-col v-if="player.team" class="pb-1">
							<b-row class="text-left">
								<b-col cols="2">
									<img :src="player.team.logo_url" width="100%" />
								</b-col>
								<b-col>
									<div style="line-height: 1">{{player.team.name}}</div>
									<div style="line-height: 1" class="small text-muted" v-if="player.team.paese">{{player.team.paese.name}}</div>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-card>

				<div class="my-2 px-2" v-if="match">
						<b-card class="mt-2">
							<template slot="header">
								<b-row class="flex-items-xs-middle d-flex">
									<b-col>
										Partita Collegata
									</b-col>
									<b-col>
										<div class="small text-muted text-right">Questa valutazione è collegata ad una partita.</div>
									</b-col>
								</b-row>
							</template>
							<b-row>
								<b-col>
									<b-row class="align-items-center">
										<b-col class="text-center">
											<div>{{ match.label }}</div>
											<div class="small text-muted">{{ match.data }}</div>
										</b-col>
									</b-row>
								</b-col>
								<b-col class="text-right">
									<b-button size="sm" @click.prevent="addValutazionePartita(typeOfMatchValutations[0])" variant="outline-success">Aggiungi Valutazione Partita</b-button>
									<!--b-button-group>
									    <b-dropdown @click.prevent="addValutazionePartita('Breve')" variant="outline-success" size="sm" right split text="Aggiungi Valutazione Partita">
									      <b-dropdown-item :disabled="!isMatchClick('Breve')" @click.prevent="addValutazionePartita('Breve')">Base</b-dropdown-item>
									      <b-dropdown-item :disabled="!isMatchClick('Estesa')" @click.prevent="addValutazionePartita('Estesa')">Estesa</b-dropdown-item>
									    </b-dropdown>
									</b-button-group-->
								</b-col>
							</b-row>
							<div v-for="val,k in valutazionePartita" class="my-3" style="background-color: rgba(0,0,0,.05); border-radius: 5px; padding: 0.5em; border: 1px solid rgba(0,0,0,.1)">

								<b-row class="mb-2">
									<b-col>
										<p class="lead ml-1">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(val)}}</span></p>
									</b-col>
									<b-col class="text-right small"><a href="javascript:;" @click.prevent="removeMatch(k)">Annulla</a></b-col>
								</b-row>
								<div :evaluation="val" :is="calculateTypePartita(val)" class="mb-4"></div>

							</div>
						</b-card>
					</div>

				<div class="px-2">
					<!--b-nav tabs>
						<b-nav-item active>Scouting</b-nav-item>
						<b-nav-item disabled>Football</b-nav-item>
						<b-nav-item disabled>Medical</b-nav-item>
					</b-nav-->
						
					<div class="px-2" style="background-color: white; border: 1px solid rgba(0,0,0,.1);">

						<div v-if="valutazioneGiocatore.length > 0" class="py-1">
							<div v-for="a in valutazioneGiocatore" class="my-3" style="background-color: rgba(0,0,0,.05); border-radius: 5px; padding: 0.5em; border: 1px solid rgba(0,0,0,.1)">
								<!-- <b-row class="d-flex align-items-center mb-2">
									<b-col>
										<p class="lead ml-1 mb-0">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(a)}}</span></p>
									</b-col>
									<b-col class="text-right"><a href="javascript:;" @click.prevent="removePlayer(k)">&times; Rimuovi</a></b-col>
								</b-row> -->
								<div 
									:evaluation="a" 
									:is="calculateTypePlayer(a)"
									@remove="removePlayer(a)"
								>
								</div>
							</div>
						</div>

						<hr v-if="valutazioneGiocatore.length > 0" />
						<div v-else class="py-1" style="width: 5px"></div>
						<div class="lead text-muted my-2 text-center" v-if="valutazioneGiocatore.length > 0">Inserisci un altra valutazione</div>

						<b-row class="align-items-center" :class="{ 'my-2': valutazioneGiocatore.length == 0 }">
							<b-col cols="3" class="mb-1" style="height: 100%" v-for="val in typeOfPlayerValutations">
								<b-card align="center" @click.prevent="addValutazioneGiocatore(val)" :style="(!isPlayerClick(val.id) ? 'cursor:pointer;height:100%;' : 'opacity: .5;') + 'line-height: 1; height: 72px;'">
									{{ val.nome }}
								</b-card>
							</b-col>
						</b-row>
					</div>

				</div>

				<center class="my-3">
					<b-button type="submit" v-if="valutazioneGiocatore.length > 0 || valutazionePartita.length > 0">Salva Valutazion{{ valutazioneGiocatore.length == 1 ? 'e':'i' }}</b-button>
					<!--b-button @click="$emit('refresh', player.id)">Refresh</b-button-->
				</center>

			</b-form>

		</b-sidebar>

	</span>
</template>

<script>

export default {
	props: [ 'player', 'match', 'size', 'nm', 'valutazioniPlayer' ],
	components: {
		ValutazionePartitaBreve: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazionePartitaEstesa: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazioneGiocatoreBreve: require('@/components/layout/Valutazioni/ValutazioneGiocatoreBreve.vue').default,
		ValutazioneGiocatoreEstesa: require('@/components/layout/Valutazioni/ValutazioneGiocatoreEstesa.vue').default,
		ValutazioneGiocatoreConfigReport: require('@/components/layout/Valutazioni/ValutazioneGiocatoreConfigReport.vue').default,
	},
	methods: {
		generateName: function(name) {
			return _.startCase(name.kind);
		},
		isPlayerClick: function(k) {
			return _.find(this.valutazioneGiocatore, { valutazione_glo_id: k });
		},
		isMatchClick: function(k) {
			return _.find(this.valutazionePartita, { kind: k });
		},
		removeMatch: function(k) {
			this.valutazionePartita.splice(k, 1);
		},
		removePlayer: function(evaluation) {			
			this.valutazioneGiocatore = this.valutazioneGiocatore.filter( 
				( valutazione ) => evaluation.glossario.id !== valutazione.valutazione_glo_id 
			)
		},
		calculateTypePlayer: function(what) {
			// @TODO-MANDARINO adattare al componente del DB - al momento è statico per disallineamento tra DB e visualizzazione
			// return 'ValutazioneGiocatore' + what.kind;
			return 'ValutazioneGiocatoreConfigReport';
		},
		calculateTypePartita: function(what) {
			return 'ValutazionePartita' + what.kind;
		},
		addValutazioneGiocatore: function(tipo) {
			// if(!this.isPlayerClick(tipo.component))
			// 	this.valutazioneGiocatore.push({ kind: tipo.component, evaluation: null, valutazione_glo_id: tipo.valutazione_glo_id });
			if(!this.isPlayerClick(tipo.id)){
				this.valutazioneGiocatore.push({ 
					kind: tipo.component,
					glossario: tipo,
					player: this.player,
					evaluation: null,
					valutazione_glo_id: tipo.id
				});
			}
		},
		addValutazionePartita: function(tipo) {
			if(!this.isMatchClick(tipo))
				this.valutazionePartita.push({ kind: tipo.component, evaluation: null, valutazione_glo_id: tipo.valutazione_glo_id});
		},
		inviaValutazioni: function() {

			this.ux.load = true

			var relazioni = [
				{ entita: this.player.persona_id ? 'Persona' : 'ws_player', entita_id: this.player.persona_id ? this.player.persona_id : this.player.id },
			];

			if(this.match) {
				relazioni.push({ entita: this.match.allegati ? this.match.allegati.entita : 'ws_match', entita_id: this.match.allegati ? this.match.allegati.entita_id : this.match.partita.wyId });
			}

			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/create', { persona: this.valutazioneGiocatore, partita: this.valutazionePartita, relazioni }).then(function(data) {
				this.$emit('refresh', this.player.id);

				/** Reset evaluation datas **/
				this.valutazioneGiocatore = []
				this.valutazionePartita = []
				this.ux.load = false
				this.ux.show = false
			});

		}
	},
	computed: {
		searchObject: function() {
			var u = {};
			u.player = this.player.id;
			if(this.match)
				u.match = this.match.partita.wyId;
			
			return u;
		},
		typeOfPlayerValutations: function() {
			return _.filter(this.valutazioniPlayer, function(i) {
				const role = i?.config?.role ?? null
				return role === null || role.indexOf(this.player.role) !== -1;
			}.bind(this));
		},
		typeOfMatchValutations: function() {
			// return _.filter(this.valutazioniPlayer, function(i) {
			// 	return i.entity == 'Match'
			// }.bind(this));
			return [{ entity: 'Match', name: 'Partita', component: 'Breve', role: null, valutazione_glo_id: 2 }]
		}
	},
	data: function() {
		return {
			ux: {
				show: false,
				load: false,
			},
			valutazioneGiocatore: [],
			valutazionePartita: []
		}
	},
	mounted: function() {
		// this.$refs.mod.show();
	}
}
</script>
import axios from 'axios';

export default {
    getCompetitions({ state, commit }) {
        return axios.get(`/scout/competizioni`)
            .then(function ({ data }) {
                commit('setCompetitions', data);
            })
            .catch(function (error) {
                console.log(error);
            });
    },
};

<template id="form">
	<div class="card border-0 rounded-0">
		<div class="card-body">
			<b-container>

				<b-row>
					<b-col cols="12">
						<div v-if="title" class="card-title">
							<span>{{ title }}</span>
						</div>
						<form v-on:submit.prevent="onSubmit()" action="#" method="POST">
							<div class="content">
								<picture-input ref="pictureTeam" @change="onLogoChange" width="120" height="120" size="10" class="my-1" :prefill="logo"></picture-input>
								<b-form-group id="officialNameGroup" horizontal label="Nome Ufficiale" :label-cols="3" label-for="officialName">
									<b-input-group>
										<b-form-input id="officialName" type="text" v-model="form.officialName" required :readonly="form.wyscout_team_id != null || form.wyscout != null"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="searchTeam" title="Cerca squadra"><i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
								<b-form-group id="nameGroup" horizontal label="Nome" :label-cols="3" label-for="name">
									<b-form-input id="name" type="text" v-model="form.name" required></b-form-input>
								</b-form-group>
								<b-form-group id="cityGroup" horizontal label="Città" :label-cols="3" label-for="city">
									<b-form-input id="city" type="text" v-model="form.city" ></b-form-input>
								</b-form-group>
								<b-form-group id="paeseNascitaGroup" horizontal label="Paese" :label-cols="3" label-for="paese">
									<autocomplete-paese :suggestions="aree" id="paese" ref="paese" @select="onPaeseSelect" @change="onPaeseChange" v-model="form.area"></autocomplete-paese>
								</b-form-group>
								<b-form-group id="categoriaGroup" horizontal label="Categoria" :label-cols="3" label-for="categoria">
									<multiselect v-model="form.categoria" :options="categories_opt" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="id" :preselect-first="true"></multiselect>
								</b-form-group>
								<b-form-group id="competizioneGroup" horizontal label="Campionato" :label-cols="3" label-for="competizione">
									<b-input-group>
										<b-form-input id="competizione" type="text" v-model="competizione" :readonly="true"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="searchCompetizione" title="Cerca campionato"><i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
								<b-form-group id="ownerGroup" horizontal label="Squadra AS Roma" :label-cols="3" label-for="owner" v-if="auth.isAdmin()">
									<b-form-checkbox id="owner" v-model="form.owner"></b-form-checkbox>
								</b-form-group>

								<b-form-group horizontal label="Providers" :label-cols="3" v-if="auth.isUserEnable('squadre_providers_edit')">
									<entita-api-manager :entityId="$route.params.id" entity="Squadre" v-if="$route.params.id"/>
									<div v-else>
									Devi prima salvare l'anagrafica squadra per poter aggiungere un provider!		
									</div>
								</b-form-group>

								<b-form-group horizontal label="Divisa" :label-cols="3">
									<b-img :src="image_divisa" width="100"></b-img>
									<b-button style="float: right" size="xs" @click="showModalDivisa(false)">Configura divisa</b-button>
								</b-form-group>

								<b-form-group horizontal label="Divisa portiere" :label-cols="3">
									<b-img :src="image_divisa_portiere" width="100"></b-img>
									<b-button style="float: right" size="xs" @click="showModalDivisa(true)">Configura divisa</b-button>
								</b-form-group>

								<div class="d-flex align-items-center">
									<b-form-group class="text-right">
										<b-button type="reset" variant="outline-primary">Annulla</b-button>
										<b-button type="submit" variant="primary" class="text-uppercase"><strong>Salva Informazioni</strong></b-button>
									</b-form-group>
								</div>
							</div>
						</form>
					</b-col>

				</b-row>
			</b-container>
		</div>

		<b-modal id="searchTeamModal" ref="searchTeamModal" :title="is_search_competizione ? 'Ricerca campionato' : 'Ricerca squadra'" ok-only ok-title="Chiudi">
			<div v-if="show_aree">
				<span>Seleziona un'area: </span>
				<b-form-input class="mb-1"
				v-model="search_area"
				placeholder="Quick search"
				></b-form-input>
				<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
					<div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<b-row>
						<b-col cols="3" v-for="area in filtered_aree" v-bind:key="area.id" style="padding: 0.2rem">
							<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(is_search_competizione ? area.id : area.sigla3)">
								<flag :country="area.sigla" :size="24"></flag><br/>
								{{area.paese}}
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
			<div v-if="show_competizioni">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
				<div v-if="competizioni.length > 0">
					<span>Seleziona una competizione: </span>
					<b-form-input class="mb-1"
					v-model="search_competizione"
					placeholder="Quick search"
					></b-form-input>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<b-row>
							<b-col cols="4" v-for="(comp, index) in filtered_competizioni" v-bind:key="index" style="padding: 0.2rem">
								<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp)">
									<strong>{{is_search_competizione ? comp.nome : comp.name}}</strong><br/>
									<span class="font-size-xs color-rosso">{{$i18n.t(is_search_competizione ? comp.formato : comp.format)}}</span><br/>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
			<div v-if="show_squadre">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
				<div v-if="squadre.length > 0">
					<span>Seleziona una squadra: </span>
					<b-form-input class="mb-1"
					v-model="search_squadra"
					placeholder="Quick search"
					></b-form-input>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<b-row>
							<b-col cols="4" v-for="team in filtered_squadre" v-bind:key="team.id" style="padding: 0.2rem">
								<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selezionaSquadra(team)">
									<img :src="team.imageDataURL" width="50" /><br/>
									<strong>{{team.officialName}}</strong><br/>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal ref="divisaModal" :title="'Editor Divisa' + (isPortiere ? ' Portiere' : '')" ok-only ok-title="Chiudi" ok-variant="secondary">
			<editorDivisa @selectDivisa="selectDivisa"></editorDivisa>
		</b-modal>

	</div>

</template>

<script>

import moment from 'moment'
import axios from 'axios';
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types'
export default {

	data: function(){
		return {
			categories_opt: [],
			image_team: '',
			file_logo: '',
			form: {
				name: '',
				officialName: '',
				type: '',
				category: '',
				gender: '',
				city: '',
				area: 0,
				wyscout_team_id: null,
				categoria: null,
				owner: false,
				wyscout: null,
				competizione_id: null
			},
			aree: [],
			competizioni: [],
			squadre: [],
			show_aree: false,
			show_competizioni: false,
			show_squadre: false,
			errors: [],
			search_area: "",
			search_competizione: "",
			search_squadra: "",
			image_divisa: '',
			file_divisa: '',
			image_divisa_portiere: '',
			file_divisa_portiere: '',
			isPortiere: false,
			competizione: null,
			is_search_competizione: false
		}
	},

	methods: {

		showModalDivisa(isPortiere) {
			this.isPortiere = isPortiere;
			this.$refs.divisaModal.show();
		},

		selectDivisa(divisa) {
			let url = window.URL.createObjectURL(divisa);
			if(this.isPortiere) {
				this.image_divisa_portiere = url;
    			this.file_divisa_portiere = new File([divisa], 'divisa_portiere.svg');
			} else {
				this.image_divisa = url;
    			this.file_divisa = new File([divisa], 'divisa.svg');
    		}
			this.$refs.divisaModal.hide();
		},

		onSubmit: function(){
			if(this.form.area == 0) {
				this.$store.commit(types.ALERTS_PUSH, { msg: "Devi selezionare un paese!", status: 0 });
				return;
			}
			if(this.form.categoria == null) {
				this.$store.commit(types.ALERTS_PUSH, { msg: "Devi selezionare una categoria!", status: 0 });
				return;
			}
			var formData = new FormData();
			var path = '/squadre/add';
			formData.append("form", JSON.stringify(this.form));
			formData.append("file_logo", this.file_logo);
			if(this.file_divisa != '') {
				formData.append("file_divisa", this.file_divisa);
			}
			if(this.file_divisa_portiere != '') {
				formData.append("file_divisa_portiere", this.file_divisa_portiere);
			}
			if(this.$route.params.id) {
				formData.set('id', this.$route.params.id);
				path  = '/squadre/edit';
			}

			this.$http.post(this.$store.state.apiEndPoint + path, formData).then((response) => {
            	// success callback
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: 'football_squadre'});
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
				// error callback
			});
		},

		searchTeam: function() {
			this.is_search_competizione = false;
			this.show_aree = true;
			this.show_competizioni = false;
			this.show_squadre = false;
			this.search_area = "";
			this.search_competizione = "";
			this.search_squadra = "";
			if(this.aree.length == 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
					if (response.ok)
					{
						this.aree = response.data;
					}
				}, (response) => {
				});
			}
			this.$refs.searchTeamModal.show();
		},

		searchCompetizione: function() {
			this.is_search_competizione = true;
			this.show_aree = true;
			this.show_competizioni = false;
			this.show_squadre = false;
			this.search_area = "";
			this.search_competizione = "";
			this.search_squadra = "";
			if(this.aree.length == 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
					if (response.ok)
					{
						this.aree = response.data;
					}
				}, (response) => {
				});
			}
			this.$refs.searchTeamModal.show();
		},

		selezionaArea: function(sigla) {
			this.show_aree = false;
			this.competizioni = [];
			this.is_search = true;
			this.show_competizioni = true;
			if(this.is_search_competizione) {
				this.$http.get(this.$store.state.apiEndPoint + '/club/competizioni/' + sigla).then((response) => {
					this.competizioni = response.data;
					this.is_search = false;
				});
			} else {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competitions/' + sigla).then((response) => {
					this.competizioni = response.data;
					this.is_search = false;
				});
			}
		},

		selezionaSquadra: function(squadra) {
			this.form.officialName = squadra.officialName;
			this.form.name = squadra.name;
			this.form.wyscout_team_id = squadra.wyId;
			this.form.type = squadra.type;
			this.form.category = squadra.category;
			this.form.gender = squadra.gender;
			this.form.city = squadra.city;
			this.form.area = squadra.gender;
			this.image_team = squadra.imageDataURL;
			if(squadra.area.alpha2code) {
				for (var index in this.aree) {
					var paese = this.aree[index];
					if (squadra.area.alpha2code == paese.sigla) {
						this.$refs.paese.setPaese(paese.id, paese.paese)
						break;
					}
				}
			}
			this.$refs.searchTeamModal.hide();
		},

		selezionaCompetizione: function(competizione) {
			if(this.is_search_competizione) {
				this.form.competizione_id = competizione.id;
				this.competizione = competizione.paese.paese + ' - ' + competizione.nome;
				this.$refs.searchTeamModal.hide();
			} else {
				this.show_competizioni = false;
				this.squadre = [];
				this.is_search = true;
				this.show_squadre = true;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/teams/by/competition/' + competizione.wyId).then((response) => {
					this.squadre = response.data;
					this.is_search = false;
				});
			}
		},
		onPaeseSelect(item) {
			this.form.area = item;
		},
		onPaeseChange() {
			this.form.area = 0;
		},
		onLogoChange(e) {
			if (this.$refs.pictureTeam.file) {
				this.file_logo = this.$refs.pictureTeam.file;
			} else {
				console.log('FileReader Player API not supported: use the <form>')
			}
		},
	},

	props: {
		title: {
			type: String,
			required: false
		},
	},

	components: {
		pictureInput:		require('../../../layout/picture_input.vue').default,
		autocompletePaese:  require('../../../layout/autocomplete_paese.vue').default,
		flag:               require('../../../layout/flag.vue').default,
		EntitaApiManager: 	require('@/components/layout/entita_api/manager.vue').default,
		editorDivisa: require('@/components/layout/divise/editor.vue').default,
	},

	computed: {
		auth: function() {
			return Auth;
		},
		logo: function() {
			if(this.image_team != "") {
				return this.image_team;
			} 
			return '';
		},
		filtered_aree: function () {
			if (this.search_area != "") {
				return this.aree.filter((area) =>
					area.paese.toLowerCase().includes(this.search_area.toLowerCase())
					);
			}
			return this.aree;
		},
		filtered_competizioni: function () {
			if (this.search_competizione != "") {
				return this.competizioni.filter((competizione) => {
					var comp = this.is_search_competizione ? competizione.nome : competizione.name;
					return comp.toLowerCase().includes(this.search_competizione.toLowerCase());
				});
			}
			return this.competizioni;
		},
		filtered_squadre: function () {
			if (this.search_squadra != "") {
				return this.squadre.filter((squadra) =>
					squadra.officialName
					.toLowerCase()
					.includes(this.search_squadra.toLowerCase())
					);
			}
			return this.squadre;
		},
	},

	mounted: function(){
	},

	created: function ()
	{

		if(this.$route.params.id){
			axios.get(`/squadre/get/` + this.$route.params.id)
			.then((res) => {

				var squadra = res.data;
				this.form.officialName = squadra.officialName;
				this.form.name = squadra.name;
				this.form.wyscout = squadra.wyscout;
				this.form.type = squadra.type;
				this.form.category = squadra.category;				
				this.form.gender = squadra.gender;
				this.form.city = squadra.city;
				this.form.area = squadra.gender;
				this.form.competizione_id = squadra.competizione_id;
				if(squadra.logo != null && squadra.logo != "") {
					this.image_team = squadra.logo_url;
				}
				if(squadra.divisa != null && squadra.divisa != "") {
					this.image_divisa = squadra.divisa_url;
				}
				if(squadra.divisa_portiere != null && squadra.divisa_portiere != "") {
					this.image_divisa_portiere = squadra.divisa_portiere_url;
				}
				if(squadra.paese) {
					this.$refs.paese.setPaese(squadra.paese.id, squadra.paese.paese);	
				}
				if(squadra.categoria) {
					this.form.categoria = {
						name: squadra.categoria.name,
						id: squadra.categoria.id,
					};
				}
				if(squadra.societa.length > 0) {
					this.form.owner = true;
				}
				if(squadra.competizione) {
					this.competizione = squadra.competizione.paese.paese + ' - ' + squadra.competizione.nome;
				}

			}).catch((err) => {
				this.loading = false;
			});
		}

		/**
		*  RETRY CATEGORIES
		**/

		axios.get(`/exercise/categories`)
		.then((res) => {
			this.categories_opt = res.data.map(item => ({
				name: item.name,
				id: item.id,
			}));
			this.loading    = false;
		}).catch((err) => {
			this.loading = false;
		});

		this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
			if (response.ok)
			{
				this.aree = response.data;
			}
		}, (response) => {
		});
	},

	watch: {

		form: {
			handler: function () {this.errors = []; },
			deep: true
		},
	},

}
</script>

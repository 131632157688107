<template>
	<div>
		<b-nav vertical>
			<b-nav-item :to="{ name: 'PlayerPersonaStats' }" :style="isSelected('PlayerPersonaStats') ? navActiveStyle : ''">
				Carriera
			</b-nav-item>
			<b-nav-item :to="{ name: 'PlayerPersonaStatsMatches' }" :style="isSelected('PlayerPersonaStatsMatches') ? navActiveStyle : ''">
				Partite
			</b-nav-item>
		</b-nav>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			navActiveStyle: 'background-color: white; border-top-left-radius: 10px; border-bottom-left-radius: 10px;'
		}
	},
	methods: {
		isSelected: function(name) {
			return name === this.$route.name;
		}
	}
}

</script>